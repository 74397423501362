.index-purchase-methods {
  &__item {
    border: 1px solid var(--grey-line-color);
  }

  &__link {
    display: flex;
    flex-direction: column;
  }

  &__subtitle {
    color: var(--black-color-40);
  }

  &__arrow,
  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__icon {
    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__arrow {
    width: 18px;
    height: 18px;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__top-wrap,
  &__bottom-wrap {
    display: flex;
  }

  &__top-wrap {
    align-items: flex-start;
    justify-content: space-between;
  }

  &__bottom-wrap {
    flex-direction: column;
  }
}

@include respond-up('s-large') {
  .index-purchase-methods {
    &__list {
      margin-top: 30px;
    }

    &__item {
      grid-column: span 3;
      transition: var(--default-transition);

      &:hover {
        border-color: var(--grey-line-hover-color);

        .index-purchase-methods {
          &__arrow {
            svg path {
              stroke: var(--blue-color);
            }
          }

          &__title {
            color: var(--blue-color);
          }
        }
      }
    }

    &__arrow {
      svg path {
        transition: var(--default-transition);
      }
    }

    &__bottom-wrap {
      margin-top: 62px;
    }

    &__title {
      transition: var(--default-transition);
    }
  }
}

@include respond-up('medium') {
  .index-purchase-methods {
    &__link {
      padding: 30px;
    }

    &__icon {
      width: 60px;
      height: 60px;
    }
  }
}

@include respond('medium') {
  .index-purchase-methods {
    &__list {
      margin-top: 20px;
    }

    &__item {
      grid-column: span 4;
    }

    &__bottom-wrap {
      margin-top: 30px;
    }
  }
}

@include respond-down('small') {
  .index-purchase-methods {
    &__list {
      margin-top: 10px;
    }

    &__item {
      grid-column: span 2;
    }

    &__link {
      padding: 15px;
    }

    &__icon {
      width: 46px;
      height: 46px;
    }

    &__bottom-wrap {
      margin-top: 20px;
    }
  }
}