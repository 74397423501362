.floating-field {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    z-index: 5;
    top: 30px;
    right: 20px;
    width: 18px;
    height: 18px;
    background-image: url('../images/svg/error-notice.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    transition: var(--default-transition);
    opacity: 0;
  }

  &_textarea {
    &::after {
      content: '';
      position: absolute;
      z-index: 5;
      top: 0;
      left: 0;
      width: 100%;
      height: 36px;
      background-color: var(--grey-color);
      transition: var(--default-transition);
    }
  }

  label {
    position: absolute;
    z-index: 10;
    left: 0;
    display: flex;
    align-items: center;
    pointer-events: none;
    transition: var(--default-transition);
    margin: 0;

    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 22.4px */
  }

  input,
  textarea {
    background-color: var(--grey-color);
    transition: var(--default-transition);

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-internal-autofill-selected {
      color: black !important;
      caret-color: black !important;
      -webkit-text-fill-color: black !important;
    }
  }

  &._filled input + label,
  input:focus + label,
  &._filled textarea + label,
  textarea:focus + label {
    top: 0;
    color: var(--black-color-40);
  }

  &._error {
    &::before {
      opacity: 1;
    }
  }

  &._white {
    label {
      color: white;
    }

    input,
    textarea {
      background-color: var(--white-color-10);
      color: white;

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-internal-autofill-selected {
        color: white !important;
        caret-color: white !important;
        -webkit-text-fill-color: white !important;
      }
    }

    &._filled input + label,
    input:focus + label,
    &._filled textarea + label,
    textarea:focus + label {
      color: var(--white-color-40);
    }
  }
}

@include respond-up('s-large') {
  .floating-field {
    &_textarea {
      &:hover {
        &::after {
          background-color: var(--grey-hover-color);
        }
      }
    }

    &:hover {
      input,
      textarea {
        background-color: var(--grey-hover-color);
      }
    }

    &._white {
      &:hover {
        input,
        textarea {
          background-color: var(--white-color-13);
        }
      }
    }
  }
}

@include respond-up('medium') {
  .floating-field {
    label {
      font-size: 16px;
      letter-spacing: -0.32px;
      top: 27px;
      left: 20px;
    }

    &._filled input + label,
    input:focus + label,
    &._filled textarea + label,
    textarea:focus + label {
      font-size: 12px;
      letter-spacing: -0.24px;
      top: 19px;
    }
  }
}

@include respond('small') {
  .floating-field {
    &::before {
      top: 19px;
      right: 16px;
    }

    &_textarea {
      &::after {
        height: 25px;
      }
    }

    label {
      font-size: 14px;
      letter-spacing: -0.28px;
      top: 18px;
      left: 16px;
    }

    &._filled input + label,
    input:focus + label,
    &._filled textarea + label,
    textarea:focus + label {
      font-size: 11px;
      letter-spacing: -0.22px;
      top: 10px;
    }
  }
}