:root {
  --modal-bg: rgba(7, 20, 46, .6);
  --modal-content-radius: 0;
  --modal-content-shadow: none;
  --modal-content-padding: 40px;
  --modal-content-background: #fff;
  --modal-closer-color: var(--modal-bg);
  --modal-closer-size: 60px;
  --modal-preloader-size: 30px;
}

.modal__bg {
  position: fixed;
  height: 100%;
  width: 100%;
  background: white;
  background: var(--modal-bg);
  z-index: 10000;
  display: none;
  top: 0;
  left: 0;
  overflow-y: scroll;

  &._opened {
    display: block;
  }
}

.modal__layout {
  display: none;
  position: relative;
  z-index: 11000;
  cursor: default;
  padding: 40px 0;
  margin: 0 auto;
  min-height: 100%;
  align-items: center;
  justify-content: center;

  &._opened {
    display: flex;
  }
}

.modal__container {
  padding: var(--modal-content-padding);
  border-radius: var(--modal-content-radius);
  background: var(--modal-content-background);
  box-shadow: var(--modal-content-shadow);
  position: relative;

  &.no-padding {
    padding: 0 !important;
  }
}

.modal__content {
  img {
    max-width: 100%;
    height: auto;
  }
}

.modal__closer {
  position: absolute;
  z-index: 50;
  width: var(--modal-closer-size);
  height: var(--modal-closer-size);
  text-decoration: none;
  cursor: pointer;
  border: 1px solid var(--white-color-40);
  border-radius: 100%;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 30px;
    height: 30px;
    transform: translate3d(-50%, -50%, 0);
    background-image: url('../images/svg/closer-white.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
}

.modal__loader {
  display: block;
  position: fixed;
  z-index: 3000;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: var(--modal-bg);
  transition: 0.3s all;
  opacity: 0;
  visibility: hidden;
}

body.modal-loading {
  overflow: hidden;
  --primary-color-opacity: rgba(1, 199, 103, .85);
  --preloader-size: 100px;

  .modal__loader {
    opacity: 1;
    visibility: visible;

    &:before, &:after {
      content: '';
      position: fixed;
      width: var(--preloader-size);
      height: var(--preloader-size);
      left: 50%;
      top: 50%;
      margin-left: calc(var(--preloader-size) / -2);
      margin-top: calc(var(--preloader-size) / -2);
      background-color: #000;
      border-radius: calc(var(--preloader-size) / 2);
      transform: scale(0);
    }

    &:before {
      background-color: var(--primary-color-opacity, var(--blue-color));
      animation-name: pulse;
      animation-iteration-count: infinite;
      animation-duration: 1.4s;
      animation-delay: 0.4s;
    }

    &:after {
      background-color: var(--primary-color);
      animation-name: pulse;
      animation-iteration-count: infinite;
      animation-duration: 1.4s;
    }
  }
}

@include respond-up('s-large') {
  .modal__closer {
    top: 0;
    right: -80px;
    transition: var(--default-transition);

    &:hover {
      border-color: #fff;
    }
  }
}

@include respond('medium') {
  .modal__closer {
    top: 40px;
    right: 40px;
  }
}

@include respond-down('medium') {
  .modal__bg {
    background: #fff;
  }

  .modal__layout {
    padding: 0;
  }

  .modal__container {
    position: static;
  }

  .modal__closer {
    background-color: #fff;
    border: 1px solid var(--grey-line-color);

    &::before {
      background-image: url('../images/svg/closer-blue.svg');
    }
  }
}

@include respond('small') {
  :root {
    --modal-content-padding: 20px;
    --modal-closer-size: 46px;
  }

  .modal__closer {
    top: 20px;
    right: 20px;

    &::before {
      width: 20px;
      height: 20px;
    }
  }
}

@keyframes pulse {
  0% {
    transform: scale(0);
  }

  50% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}