@font-face {
    font-family: 'Halvar Breit';
    src: url('HalvarBreit-Bd.eot');
    src: local('Halvar Breitschrift Bold'), local('HalvarBreit-Bd'),
    url('HalvarBreit-Bd.eot?#iefix') format('embedded-opentype'),
    url('HalvarBreit-Bd.woff2') format('woff2'),
    url('HalvarBreit-Bd.woff') format('woff'),
    url('HalvarBreit-Bd.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}