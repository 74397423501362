.recall-form {
  background: var(--blue-color-gradient);
  overflow: hidden;

  &__title,
  &__description {
    color: white;
  }

  &__submit {
    width: 100%;
  }

  &__policy-wrap {
    display: flex;
    justify-content: center;
  }

  &__success {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: var(--blue-color-gradient);
  }

  &__success-icon {
    display: flex;
    height: max-content;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__success-title,
  &__success-subtitle {
    width: var(--grid-column4);
    text-align: center;
  }

  &__success-title {
    color: white;
    margin-top: 30px;
  }

  &__success-subtitle {
    color: var(--white-color-40);
    margin-top: 20px;
  }
}

@include respond-up('s-large') {
  .recall-form {
    padding: 120px 0;

    &__title-container {
      grid-column: 1/6;
    }

    &__data {
      grid-column: 8/13;
    }

    &__description {
      margin-top: 40px;
    }
  }
}

@include respond-up('medium') {
  .recall-form {
    &__policy-wrap {
      margin-top: 20px;
    }
  }
}

@include respond('medium') {
  .recall-form {
    padding: 80px 0;

    &__inner {
      grid-row-gap: 50px;
    }

    &__title-container,
    &__data {
      grid-column: 1/9;
    }

    &__description {
      margin-top: 30px;
    }

    &__title,
    &__description {
      width: calc(var(--grid-column5) + var(--grid-gap));
    }

    &__fields {
      display: flex;
    }

    &__field {
      flex: 0 1 100%;

      &:last-child {
        margin-left: 10px;
      }
    }

    &__success-subtitle {
      margin-top: 15px;
    }
  }
}

@include respond-down('medium') {
  .recall-form {

  }
}

@include respond-down('small') {
  .recall-form {
    padding: 40px 0;

    &__inner {
      grid-row-gap: 31px;
    }

    &__title-container,
    &__data {
      grid-column: 1/5;
    }

    &__description {
      margin-top: 20px;
    }

    &__policy-wrap {
      margin-top: 15px;
    }

    &__success-icon {
      width: 46px;
      height: 46px;
    }

    &__success-title {
      margin-top: 20px;
    }

    &__success-subtitle {
      margin-top: 8px;
    }
  }
}