.project-albums {
  position: relative;

  &__contents {
    width: 100%
  }

  &__slide {
    position: relative;
  }

  &__picture {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    pointer-events: none;
  }

  &__tab {
    background: #FFFFFF;
  }

  &__tabs-wrap {
    position: absolute;
    width: calc(100% - var(--grid-spacer));
    overflow: hidden;
    z-index: 100;
  }

  &__pagination {
    &.swiper-pagination-bullets.swiper-pagination-horizontal {
      left: unset;
      width: max-content;
    }
  }
}

@include respond-up('s-large') {
  .project-albums {
    &__slide {
      &.swiper-slide {
        height: calc(var(--grid-column12) * (660 / 1440));
      }
    }

    &__arrow-left,
    &__arrow-right {
      position: absolute;
      top: 50%;
      z-index: 100;
    }

    &__arrow-left {
      left: 40px;
    }

    &__arrow-right {
      right: 40px;
    }

    &__pagination {
      &.swiper-pagination-bullets.swiper-pagination-horizontal {
        right: 58px;
        bottom: 50px;
      }
    }
  }
}

@include respond-up('medium') {
  .project-albums {
    &__tabs-wrap {
      top: 20px;
      padding: 0 20px;
    }
  }
}

@include respond('medium') {
  .project-albums {
    &__slide {
      &.swiper-slide {
        height: calc(var(--grid-column8) * (446 / 754));
      }
    }

    &__pagination {
      &.swiper-pagination-bullets.swiper-pagination-horizontal {
        left: 20px;
        right: 20px;
        bottom: 20px;
        width: calc(100% - 40px);
      }
    }
  }
}

@include respond-down('medium') {
  .project-albums {
    &__arrow-left,
    &__arrow-right {
      display: none;
    }

    &__tabs-wrap {
      left: 0;
      right: 0;
      width: 100%;
    }

    &__pagination {
      .swiper-pagination-bullet {
        flex: 0 1 100%;
      }
    }
  }
}

@include respond('small') {
  .project-albums {
    &__tabs-wrap {
      top: 10px;
      padding: 0 10px;
    }

    &__slide {
      &.swiper-slide {
        height: calc(var(--grid-column4) * (270 / 334));
      }
    }

    &__pagination {
      &.swiper-pagination-bullets.swiper-pagination-horizontal {
        left: 10px;
        right: 10px;
        bottom: 10px;
        width: calc(100% - 20px);
      }
    }
  }
}