.news-cards {
  &__all-news {
    width: 100%;
  }

  &__item {
    grid-column: span 4;
  }
}

@include respond-up('s-large') {
  .news-cards {
    &__list {
      margin-top: 30px;
    }

    &__all-news {
      margin-top: 50px;
    }
  }
}

@include respond('medium') {
  .news-cards {
    &__list {
      margin-top: 20px;
    }

    &__all-news {
      margin-top: 40px;
    }

    &__item {
      &:nth-child(3) {
        display: none;
      }
    }
  }
}

@include respond-down('small') {
  .news-cards {
    &__list {
      margin-top: 10px;
    }

    &__all-news {
      margin-top: 20px;
    }
  }
}