.info-card {
  background: var(--soft-gradient);
  display: flex;

  &__title {
    color: var(--blue-color);
  }
}

@include respond-up('s-large') {
  .info-card {
    padding: 60px 60px 70px;
  }
}

@include respond('medium') {
  .info-card {
    padding: 40px 40px 50px;
  }
}

@include respond-down('small') {
  .info-card {
    padding: 20px 20px 30px;
  }
}