.card-with-short-description {
  &__title-wrap {
    display: flex;
    flex-direction: column;
  }

  &__description {
    margin-top: 10px;
    color: var(--black-color-40);
  }
}

@include respond-up('medium') {
  .card-with-short-description {
    align-items: center;
    justify-content: space-between;
  }
}

@include respond-down('small') {
  .card-with-short-description {
    flex-direction: column;

    &__button-wrap {
      margin-top: 20px;
    }
  }
}