.index-projects {
  &._no-tabs {
    .index-projects__contents {
      border-top: 1px solid var(--grey-line-color);
    }
  }

  .card-big {
    border-top: none;
    padding-top: 0;
  }
}

@include respond-up('s-large') {
  .index-projects {
    &__tabs,
    &__contents {
      margin-top: 30px;
    }

    &._no-tabs {
      .index-projects__contents {
        padding-top: 30px;
      }
    }
  }
}

@include respond('medium') {
  .index-projects {
    &__tabs,
    &__contents {
      margin-top: 20px;
    }

    &._no-tabs {
      .index-projects__contents {
        padding-top: 20px;
      }
    }
  }
}

@include respond-down('small') {
  .index-projects {
    &__tabs,
    &__contents {
      margin-top: 10px;
    }

    &._no-tabs {
      .index-projects__contents {
        padding-top: 10px;
      }
    }
  }
}