.actions-page {
  &__list {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: var(--grid-gap);
  }
}

@include respond-up('s-large') {
  .actions-page {
    padding-bottom: 120px;

    &__list {
      grid-template-columns: repeat(2, 1fr);
      margin-top: 60px;
    }
  }
}

@include respond('medium') {
  .actions-page {
    padding-bottom: 80px;

    &__list {
      margin-top: 40px;
    }
  }
}

@include respond('small') {
  .actions-page {
    padding-bottom: 40px;

    &__list {
      margin-top: 20px;
    }
  }
}