.easy-to-start-item {
  &:first-child {
    .easy-to-start-item {
      &__title-wrap {
        padding-left: 10px;
      }
    }
  }

  &:last-child {
    .easy-to-start-item {
      &__title-wrap {
        padding-right: 10px;
      }
    }
  }

  &:not(:last-child) {
    margin-right: var(--grid-gap);

    .easy-to-start-item {
      &__title-wrap {
        position: relative;

        &::before {
          position: absolute;
          content: '';
          top: 0;
          left: 100%;
          height: 100%;
          width: var(--grid-gap);
          background: #fff;
        }
      }
    }
  }

  &.swiper-slide {
    width: var(--grid-column3);
  }

  &__title-wrap {
    background: #fff;
    display: flex;
    align-items: center;
    padding: 10px 0;
  }

  &__position {
    color: var(--blue-color);
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--grey-color);
  }
}

@include respond-up('s-large') {
  .easy-to-start-item {
    &__content {
      margin-top: 50px;
    }
  }
}

@include respond-up('medium') {
  .easy-to-start-item {
    &__position {
      width: 64px;
      height: 64px;
    }

    &__title {
      margin-left: 20px;
    }
  }
}

@include respond('medium') {
  .easy-to-start-item {
    &__content {
      margin-top: 30px;
    }
  }
}

@include respond-down('small') {
  .easy-to-start-item {
    &__position {
      width: 36px;
      height: 36px;
    }

    &__title {
      margin-left: 15px;
    }

    &__content {
      margin-top: 15px;
    }
  }
}