.breadcrumbs {
  &__list {
    display: flex;
    align-items: center;
  }

  &__item {
    display: flex;

    &_active {
      .breadcrumbs {
        &__name {
          color: var(--black-color-40);
        }
      }
    }

    &:nth-last-child(2) {
      .breadcrumbs {
        &__delimiter-line {
          background-color: var(--black-color-40);
        }
      }
    }
  }

  &__item_delimiter {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__link,
  &__item_delimiter-icon {
    display: flex;
  }

  &__delimiter-line {
    width: 14px;
    height: 1px;
    background-color: black;
  }

  &__name {
    transition: var(--default-transition);
  }
}

@include respond-up("s-large") {
  .breadcrumbs {
    &__link {
      &:hover {
        .breadcrumbs__name {
          color: var(--blue-color);
        }
      }
    }
  }
}

@include respond-up("medium") {
  .breadcrumbs {
    margin-top: 30px;

    &__list {
      margin: -4px;
    }

    &__item {
      padding: 4px 2px;

      &_delimiter {
        padding: 4px 1px 3px;
      }
    }

    &__link {
      transition: color .4s;
      padding: 4px;
      margin: -4px;
    }
  }
}

@include respond("medium") {
  .breadcrumbs {

  }
}

@include respond-down("small") {
  .breadcrumbs {
    margin-top: 15px;

    &__list {
      margin: -3px;
    }

    &__item {
      padding: 3px 1px;

      &:last-child {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &__link {
      padding: 3px;
      margin: -3px;
    }

    &__delimiter-line {
      width: 12px;
    }
  }
}
