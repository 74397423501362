.custom-select {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 20px;
    right: 20px;
    width: 10px;
    height: 6px;
    background-image: url('../images/svg/select-marker.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    transition: var(--default-transition);
  }

  select {
    border: unset;
    border-radius: 0;
    box-shadow: none;
    margin: 0;
    width: 100%;
    display: block;
    appearance: none;

    font-family: var(--h-font);
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 14px;
    height: 45px;
    padding: 14px 20px;
    background-color: var(--grey-color);

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-internal-autofill-selected {
      color: white;
      caret-color: white;
      -webkit-text-fill-color: white;
      -webkit-box-shadow: 0 0 0px 1000px var(--t) inset;
      transition: background-color 5000s ease-in-out 0s;
    }
  }
}

@include respond-down('small') {
  .custom-select {
    &::before {
      top: 16px;
      right: 16px;
    }

    select {
      font-size: 12px;
      height: 38px;
      padding: 12px 16px;
    }
  }
}