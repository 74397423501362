.vacancy-modal {

}

@include respond-up('s-large') {
  .vacancy-modal {
    max-width: 608px;
  }
}

@include respond-up('medium') {
  .vacancy-modal {
    .modal-layout__submit {
      margin-top: 40px;
    }

    &__bottom-fields {
      margin-top: 20px;
    }
  }
}

@include respond('medium') {
  .vacancy-modal {
    max-width: var(--grid-column6);
  }
}

@include respond-down('small') {
  .vacancy-modal {
    max-width: var(--grid-column4);

    .modal-layout__submit {
      margin-top: 20px;
    }

    &__bottom-fields {
      margin-top: 10px;
    }
  }
}