.news-card {
  position: relative;
  display: flex;
  flex-direction: column;
  border: 1px solid var(--grey-line-color);
  transition: var(--default-transition);

  &__date {
    z-index: 10;
    transition: var(--default-transition);
    flex: 0 0 auto;
  }

  &__title-wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 10;
    flex: 0 1 100%;
  }

  &__title {
    transition: var(--default-transition);
  }

  &__arrow-wrap {
    display: flex;
  }

  &__arrow {
    display: flex;
    height: max-content;

    svg path {
      transition: var(--default-transition);
    }
  }

  &__image-wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: var(--default-transition);
  }

  &__picture {
    display: flex;
    width: 100%;
    height: 100%;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: var(--black-color-40);
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@include respond-up('s-large') {
  .news-card {
    padding: 40px;
    height: calc(var(--grid-column4) * (316 / 440));

    &:hover {
      border-color: var(--grey-line-hover-color);

      .news-card {
        &__title {
          color: var(--blue-color);
        }

        &__arrow {
          svg path {
            stroke: var(--blue-color);
          }
        }
      }

      &._has-image {
        border-color: var(--grey-line-color);
        
        .news-card {
          &__image-wrap {
            opacity: 1;
          }

          &__title,
          &__date {
            color: white;
          }

          &__arrow {
            svg path {
              stroke: white;
            }
          }
        }
      }
    }

    &__title-wrap {
      margin-top: 20px;
    }
  }
}

@include respond-up('medium') {
  .news-card {
    &__arrow-wrap {
      justify-content: flex-end;
    }
  }
}

@include respond('medium') {
  .news-card {
    padding: 30px;
    height: 256px;

    &__title-wrap {
      margin-top: 15px;
    }
  }
}

@include respond-down('medium') {
  .news-card {
    &__image-wrap {
      display: none;
    }
  }
}

@include respond-down('small') {
  .news-card {
    padding: 20px;

    &__title-wrap {
      flex-direction: row;
      margin-top: 6px;
    }

    &__arrow-wrap {
      align-items: flex-end;
    }
  }
}