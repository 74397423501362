.advantages {
  display: grid;
  grid-gap: var(--grid-gap);

  &__icon {
    display: flex;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__subtitle {
    color: var(--black-color-40);
  }

  &__item {
    grid-column: span 1;
  }
}

@include respond-up('s-large') {
  .advantages {
    grid-template-columns: repeat(4, 1fr);
  }
}

@include respond-up('medium') {
  .advantages {
    &__icon {
      width: 60px;
      height: 60px;
    }

    &__title {
      margin-top: 20px;
    }
  }
}

@include respond-down('medium') {
  .advantages {
    grid-template-columns: repeat(2, 1fr);
  }
}

@include respond-down('small') {
  .advantages {
    &__icon {
      width: 46px;
      height: 46px;
    }

    &__title {
      margin-top: 10px;
    }
  }
}