.action-cards {
  &__all-news {
    width: 100%;
  }
}

@include respond-up('s-large') {
  .action-cards {
    &__item {
      grid-column: span 6;
    }

    &__list {
      margin-top: 30px;
    }

    &__all-news {
      margin-top: 50px;
    }
  }
}

@include respond('medium') {
  .action-cards {
    &__item {
      grid-column: span 8;
    }

    &__list {
      margin-top: 20px;
    }

    &__all-news {
      margin-top: 40px;
    }
  }
}

@include respond-down('small') {
  .action-cards {
    &__item {
      grid-column: span 4;
    }

    &__list {
      margin-top: 10px;
    }

    &__all-news {
      margin-top: 20px;
    }
  }
}