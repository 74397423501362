.index-contacts {

}

@include respond-up('s-large') {
  .index-contacts {
    &__content {
      margin-top: 30px;
    }
  }
}

@include respond-up('medium') {
  .index-contacts {

  }
}

@include respond('medium') {
  .index-contacts {
    &__content {
      margin-top: 20px;
    }
  }
}

@include respond-down('medium') {
  .index-contacts {

  }
}

@include respond-down('small') {
  .index-contacts {
    &__content {
      margin-top: 10px;
    }

    .contacts-block__contacts-list {
      margin-top: 0;
    }
  }
}