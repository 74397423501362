.drop-menu {
  background-color: white;

  &__links-container {
    display: flex;
    justify-content: space-between;
  }

  &__link {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid var(--grey-line-color);
    padding: 30px;
  }

  &__link-title {
    color: black;
    transition: var(--default-transition);
  }

  &__link-bottom {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }

  &__link-bottom-icon {
    display: flex;
    height: max-content;

    svg path {
      transition: var(--default-transition);
    }
  }

  &__list {
    display: grid;
    grid-template-rows: repeat(5, 1fr);
    grid-auto-flow: column;
    margin: -12px 0;
  }

  &__item-link {
    display: flex;
    padding: 12px 0;
  }

  &__item-link-title {
    color: black;
    transition: var(--default-transition);
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--grey-color);
    border-radius: 100%;
    width: 70px;
    height: 70px;
  }

  &__link-images {
    display: flex;
    margin: 0 0 -4px -4px;
  }

  &__link-image {
    display: flex;
    width: 78px;
    height: 78px;
    border-radius: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;

    &:last-child {
      margin-left: -28px;
    }

    &_left {
      background-image: url('../images/drop-menu/drop-menu-2.png');
    }

    &_right {
      background-image: url('../images/drop-menu/drop-menu-1.png');
    }
  }

  &__link-picture {
    display: flex;
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

@include respond-up('s-large') {
  .drop-menu {
    padding: 60px 0;

    &__links-container {
      grid-column: 1/7;
    }

    &__menu-container {
      grid-column: 8/13;
    }

    &__link {
      flex: 0 1 var(--grid-column3);
      height: calc((255 / 315) * var(--grid-column3));

      &:hover {
        border-color: var(--grey-line-hover-color);

        .drop-menu {
          &__link-title {
            color: var(--blue-color);
          }

          &__link-bottom-icon {
            svg path {
              stroke: var(--blue-color);
            }
          }
        }
      }
    }

    &__list {
      grid-template-columns: repeat(2, calc(var(--grid-column2) + var(--grid-gap)));
    }

    &__item-link {
      &:hover {
        .drop-menu {
          &__item-link-title {
            color: var(--blue-color);
          }
        }
      }
    }
  }
}

@include respond-up('medium') {
  .drop-menu {

  }
}

@include respond('medium') {
  .drop-menu {
    padding: 40px 0 48px;

    &__inner {
      grid-row-gap: 60px;
    }

    &__links-container,
    &__menu-container {
      grid-column: 1/9;
    }

    &__links-container {

    }

    &__link {
      flex: 0 1 var(--grid-column4);
      height: 215px;
    }
  }
}

@include respond-down('medium') {
  .drop-menu {
    &__list {
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: var(--grid-gap);
    }
  }
}

@include respond-down('small') {
  .drop-menu {
    padding: 20px 0 30px;

    &__inner {
      grid-row-gap: 40px;
    }

    &__links-container,
    &__menu-container {
      grid-column: 1/5;
    }

    &__links-container {
      flex-direction: column;
    }

    &__link {
      flex-direction: row;
      align-items: center;
      padding: 14px 14px 14px 20px;

      &_layouts {
        margin-top: 20px;
      }
    }

    &__list {
      margin: -10px 0;
    }

    &__item-link {
      padding: 10px 0;
    }

    &__link-bottom-icon {
      display: none;
    }

    &__icon {
      width: 46px;
      height: 46px;
    }

    &__link-image {
      width: 54px;
      height: 54px;
    }

    &__link-images {
      margin: -4px -4px -4px 0;
    }
  }
}