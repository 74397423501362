.index-about {
  &__button {
    width: fit-content;
  }

  &__button-wrap {
    display: flex;
    justify-content: flex-start;
  }
}

@include respond-up('s-large') {
  .index-about {
    &__heading-wrap {
      &.grid-block {
        grid-row-gap: 40px;
      }
    }

    &__block-heading {
      grid-column: 1/13;
    }

    &__button-wrap {
      grid-column: 7/13;
    }

    &__about-points {
      margin-top: 60px;
    }
  }
}

@include respond-up('medium') {
  .index-about {

  }
}

@include respond('medium') {
  .index-about {
    &__heading-wrap {
      &.grid-block {
        grid-row-gap: 30px;
      }
    }

    &__block-heading {
      grid-column: 1/9;
    }

    &__button-wrap {
      grid-column: 1/9;
    }

    &__about-points {
      margin-top: 50px;
    }
  }
}

@include respond-down('medium') {
  .index-about {

  }
}

@include respond-down('small') {
  .index-about {
    &__heading-wrap {
      &.grid-block {
        grid-row-gap: 20px;
      }
    }

    &__block-heading {
      grid-column: 1/5;
    }

    &__button-wrap {
      grid-column: 1/5;
    }

    &__about-points {
      margin-top: 30px;
    }
  }
}