.arrow {
  display: flex;
  width: max-content;

  &__icon {
    display: flex;
    height: max-content;
  }

  &_left {
    transform: rotate(180deg);
  }

  &_right {

  }

  &_blue {

  }

  &_white {
    svg path {
      fill: white;
    }
  }
}

@include respond-up('s-large') {
  .arrow {
    &__icon {
      svg path {
        transition: fill .3s var(--default-transition-function);
      }
    }

    &_blue {
      &:hover {
        svg path {
          fill: var(--blue-hover-color);
        }
      }
    }

    &_white {
      svg path {
        fill: var(--white-color-80);
      }
    }
  }
}

@include respond-up('medium') {
  .arrow {
    &__icon {
      &_mob {
        display: none;
      }
    }
  }
}

@include respond('medium') {
  .arrow {

  }
}

@include respond-down('small') {
  .arrow {
    &__icon {
      &_desk {
        display: none;
      }

      &_mob {
        display: flex;
      }
    }
  }
}