.hamburger {
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;

  &__icon {
    position: relative;
    height: 30px;
  }

  &__line {
    position: absolute;
    left: 0;
    height: 2px;
    width: 100%;
    background-color: var(--blue-color);
    transition: var(--default-transition);

    &:nth-child(1) {
      top: 7px;
    }

    &:nth-child(2) {
      top: 50%;
      transform: translate3d(0, -50%, 0);
    }

    &:nth-child(3) {
      bottom: 7px;
    }
  }

  &._opened {
    .hamburger {
      &__line {
        width: 36px;

        &:nth-child(1) {
          transform: translate3d(0, 7px, 0) rotate(45deg);
        }

        &:nth-child(2) {
          width: 0;
        }

        &:nth-child(3) {
          transform: translate3d(0, -7px, 0) rotate(-45deg);
        }
      }
    }
  }

  &._light {
    .hamburger {
      &__line {
        background-color: #fff;
      }
    }
  }
}

@include respond-up('s-large') {
  .hamburger {
    &:not(._opened) {
      &:hover {
        .hamburger {
          &__line {
            &:nth-child(2) {
              width: 34px;
            }
          }
        }
      }
    }
  }
}

@include respond-up('medium') {
  .hamburger {
    &__icon {
      width: 46px;
    }
  }
}

@include respond-down('small') {
  .hamburger {
    &__icon {
      width: 30px;
    }

    &__line {
      &:nth-child(1) {
        top: 8px;
      }

      &:nth-child(3) {
        bottom: 8px;
      }
    }

    &._opened {
      .hamburger {
        &__line {
          width: 26px;

          &:nth-child(1) {
            transform: translate3d(0, 6px, 0) rotate(45deg);
          }

          &:nth-child(3) {
            transform: translate3d(0, -6px, 0) rotate(-45deg);
          }
        }
      }
    }
  }
}