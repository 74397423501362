.about-page {
  &__director {
    background: linear-gradient(180deg, #0C247B 0%, #060C21 100%);
  }

  &__director-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__director-picture {
    display: flex;
  }

  &__director-texts-wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__director-position {
    color: var(--white-color-40);
  }

  &__director-name,
  &__director-quote {
    color: white;
  }

  &__director-wrap {
    display: flex;
  }
}

@include respond-up('s-large') {
  .about-page {
    &__director-wrap {
      justify-content: space-between;
    }

    &__director-quote {
      margin-top: 30px;
    }

    &__director-texts-wrap {
      padding: var(--grid-gap) 0 var(--grid-gap) var(--grid-gap);
      flex: 0 0 var(--grid-column8);
    }

    &__director-picture {
      flex: 0 0 var(--grid-column4);
    }

    &__director-link {
      margin-top: 60px;
    }

    &__description {
      width: var(--grid-column9);
      margin-top: 60px;
    }

    &__philosophy {
      display: grid;
      grid-template-columns: repeat(2, var(--grid-column6));
      grid-column-gap: var(--grid-gap);
    }

    &__philosophy,
    &__director,
    &__image-different-slider,
    &__cards,
    &__image-sliders {
      margin-top: 120px;
    }

    &__characteristics {
      margin-top: 80px;
    }

    &__principles {
      margin-top: 140px;
    }
  }
}

@include respond('medium') {
  .about-page {
    &__description {
      margin-top: 40px;
    }

    &__director,
    &__philosophy,
    &__principles,
    &__image-different-slider,
    &__cards,
    &__image-sliders {
      margin-top: 80px;
    }

    &__director-wrap {
      padding: 40px 40px 50px 40px;
    }

    &__director-texts-wrap {
      flex: 0 0 var(--grid-column5);
      padding-right: var(--grid-gap);
    }

    &__director-quote {
      margin-top: 30px;
    }

    &__director-link {
      margin-top: 40px;
    }

    &__director-picture {
      flex: 0 0 calc(var(--grid-column3) - 40px);
      height: calc(275 / 218 * (var(--grid-column3) - 40px));
    }

    &__philosophy {
      grid-row-gap: 25px;
    }

    &__characteristics {
      margin-top: 50px;
    }
  }
}

@include respond-down('medium') {
  .about-page {
    &__philosophy {
      display: grid;
    }
  }
}

@include respond-down('small') {
  .about-page {
    &__description {
      margin-top: 20px;
    }

    &__director,
    &__philosophy,
    &__principles,
    &__image-different-slider,
    &__cards,
    &__image-sliders {
      margin-top: 40px;
    }

    &__director-texts-wrap {
      margin-top: 15px;
    }

    &__director-link,
    &__director-quote {
      margin-top: 20px;
    }

    &__director-wrap {
      flex-direction: column-reverse;
      padding: 20px 20px 30px;
    }

    &__director-picture {
      width: calc(var(--grid-column2) - 20px);
      height: calc(173 / 137 * var(--grid-column2));
    }

    &__philosophy {
      grid-row-gap: 15px;
    }

    &__characteristics {
      margin-top: 30px;
    }
  }
}