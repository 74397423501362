.arrows {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 138px;

  &__pagination {
    &.swiper-pagination {
      position: relative;
      @extend .texts;
      @extend .texts_paragraph-6;
      //margin: 0 22px;
      bottom: unset;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &._hidden {
    opacity: 0;
    pointer-events: none;
  }

  .swiper-pagination-current {
    display: block;
    text-align: end;
    width: 20px;
  }

  .swiper-pagination-total {
    display: block;
    text-align: start;
    width: 20px;
  }
}

@include respond-down('small') {
  .arrows {
    width: 109px;

    &__pagination {
      &.swiper-pagination {
        //margin: 0 17px;
      }
    }
  }
}