.about-principles {
  grid-row-gap: 0;

  &__item {
    &._opened {
      .about-principles {
        &__item-link-icon {
          .button__icon::after {
            transform: translate3d(-50%, -50%, 0) rotate(180deg);
          }
        }

        &__item-title {
          color: var(--blue-color);
        }
      }
    }
  }

  &__item-title {
    color: var(--grey-icon-color);
    transition: var(--default-transition);
  }

  &__block {
    height: 0;
    overflow: hidden;
    transition: height 0.3s var(--default-transition-function);
  }

  &__item-link {
    position: relative;
    display: flex;
    align-items: center;
  }
}

@include respond-up('s-large') {
  .about-principles {
    &__title {
      grid-column: 1/6;
    }

    &__list {
      grid-column: 6/13;
      margin: -10px 0;
    }

    &__item-link {
      padding: 10px 0 10px calc(var(--grid-column) - 16px);

      &:hover {
        .about-principles {
          &__item-link-icon {
            background-color: var(--grey-hover-color);
            border-color: var(--grey-hover-color);
          }

          &__item-title {
            color: var(--blue-color);
          }
        }
      }
    }

    &__block {
      padding-left: calc(var(--grid-column1) + var(--grid-gap));
    }

    &__item-description {
      width: var(--grid-column5);
      padding: 20px 0 50px;
    }
  }
}

@include respond-up('medium') {
  .about-principles {
    &__item-title {
      margin-left: 30px;
    }
  }
}

@include respond('medium') {
  .about-principles {
    &__title,
    &__list {
      grid-column: 1/9;
    }

    &__list {
      margin: 18px 0 -7px;
    }

    &__item-link {
      padding: 7px 0;
    }

    &__block {
      padding-left: 76px;
    }

    &__item-description {
      width: var(--grid-column6);
      padding: 13px 0 33px;
    }
  }
}

@include respond-down('small') {
  .about-principles {
    &__title,
    &__list {
      grid-column: 1/5;
    }

    &__list {
      margin: 9px 0 -6px;
    }

    &__item-link {
      padding: 6px 0;
    }

    &__item-title {
      margin-left: 20px;
    }

    &__block {
      padding-left: 52px;
    }

    &__item-description {
      padding: 9px 0 25px;
    }
  }
}
