.article-layout {
  &__inner {
    border-top: 1px solid var(--grey-line-color);
  }

  &__part-image-title {
    color: var(--black-color-40);
  }

  &__picture-small,
  &__picture {
    display: flex;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@include respond-up('s-large') {
  .article-layout {
    padding-bottom: 120px;

    &__inner {
      display: grid;
      grid-template-columns: var(--grid-column3) 1fr;
      grid-gap: var(--grid-gap);
      padding-top: 30px;
    }

    &__picture-small {
      width: var(--grid-column7);
      height: calc(483 / 815 * var(--grid-column7));
    }

    &__picture {
      width: 100%;
      height: calc(529 / 1065 * var(--grid-column9));
      margin-bottom: 60px;
    }

    &__body {
      width: var(--grid-column7);
    }

    &__part {
      &:not(:first-child) {
        margin-top: 60px;
      }

      h1, h2, h3 {
        margin-top: 60px;
      }

      p {
        margin-top: 25px;
      }

      ul, ol {
        margin-top: 30px;
      }
    }

    &__also {
      margin-top: 120px;
    }
  }
}

@include respond-up('medium') {
  .article-layout {
    &__part-image-title {
      margin-top: 10px;
    }
  }
}

@include respond('medium') {
  .article-layout {
    padding-bottom: 80px;

    &__inner {
      padding-top: 20px;
    }

    &__picture-small {
      width: var(--grid-column8);
      height: calc(447 / 754 * var(--grid-column8));
    }

    &__picture {
      width: 100%;
      height: calc(375 / 754 * var(--grid-column8));
      margin-bottom: 50px;
    }

    &__part {
      &:not(:first-child) {
        margin-top: 50px;
      }

      h1, h2, h3 {
        margin-top: 50px;
      }

      p {
        margin-top: 20px;
      }

      ul, ol {
        margin-top: 30px;
      }
    }

    &__also {
      margin-top: 80px;
    }
  }
}

@include respond-down('medium') {
  .article-layout {
    &__inner {
      display: flex;
      flex-direction: column-reverse;
    }
  }
}

@include respond-down('small') {
  .article-layout {
    padding-bottom: 40px;

    &__inner {
      padding-top: 10px;
    }

    &__picture-small {
      width: var(--grid-column4);
      height: calc(198 / 334 * var(--grid-column4));
    }

    &__part-image-title {
      margin-top: 6px;
    }

    &__picture {
      width: 100%;
      height: calc(166 / 334 * var(--grid-column4));
      margin-bottom: 30px;
    }

    &__part {
      &:not(:first-child) {
        margin-top: 30px;
      }

      h1, h2, h3 {
        margin-top: 30px;
      }

      p {
        margin-top: 10px;
      }

      ul, ol {
        margin-top: 20px;
      }
    }

    &__also {
      margin-top: 40px;
    }
  }
}