.construction-item {
  &__link {
    display: flex;
    flex-direction: column;
  }

  &__slide-info {
    display: flex;
    flex-direction: column;
  }

  &__album-info {
    color: var(--black-color-40);

    &_live {
      position: relative;

      &:before {
        position: absolute;
        content: '';
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: var(--alert-color);
        left: 0;
        top: 50%;
        transform: translate3d(0, -50%, 0);
      }
    }
  }

  &__slide-title-wrap {
    display: flex;
    justify-content: space-between;
  }

  &__online {
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(180deg, #0C247B 0%, #060C21 100%);
  }

  &__album-list {
    display: none;
  }

  &__online,
  &__image-wrap,
  &__picture {
    width: 100%;
    display: flex;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@include respond-up('s-large') {
  .construction-item {
    &__online,
    &__picture {
      height: calc(var(--grid-column3) * (215 / 315));
    }
  }
}

@include respond-up('medium') {
  .construction-item {
    &__slide-info {
      margin-top: 10px;
    }

    &__slide-title-wrap {
      align-items: center;
    }

    &__album-info {
      &_live {
        padding-left: 18px;
      }
    }
  }
}

@include respond('medium') {
  .construction-item {
    &__online,
    &__picture {
      height: calc(var(--grid-column3) * (176 / 258));
    }
  }
}

@include respond-down('medium') {
  .construction-item {

  }
}

@include respond-down('small') {
  .construction-item {
    &__album-info {
      &_live {
        padding-left: 16px;
      }
    }

    &__online,
    &__picture {
      height: calc(var(--grid-column3) * (168 / 246));
    }
  }
}