.header {
  height: var(--header-height);
  transition: opacity .3s var(--default-transition-function);

  &._sticky {
    .header {
      &__container {
        transform: translate3d(0, -100%, 0);
      }
    }

    &._up {
      .header {
        &__container {
          transform: translate3d(0, 0, 0) !important;
          //transition-duration: .3s !important;
        }
      }
    }
  }

  &._opened {
    .header {
      &__container {
        transform: translate3d(0, 0, 0) !important;
      }

      &__drop-menu {
        opacity: 1;
        visibility: visible;
        pointer-events: auto;
      }
    }
  }

  &._heightless {
    height: 0;
  }

  &._transparent:not(._opened) {
    .header {
      &__container {
        background-color: var(--t);
        border-bottom-color: var(--t);
        color: white;
      }

      &__modal {
        border-color: var(--white-color-40);
        color: white;
      }

      &__logo-icon {
        svg {
          path {
            &:first-child {
              fill: white;
            }

            &:last-child {
              fill: var(--blue-light-color);
            }
          }
        }
      }

      &__menu-link {
        color: white;
      }

      &__project-link {
        border-color: var(--white-color-40);
      }

      &__project-link-icon {
        svg path {
          fill: white;
        }
      }

      &__modal_mobile {
        border-color: var(--white-color-40);

        svg path {
          fill: white;
        }
      }

      &__phone-link {
        .header__phone-title {
          color: white;
        }

        &::before,
        &::after {
          border-color: var(--white-color-40);
        }

        svg path {
          fill: white;
        }
      }
    }

    .hamburger {
      &__line {
        background-color: #fff;
      }
    }
  }

  &__container {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    right: 0;
    background: white;
    padding: 0;
    border-bottom: 1px solid var(--grey-line-color);
    transition: var(--default-transition);
  }

  &__container-inner {
    display: flex;
    justify-content: space-between;
    height: var(--header-height);
  }

  &__left {
    display: flex;
    align-items: center;
    flex: 0 0 auto;
  }

  &__right {
    display: flex;
    align-items: center;
    flex: 0 0 auto;
  }

  &__logo-icon {
    width: 120px;
    height: 38px;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__logo,
  &__menu {
    display: flex;
    align-items: center;
    height: 100%;
  }

  &__project-link {
    display: none;
  }

  &__menu-item {
    height: 100%;
  }

  &__menu-link {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 10px;
    transition: var(--default-transition);
  }

  &__phone-link {
    display: flex;
    align-items: center;
    height: 100%;
  }

  &__phone-icon {
    display: none;
  }

  &__hamburger {
    height: 100%;
    padding: 0 20px;
    margin-right: -20px;
  }

  &__drop-menu {
    position: absolute;
    top: calc(100% + 1px);
    right: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: var(--default-transition);
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.08);
  }
}

@include respond-up('s-large') {
  .header {
    &__menu {
      margin-left: 50px;
    }

    &__menu-link {
      &:hover {
        color: var(--blue-color);
      }
    }

    &__modal {
      margin-left: 40px;

      &.button_small {
        padding: 14px 20px;
      }

      &_mobile {
        display: none;
      }
    }

    &__phone-title {
      transition: var(--default-transition);
    }

    &__phone-link {
      &:hover {
        .header__phone-title {
          color: var(--blue-color);
        }
      }
    }

    &._transparent:not(._opened) {
      .header {
        &__modal {
          &:hover {
            border-color: white;
          }
        }

        &__phone-link {
          &:hover {
            .header__phone-title {
              color: var(--white-color-80);
            }
          }
        }

        &__menu-link {
          &:hover {
            color: var(--white-color-80);
          }
        }
      }
    }
  }
}

@include respond-down('s-large') {
  .header {

  }
}

@include respond-up('medium') {
  .header {
    &__hamburger {
      margin-left: 20px;
    }
  }
}

@include respond('medium') {
  .header {
    &__project-link {
      padding: 13px 24px 13px 22px;
      border: 1px solid var(--grey-line-color);
      margin-left: 40px;
    }

    &__project-link-title {
      margin-left: 10px;
    }

    &__modal {
      margin-left: 30px;
    }
  }
}

@include respond-down('medium') {
  .header {
    &__menu {
      display: none;
    }

    &__project-link {
      display: flex;
      align-items: center;
    }

    &__project-link-icon {
      display: flex;
      height: max-content;

      svg {
        path {
          fill: var(--grey-icon-color);
        }
      }
    }

    &__modal {
      &_desk {
        &.button {
          display: none;
        }
      }

      &_mobile {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid var(--blue-color-40);
        border-radius: 100%;
        width: 46px;
        height: 46px;

        svg path {
          fill: var(--blue-color);
        }
      }
    }
  }
}

@include respond('small') {
  .header {
    &__left {
      flex: 0 1 100%;
      justify-content: space-between;
    }

    &__logo-icon {
      width: 76px;
      height: 24px;
    }

    &__project-link {
      height: 100%;
      padding: 0 15px;
    }

    &__project-link-title {
      margin-left: 6px;
      font-family: var(--font);
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%;
      letter-spacing: -0.24px;
      padding-top: 2px;
      text-transform: capitalize;
    }

    &__phone-link {
      position: relative;
      padding: 0 12px;

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate3d(0, -50%, 0);
        width: 1px;
        height: calc(100% - 15px);
        border-left: 1px solid var(--grey-line-color);
      }

      &::after {
        content: '';
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate3d(0, -50%, 0);
        width: 1px;
        height: calc(100% - 15px);
        border-left: 1px solid var(--grey-line-color);
      }
    }

    &__phone-icon {
      display: flex;
      height: max-content;

      svg path {
        fill: var(--blue-color);
      }
    }

    &__phone-title {
      display: none;
    }

    &__modal {
      display: none;

      &.button {
        display: none;
      }
    }
  }
}