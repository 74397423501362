.project-buildings {
  &__list {
    border-top: 1px solid var(--grey-line-color);
  }
}

@include respond-up('s-large') {
  .project-buildings {
     &__list {
       margin-top: 30px;
       padding-top: 30px;
    }
  }
}

@include respond('medium') {
  .project-buildings {
    &__list {
      margin-top: 20px;
      padding-top: 20px;
    }
  }
}

@include respond-down('small') {
  .project-buildings {
    &__list {
      margin-top: 10px;
      padding-top: 10px;
    }
  }
}