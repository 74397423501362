.error-page {
  display: grid;
  grid-gap: var(--grid-gap);
  margin-top: var(--grid-gap);
  margin-bottom: var(--grid-gap);

  &__info {
    border: 1px solid var(--grey-line-color);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__code {
    font-family: var(--h-font);
    color: var(--blue-color);
  }

  &__code-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(180deg, #F2F4F9 0%, #E5E9F8 100%);
  }
}

@include respond-up('s-large') {
  .error-page {
    grid-template-columns: repeat(2, var(--grid-column6));

    &__code-wrap {
      height: calc(523 / 1640 * 100vw);
    }

    &__code {
      font-size: calc(170 / 1640 * 100vw);
    }

    &__title {
      padding-right: calc(var(--grid-column1) + var(--grid-gap) - 40px);
    }

    &__description {
      padding-right: calc(var(--grid-column2) + var(--grid-gap) - 40px);
    }

    &__button {
      margin-top: 40px;
    }
  }
}

@include respond-up('medium') {
  .error-page {
    &__info {
      padding: 40px;
    }
  }
}

@include respond('medium') {
  .error-page {
    &__code {
      font-size: calc(120 / 834 * 100vw);
    }

    &__code-wrap {
      height: calc(277 / 834 * 100vw);
    }

    &__info {
      padding-right: calc(var(--grid-column1) + var(--grid-gap));
    }

    &__description {
      margin-top: 20px;
    }

    &__button {
      margin-top: 30px;
    }
  }
}

@include respond-down('small') {
  .error-page {
    &__code {
      font-size: calc(70 / 375 * 100vw);
    }

    &__code-wrap {
      height: calc(189 / 375 * 100vw);
    }

    &__info {
      padding: 20px;
    }

    &__description {
      margin-top: 10px;
    }

    &__button {
      margin-top: 20px;
    }
  }
}