.modal-success {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__icon {
    display: flex;
    width: 60px;
    height: 60px;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__title,
  &__subtitle {
    text-align: center;
  }

  &__title {
    margin-top: 30px;
  }

  &__subtitle {
    color: var(--black-color-40);
    margin-top: 20px;
  }
}

@include respond-up('s-large') {
  .modal-success {
    &__title,
    &__subtitle {
      max-width: 360px;
    }

    &_recall {
      min-height: 513px;
    }

    &_warranty {
      min-height: 639px;
      min-width: 608px;
    }
  }
}

@include respond('medium') {
  .modal-success {
    &__subtitle {
      margin-top: 15px;
    }
  }
}

@include respond-down('medium') {
  .modal-success {
    &__title,
    &__subtitle {
      max-width: var(--grid-column4);
    }
  }
}

@include respond-down('small') {
  .modal-success {
    &__icon {
      width: 46px;
      height: 46px;
    }

    &__title {
      margin-top: 20px;
    }

    &__subtitle {
      margin-top: 8px;
    }
  }
}