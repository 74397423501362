.base-grid-slider {
  position: relative;

  &__images-slider {
    &::before {
      content: '';
      position: absolute;
      z-index: 25;
      bottom: 0;
      left: 0;
      width: 100%;
      background: linear-gradient(180deg, rgba(7, 20, 46, 0.00) 0%, rgba(7, 20, 46, 0.30) 100%);
      pointer-events: none;
    }
  }

  &__slide {
    position: relative;
  }

  &__picture {
    display: flex;
    width: 100%;
    height: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    pointer-events: none;
  }

  &__pagination {
    &.swiper-pagination-bullets.swiper-pagination-horizontal {
      z-index: 50;
      left: unset;
      width: max-content;
    }

    .swiper-pagination-bullet {
      flex: 0 1 100%;
    }
  }

  &__prev,
  &__next {
    position: absolute;
    z-index: 5;
    top: 0;
    bottom: 0;
    width: var(--grid-column3);
    height: 100%;
    opacity: 0;
    transition: var(--default-transition);

    svg {
      position: absolute;
      z-index: 5;
      top: 50%;
      transform: translate3d(0, -50%, 0);

      path {
        fill: white;
      }
    }

    &:hover {
      opacity: 1;
    }
  }

  &__prev {
    left: 0;
    background: linear-gradient(270deg, rgba(7, 20, 46, 0.00) 0%, rgba(7, 20, 46, 0.30) 100%);

    svg {
      left: 40px;
      transform: rotate(180deg);
    }
  }

  &__next {
    right: 0;
    background: linear-gradient(90deg, rgba(7, 20, 46, 0.00) 0%, rgba(7, 20, 46, 0.30) 100%);

    svg {
      right: 40px;
    }
  }

  &__text-slide-title {
    color: white;
  }

  &__text-slider {
    position: absolute;
    z-index: 75;
    display: flex;
    align-items: flex-end;
    pointer-events: none;
  }

  &__text-slide {
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    height: max-content;
    z-index: 5;
    overflow: hidden;
    transition: transform 0s, opacity 0s;
    transition-timing-function: var(--default-transition-function);
    opacity: 0;

    &._active {
      z-index: 10;
      transform: translate3d(0, 0, 0);
      opacity: 1;
      transition-duration: .7s;
    }

    &._in-next-act {
      transform: translate3d(25px, 0, 0) !important;
      opacity: 0 !important;
    }

    &._out-next-act {
      transform: translate3d(-25px, 0, 0);
      transition-duration: .3s;
    }

    &._in-prev-act {
      transform: translate3d(-25px, 0, 0) !important;
      opacity: 0 !important;
    }

    &._out-prev-act {
      transform: translate3d(25px, 0, 0);
      transition-duration: .3s;
    }
  }
}

@include respond-up('s-large') {
  .base-grid-slider {
    &__images-slider {
      &::before {
        height: calc((172 / 1640) * 100vw);
      }
    }

    &__slide.swiper-slide,
    &__picture-wrap {
      height: calc(var(--grid-column12) * (660 / 1440));
    }

    &__pagination {
      &.swiper-pagination-bullets.swiper-pagination-horizontal {
        right: 58px;
        bottom: 50px;
        width: var(--grid-column5);
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }

      .swiper-pagination-bullet {
        max-width: 132px;
      }
    }

    &__text-slider {
      top: 50px;
      right: 60px;
      bottom: 50px;
      left: 60px;
    }
  }
}

@include respond('medium') {
  .base-grid-slider {
    &__images-slider {
      &::before {
        height: calc((177 / 834) * 100vw);
      }
    }

    &__slide.swiper-slide,
    &__picture-wrap {
      height: calc(var(--grid-column8) * (446 / 754));
    }

    &__pagination {
      &.swiper-pagination-bullets.swiper-pagination-horizontal {
        left: 20px;
        right: 20px;
        bottom: 20px;
        width: calc(100% - 40px);
      }
    }

    &__text-slider {
      top: 97px;
      right: 20px;
      bottom: 42px;
      left: 20px;
    }
  }
}

@include respond-down('medium') {
  .base-grid-slider {
    &__prev,
    &__next {
      display: none;
    }
  }
}

@include respond-down('small') {
  .base-grid-slider {
    &__images-slider {
      &::before {
        height: calc((105 / 374) * 100vw);
      }
    }

    &__slide.swiper-slide,
    &__picture-wrap {
      height: calc(var(--grid-column4) * (270 / 334));
    }

    &__pagination {
      &.swiper-pagination-bullets.swiper-pagination-horizontal {
        left: 10px;
        right: 10px;
        bottom: 10px;
        width: calc(100% - 20px);
      }
    }

    &__text-slider {
      top: 58px;
      right: 10px;
      bottom: 27px;
      left: 10px;
    }
  }
}