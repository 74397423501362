.about-cards {
  &__item-icon {
    display: flex;
    width: var(--icon-size);
    height: var(--icon-size);

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__list {
    display: grid;
    grid-gap: var(--grid-gap);
  }

  &__item-wrap {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__item {
    border: 1px solid var(--grey-line-color);
    display: flex;
    flex-direction: column;
  }
}

@include respond-up('s-large') {
  .about-cards {
    &__item {
      padding: 40px;
    }

    &__list {
      grid-template-columns: repeat(2, var(--grid-column6));
    }

    &__item-text {
      margin-top: 60px;
    }
  }
}

@include respond-up('medium') {
  .about-cards {
    --icon-size: 60px;
  }
}

@include respond('medium') {
  .about-cards {
    &__item-text {
      padding-right: calc(var(--grid-column1) + var(--grid-gap) - 40px);
    }

    &__item {
      padding: 40px;
    }

    &__item-text {
      margin-top: 50px;
    }
  }
}

@include respond-down('small') {
  .about-cards {
    --icon-size: 46px;

    &__item-text {
      padding-right: 12px;
    }

    &__item {
      padding: 20px;
    }

    &__item-text {
      margin-top: 30px;
    }
  }
}