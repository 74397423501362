.uikit-page {
  padding-bottom: 100px;

  --black-ui-color: black;
  --blue-ui-color: blue;
  --background-ui-color: var(--primary-color);

  &__element_warning {
    margin-top: 50px;
    padding: 10px 10px 10px 50px;
    background-color: var(--background-ui-color);
    color: white;
    font-weight: 500;
    flex: 0 0 85%;
    position: relative;

    &:before {
      position: absolute;
      content: '';
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 34px 0 34px 34px;
      border-color: transparent transparent transparent white;
    }
  }

  &__elements-list {
    margin-top: 75px;
  }

  &__element {
    &:not(:first-child) {
      margin-top: 40px;
    }

    &._wide {
      flex: 0 0 100% !important;
    }

    &._bg {
      padding: 20px;
      background: var(--blue-white-color-gradient);
    }
  }

  &__element-description {
    margin-top: 20px;
    padding: 20px;
    background: var(--black-ui-color);
    color: white;
  }

  &__elements-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__buttons {
    padding: 20px;
    width: 48%;
    background: var(--blue-white-color-gradient);

    .uikit-page__element._wide {
      margin-top: unset !important;
    }

    &_big {
      display: grid;

      .button {
        width: 100%;
      }
    }

    &_another {
      width: 100%;
    }
  }
}

@include respond-up('s-large') {
  .uikit-page {
    &__element {
      flex: 0 0 48%;
    }
  }
}

@include respond('medium') {
  .uikit-page {

  }
}

@include respond-down('medium') {
  .uikit-page {
    &__element {
      flex: 0 0 100%;
    }
  }
}

@include respond-down('small') {
  .uikit-page {

  }
}