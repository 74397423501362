.online-cams {
  &__top {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      z-index: 10;
      top: 0;
      right: 0;
      width: 70px;
      height: 100%;
      background: linear-gradient(270deg, #F5F5F5 0%, rgba(245, 245, 245, 0.00) 100%);
      pointer-events: none;
    }
  }

  &__anchor-list {
    display: flex;
    align-items: stretch;
    background-color: var(--grey-color);
    height: 90px;
    padding: 0 30px;
    overflow-x: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__anchor-link {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 10px;
  }

  &__anchor-link-title {
    white-space: nowrap;
    transition: var(--default-transition);
  }

  &__project {
    display: flex;
  }

  &__project-cameras {
    display: grid;
    grid-column-gap: var(--grid-gap);
  }

  &__project-info {
    display: flex;
    flex-direction: column;
  }

  &__project-total {
    position: relative;
    padding-left: 18px;
    color: var(--black-color-40);

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      width: 8px;
      height: 8px;
      transform: translate3d(0, -50%, 0);
      background-color: var(--alert-color);
      border-radius: 100%;
    }
  }

  &__project-camera {
    position: relative;
  }
}

@include respond-up('s-large') {
  .online-cams {
    padding-bottom: 120px;

    &__anchor-link {
      &:hover {
        .online-cams__anchor-link-title {
          color: var(--blue-color);
        }
      }
    }

    &__main {
      margin-top: 60px;
    }

    &__project {
      justify-content: space-between;
      border-top: 1px solid var(--grey-line-color);
      padding-top: 30px;
    }

    &__project-info {
      flex: 0 0 var(--grid-column3);
    }

    &__project-cameras {
      flex: 0 0 var(--grid-column9);
      grid-template-columns: repeat(3, var(--grid-column3));
      grid-row-gap: 50px;
    }

    &__project-total {
      margin-top: 10px;

      &::before {
        top: calc(50% + 1px);
      }
    }

    &__project-camera {
      &:nth-child(3n+1),
      &:nth-child(3n+2) {
        &::before {
          content: '';
          position: absolute;
          top: 0;
          right: calc(var(--grid-gap) / -2);
          height: 100%;
          border-left: 1px solid var(--grey-line-color);
        }
      }
    }
  }
}

@include respond-up('medium') {
  .online-cams {
    &__project {
      &:not(:first-child) {
        margin-top: 50px;
      }
    }
  }
}

@include respond('medium') {
  .online-cams {
    padding-bottom: 80px;

    &__top {
      &::before {
        width: 60px;
      }
    }

    &__main {
      margin-top: 40px;
    }

    &__project-cameras {
      padding-top: 20px;
      margin-top: 20px;
      grid-row-gap: 30px;
    }

    &__project-info {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
  }
}

@include respond-down('medium') {
  .online-cams {
    &__project {
      flex-direction: column;
    }

    &__project-cameras {
      border-top: 1px solid var(--grey-line-color);
      grid-template-columns: repeat(2, 1fr);
    }

    &__project-camera {
      &:nth-child(2n+1) {
        &::before {
          content: '';
          position: absolute;
          top: 0;
          right: calc(var(--grid-gap) / -2);
          height: 100%;
          border-left: 1px solid var(--grey-line-color);
        }
      }
    }
  }
}

@include respond-down('small') {
  .online-cams {
    padding-bottom: 40px;

    &__top {
      &::before {
        width: 50px;
      }
    }

    &__anchor-list {
      height: 60px;
      padding: 0 10px;
    }

    &__main {
      margin-top: 20px;
    }

    &__project {
      &:not(:first-child) {
        margin-top: 30px;
      }
    }

    &__project-cameras {
      padding-top: 10px;
      margin-top: 10px;
      grid-row-gap: 20px;
    }

    &__project-total {
      margin-top: 2px;
      padding-left: 16px;

      &::before {
        top: calc(50% - 1px);
      }
    }
  }
}