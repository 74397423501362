.purchase-methods-page {

}

@include respond-up('s-large') {
  .purchase-methods-page {
    padding-bottom: 120px;

    &__info-card {
      margin-top: 60px;
    }
  }
}

@include respond('medium') {
  .purchase-methods-page {
    padding-bottom: 80px;

    &__info-card {
      margin-top: 80px;
    }
  }
}

@include respond-down('small') {
  .purchase-methods-page {
    padding-bottom: 40px;

    &__info-card {
      margin-top: 40px;
    }
  }
}