.project-info {
  &__wrap {
    display: flex;
  }

  &__main-wrap {
    width: 100%;
    background-color: var(--grey-color);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__props-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--grey-line-color);

    &:last-child {
      border-bottom: none;
    }
  }

  &__button,
  &__doc-button {
    width: 100%;
  }

  &__preview-wrap {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 1;
      background: var(--blue-color-gradient);
      opacity: 0.2;
    }
  }

  &__video-link-icon {
    position: absolute;
    z-index: 10;
    top: 50%;
    left: 50%;
    transform: translate3d(calc(-50% + 10px), -50%, 0);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__preview-wrap {
    position: relative;
  }

  &__picture {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__video-link-wrap {
    position: relative;
    width: 100%;
  }

  &__video-link {
    display: flex;
    width: 100%;
    height: 100%;

    &::before {
      content: '';
      position: absolute;
      z-index: 5;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: var(--gradient-image-color-20);
      transition: var(--default-transition);
    }
  }
}

@include respond-up('s-large') {
  .project-info {
    &__wrap {
      justify-content: space-between;
      margin-top: 70px;
    }

    &__main-block {
      width: var(--grid-column4);
    }

    &__info-block {
      width: var(--grid-column6)
    }

    &__description {
      padding-bottom: 70px;
    }

    &__preview-wrap {
      height: calc(var(--grid-column6) * (411 / 690));
    }

    &__button {
      margin-top: 40px;
    }

    &__video-link-wrap {
      height: calc(var(--grid-column6) * (411 / 690));
    }

    &__video-link {
      &:hover {
        &::before {
          background-color: var(--gradient-image-color-30);
        }
      }
    }
  }
}

@include respond-up('medium') {
  .project-info {
    &__main-wrap {
      height: 150px;
      margin-bottom: 25px;
    }

    &__icon {
      width: 176px;
      height: 80px;
    }

    &__props-wrap {
      margin: -7px 0 -10px;
    }

    &__props-item {
      padding: 7px 0 10px;
    }
  }
}

@include respond('medium') {
  .project-info {
    &__wrap {
      grid-row-gap: 50px;
      margin-top: 50px;
    }

    &__description {
      padding-bottom: 50px;
    }

    &__preview-wrap {
      height: calc(var(--grid-column8) * (449 / 754));
    }

    &__button {
      margin-top: 30px;
    }

    &__video-link-wrap {
      height: calc(var(--grid-column8) * (449 / 754));
    }
  }
}

@include respond-down('medium') {
  .project-info {
    &__wrap {
      flex-direction: column;
    }
  }
}

@include respond-down('small') {
  .project-info {
    &__wrap {
      grid-row-gap: 19px;
      margin-top: 30px;
    }

    &__main-wrap {
      height: 90px;
      margin-bottom: 20px;
    }

    &__icon {
      width: 128px;
      height: 58px;
    }

    &__props-wrap {
      margin: -6px 0 -8px;
    }

    &__props-item {
      padding: 6px 0 8px 0;
    }

    &__description {
      padding-bottom: 30px;
    }

    &__video-link-icon {
      svg {
        width: 73px;
        height: 96px;
      }
    }

    &__preview-wrap {
      height: calc(var(--grid-column4) * (199 / 334));
    }

    &__button {
      margin-top: 20px;
    }

    &__video-link-wrap {
      height: calc(var(--grid-column4) * (199 / 334));
    }
  }
}