.texts {
  font-style: normal;
  font-weight: 500;

  &_paragraph-1 {
    line-height: 110%;
  }

  &_paragraph-2 {
    line-height: 120%;
  }

  &_paragraph-3 {
    line-height: 130%;
  }

  &_paragraph-4,
  &_paragraph-5,
  &_paragraph-6,
  &_paragraph-7 {
    line-height: 140%;
  }
}

@include respond-up('s-large') {
  .texts {
    &_paragraph-1 {
      font-size: 35px;
      letter-spacing: -0.7px;
    }

    &_paragraph-2 {
      font-size: 28px;
      letter-spacing: -0.56px;
    }

    &_paragraph-3 {
      font-size: 22px;
      letter-spacing: -0.44px;
    }

    &_paragraph-4 {
      font-size: 18px;
      letter-spacing: -0.36px;
    }
  }
}

@include respond-up('medium') {
  .texts {
    &_paragraph-5 {
      font-size: 16px;
      letter-spacing: -0.32px;
    }

    &_paragraph-6 {
      font-size: 14px;
      letter-spacing: -0.28px;
    }

    &_paragraph-7 {
      font-size: 12px;
      letter-spacing: -0.22px;
    }
  }
}

@include respond('medium') {
  .texts {
    &_paragraph-1 {
      font-size: 30px;
      letter-spacing: -0.6px;
    }

    &_paragraph-2 {
      font-size: 24px;
      letter-spacing: -0.48px;
    }

    &_paragraph-3 {
      font-size: 20px;
      letter-spacing: -0.4px;
    }

    &_paragraph-4 {
      font-size: 17px;
      letter-spacing: -0.34px;
    }
  }
}

@include respond-down('small') {
  .texts {
    &_paragraph-1 {
      line-height: 115%;
      font-size: 20px;
      letter-spacing: -0.4px;
    }

    &_paragraph-2 {
      font-size: 18px;
      letter-spacing: -0.36px;
    }

    &_paragraph-3 {
      line-height: 140%;
      font-size: 16px;
      letter-spacing: -0.32px;
    }

    &_paragraph-4 {
      font-size: 15px;
      letter-spacing: -0.3px;
    }

    &_paragraph-5 {
      font-size: 14px;
      letter-spacing: -0.28px;
    }

    &_paragraph-6 {
      font-size: 12px;
      letter-spacing: -0.24px;
    }

    &_paragraph-7 {
      font-size: 11px;
      letter-spacing: -0.22px;
    }
  }
}