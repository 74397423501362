.documents-page {
  &__link {
    display: flex;
    border-bottom: 1px solid var(--grey-line-color);
  }

  &__link-text {
    flex: 0 1 100%;
  }

  &__download-icon {
    display: flex;
    height: max-content;
  }

  &__pdf-icon {
    display: flex;
    height: max-content;
    flex: 0 0 auto;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__size {
    color: var(--black-color-40);
  }

  &__link-text-bottom {
    display: flex;
  }
}

@include respond-up('s-large') {
  .documents-page {
    padding-bottom: 120px;

    &__common {
      margin-bottom: 120px;
    }

    &__accordion {
      margin-top: 30px;
    }

    &__common {
      position: relative;
      display: grid;
      grid-template-columns: repeat(2, var(--grid-column6));
      grid-column-gap: var(--grid-gap);
    }

    &__doc-link-title,
    &__download-icon svg path {
      transition: var(--default-transition);
    }

    &__link {
      padding: 30px 0;

      &:nth-child(1),
      &:nth-child(2) {
        border-top: 1px solid var(--grey-line-color);
      }

      &:hover {
        .documents-page {
          &__doc-link-title {
            color: var(--blue-color);
          }

          &__download-icon {
            svg path {
              stroke: var(--blue-color);
            }
          }
        }
      }
    }

    &__download-icon {
      align-self: flex-end;
      margin-top: 12px;
    }

    &__link-text-bottom {
      flex-direction: column;
    }
  }
}

@include respond-up('medium') {
  .documents-page {
    &__link-text {
      margin-left: 40px;
    }

    &__pdf-icon {
      width: 57px;
      height: 68px;
    }

    &__link-text-bottom {
      margin-top: 10px;
    }
  }
}

@include respond('medium') {
  .documents-page {
    padding-bottom: 80px;

    &__common {
      margin-bottom: 80px;
    }

    &__accordion {
      margin-top: 20px;
    }

    &__link {
      padding: 20px 0;
    }

    &__link-text-bottom {
      align-items: center;
    }
  }
}

@include respond-down('medium') {
  .documents-page {
    &__link {
      &:nth-child(1) {
        border-top: 1px solid var(--grey-line-color);
      }
    }

    &__link-text-bottom {
      justify-content: space-between;
    }
  }
}

@include respond-down('small') {
  .documents-page {
    padding-bottom: 40px;

    &__common {
      margin-bottom: 40px;
    }

    &__accordion {
      margin-top: 10px;
    }

    &__link {
      padding: 10px 0;
    }

    &__link-text {
      margin-left: 20px;
    }

    &__pdf-icon {
      width: 43px;
      height: 51px;
    }

    &__link-text-bottom {
      margin-top: 3px;
    }

    &__download-icon {
      margin-top: 4px;
    }
  }
}