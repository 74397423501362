.footer {
  background-color: var(--grey-color);

  &__wrap {
    &.grid-block {
      grid-row-gap: 0;
    }
  }

  &__grey-text {
    color: var(--black-color-40);
  }

  &__rclass-link {
    color: #000;
  }

  &__logo {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__company-info-wrap,
  &__projects-links {
    display: flex;
    flex-direction: column;
  }

  &__link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--grey-icon-color);
  }

  &__nav-link {
    display: flex;
  }

  &__phone,
  &__email {
    padding: 10px;
    margin: -10px;
  }

  &__link-arrow {
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__nav-list {
    display: grid;
    grid-template-rows: repeat(5, 1fr);
    grid-auto-flow: column;
  }
}

@include respond-up('s-large') {
  .footer {
    padding: 80px 0;

    &__company-info-wrap,
    &__projects-links,
    &__nav-wrap {
      grid-row: 1;
    }

    &__company-info-wrap {
      grid-column: 1/4;
    }

    &__projects-links {
      grid-column: 4/7;
    }

    &__nav-wrap {
      grid-column: 8/13;
    }

    &__line {
      grid-column: 1/13;
      grid-row: 2;
      border-bottom: 1px solid var(--grey-line-color);
      margin-top: 60px;
    }

    &__socials-wrap,
    &__text {
      grid-row: 3;
      margin-top: 40px;
    }

    &__socials-wrap {
      grid-column: 1/3;
    }

    &__text {
      grid-column: 4/12;
    }

    &__copy,
    &__policy,
    &__rclass {
      grid-row: 4;
    }

    &__copy {
      grid-column: 1/3;
    }

    &__policy {
      grid-column: 4/7;
    }

    &__rclass {
      grid-column: 10/13;
    }

    &__link,
    &__blue-text,
    &__rclass-link {
      transition: color .3s var(--default-transition-function);
    }

    &__blue-text {
      &:hover {
        color: var(--blue-color);
      }
    }

    &__rclass {
      &:hover {
        .footer {
          &__rclass-link {
            color: var(--blue-color);
          }
        }
      }
    }

    &__link-arrow {
      svg path {
        transition: stroke .3s var(--default-transition-function);
      }
    }

    &__link {
      transition: border-bottom-color .3s var(--default-transition-function);

      &:hover {
        color: var(--blue-color);
        border-bottom-color: #8C95AF;

        .footer {
          &__link-arrow {
            svg path {
              stroke: var(--blue-color);
            }
          }
        }
      }
    }

    &__nav-list {
      grid-template-columns: repeat(2, calc(var(--grid-column2) + var(--grid-gap)));
    }
  }
}

@include respond-up('medium') {
  .footer {
    &__logo {
      width: 120px;
      height: 38px;
    }

    &__company-contact-wrap {
      &_phone {
        margin-top: 40px;
      }

      &_email {
        margin-top: 30px;
      }

      &_address {
        margin-top: 20px;
      }
    }

    &__link {
      padding-bottom: 16px;

      &_layout {
        padding-top: 30px;
      }
    }

    &__nav-link {
      padding: 12px 0;
    }

    &__policy,
    &__rclass {
      padding: 10px;
      margin: 35px -10px -10px;
    }

    &__copy {
      margin-top: 45px;
    }

    &__nav-list {
      margin-top: -12px;
    }
  }
}

@include respond('medium') {
  .footer {
    padding: 60px 0;

    &__company-info-wrap {
      grid-column: 1/5;
      grid-row: 1/3;
    }

    &__projects-links {
      grid-column: 5/9;
      grid-row: 1;
    }

    &__socials-wrap {
      grid-column: 5/7;
      grid-row: 2;
      display: flex;
      align-items: flex-end;
    }

    &__nav-wrap {
      grid-column: 1/9;
      grid-row: 3;
      padding-top: 60px;
      margin-top: 60px;
      border-top: 1px solid var(--grey-line-color);
    }

    &__text {
      grid-column: 1/9;
      grid-row: 4;
      padding-top: 40px;
      margin-top: 60px;
      border-top: 1px solid var(--grey-line-color);
    }

    &__copy,
    &__policy,
    &__rclass {
      grid-row: 5;
    }

    &__copy {
      grid-column: 1/4;
    }

    &__policy {
      grid-column: 3/7;
      display: flex;
      justify-content: center;
    }

    &__rclass {
      grid-column: 7/9;
      justify-content: flex-end;
      display: flex;
    }

    &__nav-list {
      margin-bottom: -12px;
    }
  }
}

@include respond-down('medium') {
  .footer {
    &__line {
      display: none;
    }

    &__nav-list {
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: var(--grid-gap);
    }
  }
}

@include respond-down('small') {
  .footer {
    padding: 30px 0;

    &__logo {
      width: 89px;
      height: 29px;
    }

    &__company-contact-wrap {
      &_phone {
        margin-top: 20px;
      }

      &_email {
        margin-top: 20px;
      }

      &_address {
        margin-top: 10px;
      }
    }

    &__link {
      padding-bottom: 11px;

      &_layout {
        padding-top: 18px;
      }
    }

    &__nav-link {
      padding: 10px 0;
    }

    &__company-info-wrap {
      grid-column: 1/4;
      grid-row: 1;
    }

    &__socials-wrap {
      grid-column: 4/5;
      grid-row: 1;
    }

    &__socials {
      flex-direction: column;
      align-items: flex-end;

      .socials__icons_yt {
        transform: translate3d(0px, -1px, 0);
      }
    }

    &__projects-links {
      grid-column: 1/5;
      grid-row: 2;
      margin-top: 40px;
    }

    &__nav-wrap {
      grid-column: 1/5;
      grid-row: 3;
      margin-top: 40px;
    }

    &__text {
      grid-column: 1/5;
      grid-row: 4;
      padding-top: 20px;
      margin-top: 40px;
      border-top: 1px solid var(--grey-line-color);
    }

    &__copy,
    &__policy,
    &__rclass {
      grid-column: 1/5;
    }

    &__policy {
      padding: 10px 0 5px;
    }

    &__rclass {
      padding: 5px 0 10px;
    }

    &__copy {
      margin-top: 30px;
    }

    &__nav-list {
      margin: -10px 0;
    }
  }
}