button,
.button {
  text-align: center;
  transition: var(--default-transition);
  cursor: pointer;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  border: none;
  width: max-content;
  height: max-content;

  &__icon {
    display: flex;
    height: max-content;
  }

  &__title {

  }

  &_big {
    padding: 30px 30px 28px;
    border: 1px solid var(--t);
  }

  &_small {
    padding: 19px 30px;
    border: 1px solid var(--t);
  }

  &_smallest {
    padding: 13px 20px;
    border: 1px solid var(--t);
  }

  &_blue {
    background-color: var(--blue-color);
    color: white;
    border-color: var(--blue-color);
  }

  &_white {
    background-color: white;
    color: black;
    border-color: white;
  }

  &_grey {
    background-color: var(--grey-color);
    color: var(--blue-color);
    border-color: var(--grey-color);
  }

  &_transparent-blue {
    background-color: transparent;
    color: var(--blue-color);
    border-color: var(--blue-color-40);
  }

  &_transparent-white {
    background-color: transparent;
    color: white;
    border-color: var(--white-color-40);
  }

  &_iconed {
    display: flex;
    align-items: center;
    color: var(--blue-color);
    width: max-content;
    padding-left: 0;
    padding-right: 0;

    .button__title {
      margin-left: 14px;
      padding-top: 1px;
    }
  }

  &_round {
    width: 46px;
    height: 46px;
    border-radius: 100%;

    &.button_blue {
      .button__icon {
        &::before,
        &::after {
          background-color: white;
        }
      }
    }
  }

  &_plus,
  &_minus {
    .button__icon {
      position: relative;
      width: 100%;
      height: 100%;
    }
  }

  &_plus {
    .button__icon {
      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 18px;
        height: 2px;
        background-color: var(--blue-color);
        transition: var(--default-transition);
      }

      &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(90deg);
        width: 18px;
        height: 2px;
        background-color: var(--blue-color);
        transition: var(--default-transition);
      }
    }
  }

  &_minus {
    .button__icon {
      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 18px;
        height: 2px;
        background-color: var(--blue-color);
      }
    }
  }
}

@include respond-up('s-large') {
  button,
  .button {
    &_big {
      transition: var(--default-transition);
    }

    &_blue {
      &:hover {
        background-color: var(--blue-hover-color);
        border-color: var(--blue-hover-color);
      }
    }

    &_white {
      &:hover {
        color: var(--blue-color);
        background-color: var(--grey-color);
        border-color: var(--grey-color);
      }
    }

    &_grey {
      &:hover {
        color: black;
        background-color: var(--grey-hover-color);
        border-color: var(--grey-hover-color);
      }
    }

    &_transparent-blue {
      &:hover {
        border-color: var(--blue-color);
      }
    }

    &_transparent-white {
      &:hover {
        border-color: white;
      }
    }

    &_iconed {
      &:hover {
        color: black;
      }
    }
  }
}

@include respond('small') {
  button,
  .button {
    &_big {
      padding: 20px 21px;
    }

    &_small {
      padding: 11px 20px;
    }

    &_smallest {
      padding: 9px 14px;
    }

    &_iconed {
      .button__title {
        margin-left: 10px;
      }
    }

    &_round {
      width: 32px;
      height: 32px;

      .button__icon {
        &::before,
        &::after {
          width: 12px;
        }
      }
    }
  }
}