.recall-modal {

}

@include respond-up('s-large') {
  .recall-modal {
    max-width: 360px;
  }
}

@include respond('medium') {
  .recall-modal {
    max-width: var(--grid-column6);
  }
}

@include respond-down('small') {
  .recall-modal {
    max-width: var(--grid-column4);
  }
}