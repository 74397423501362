.project-map {
  position: relative;

  &__main-icon {
    position: relative;
    width: 61px;
    height: 61px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    border: 5px solid white;
    overflow: hidden;
  }

  &__main-icon-img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__object-icon {
    width: 46px;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__object-icon-img {
    width: 20px;
    height: 20px;
  }

  &__cluster-icon,
  &__project-hint-title {
    @extend .texts;
    @extend .texts_paragraph-5;
  }

  &__cluster-icon {
    font-family: var(--font);
    color: #fff;
    background-color: var(--blue-color);
    border-radius: 100%;
    width: 59px;
    height: 59px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__hint-container {
    display: none;
  }

  &__project-hint {
    background-color: #fff;
    width: max-content;
    padding: 7px 11px 11px;
  }

  &__project-hint-title {
    display: flex;
    width: max-content;
  }

  &__filter {
    position: absolute;
    width: var(--grid-column4);
    background-color: white;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.08);
  }

  &__filter-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__filter-item-all {
    display: flex;
    align-items: center;
    width: 100%;

    &._not-checked {
      .project-map {
        &__filter-item-title {
          color: var(--black-color-40);
        }

        &__filter-item-icon {
          svg path {
            fill: var(--grey-icon-color);
          }
        }
      }
    }
  }

  &__filter-item {
    display: flex;

    label {
      display: flex;
      align-items: center;
      cursor: pointer;
      color: var(--black-color-40);
      transition: var(--default-transition);
      width: 100%;

      svg path {
        transition: var(--default-transition);
      }
    }

    input[type=checkbox] {
      display: none;

      &:checked {
        + label {
          color: black;

          svg {
            path {
              &[stroke] {
                stroke: var(--blue-color);
              }

              &[fill] {
                fill: var(--blue-color);
              }
            }

            circle {
              &[stroke] {
                stroke: var(--blue-color);
              }

              &[fill] {
                fill: var(--blue-color);
              }
            }
          }
        }
      }
    }
  }

  &__filter-item-icon {
    display: flex;
    height: max-content;

    svg path {
      transition: var(--default-transition);
    }
  }

  &__filter-item-title {
    transition: var(--default-transition);
  }

  &__filter-button-icon {
    display: none;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    background-color: var(--grey-color);

    svg path {
      fill: var(--blue-color);
    }
  }

  &__filter-button-icon-opener {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__filter-button-icon-closer {
    display: none;
    align-items: center;
    justify-content: center;
  }

  &__filter-inner {
    &._opened {
      .project-map {
        &__filter-button-icon-opener {
          display: none;
        }

        &__filter-button-icon-closer {
          display: flex;
        }
      }
    }
  }

  &__filter-item-total {
    margin-left: auto;
  }
}

@include respond-up('s-large') {
  .project-map {
    //margin-top: 138px;

    &__canvas {
      height: 749px;
    }

    &__filter {
      top: 60px;
      left: var(--grid-spacer-and-indent);
    }

    &__filter-button {
      pointer-events: none;
      padding: 30px 40px;
    }

    &__filter-list {
      padding: 3px 40px 20px;
    }

    &__filter-item {
      &:not(:last-child) {
        border-bottom: 1px solid var(--grey-line-color);
      }

      &:hover {
        input[type=checkbox]:checked + label,
        label {
          color: var(--blue-color);
        }

        .project-map__filter-item-title {
          color: var(--blue-color);
        }
      }
    }
  }
}

@include respond-up('medium') {
  .project-map {
    &__filter-item {
      .project-map__filter-item-all,
      label {
        padding: 7px 0 10px;
      }
    }

    &__filter-item-title {
      margin-left: 20px;
    }
  }
}

@include respond('medium') {
  .project-map {
    margin-top: 90px;

    &__canvas {
      height: 550px;
    }

    &__filter {
      top: 20px;
      width: var(--grid-column8);
    }

    &__filter-button {
      padding: 11px 11px 11px 30px;
    }

    &__filter-button-icon {
      width: 46px;
      height: 46px;
    }

    &__filter-list {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(3, 1fr);
      grid-column-gap: var(--grid-gap);
      grid-auto-flow: column;
      padding: 13px 30px 20px;
    }

    &__filter-item {
      &:not(:nth-child(3n)) {
        border-bottom: 1px solid var(--grey-line-color);
      }
    }
  }
}

@include respond-down('medium') {
  .project-map {
    &__filter {
      left: var(--grid-spacer);
    }

    &__filter-block {
      transition: var(--default-transition);
      height: 0;
      overflow: hidden;
    }

    &__filter-button-icon {
      display: flex;
    }
  }
}

@include respond-down('small') {
  .project-map {
    margin-top: 40px;

    &__canvas {
      height: 438px;
    }

    &__main-icon {
      width: 42px;
      height: 42px;
    }

    &__object-icon {
      width: 36px;
      height: 36px;
    }

    &__cluster-icon {
      width: 44px;
      height: 44px;
    }

    &__project-hint {
      display: none;
    }

    &__filter {
      top: 10px;
      width: var(--grid-column4);
    }

    &__filter-button {
      padding: 7px 7px 7px 20px;
    }

    &__filter-list {
      padding: 7px 20px 12px;
    }

    &__filter-item {
      &:not(:last-child) {
        border-bottom: 1px solid var(--grey-line-color);
      }

      .project-map__filter-item-all,
      label {
        padding: 6px 0 8px;
      }
    }

    &__filter-button-icon {
      width: 36px;
      height: 36px;
    }

    &__filter-item-title {
      margin-left: 10px;
    }
  }
}