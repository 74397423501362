.renovation-page {
  &__included-slider {
    margin-top: calc(2 * var(--grid-gap));
  }
}

@include respond-up('s-large') {
  .renovation-page {
    padding-bottom: 140px;

    &__tabs {
      margin-bottom: 60px;
    }

    &__advantages {
      margin-top: 100px;
    }

    &__images-slider,
    &__info {
      margin-top: 120px;
    }
  }
}

@include respond('medium') {
  .renovation-page {
    padding-bottom: 80px;

    &__tabs {
      margin-bottom: 40px;
    }

    &__advantages {
      margin-top: 60px;
    }

    &__images-slider,
    &__info {
      margin-top: 80px;
    }
  }
}

@include respond-down('small') {
  .renovation-page {
    padding-bottom: 40px;

    &__tabs {
      margin-bottom: 20px;
    }

    &__advantages {
      margin-top: 30px;
    }

    &__images-slider,
    &__info {
      margin-top: 40px;
    }
  }
}