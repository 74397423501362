.modal-layout {
  &__submit {
    width: 100%;
  }

  &__policy-wrap {
    display: flex;
    justify-content: center;
  }
}

@include respond-up('s-large') {
  .modal-layout {
    &__subtitle {
      margin-top: 20px;
    }
  }
}

@include respond-up('medium') {
  .modal-layout {
    &__fields {
      margin-top: 40px;
    }

    &__policy-wrap {
      margin-top: 20px;
    }
  }
}

@include respond('medium') {
  .modal-layout {
    &__subtitle {
      margin-top: 15px;
    }
  }
}

@include respond-down('small') {
  .modal-layout {
    &__subtitle {
      margin-top: 8px;
    }

    &__fields {
      margin-top: 30px;
    }

    &__policy-wrap {
      margin-top: 15px;
    }
  }
}