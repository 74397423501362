.history-page {
  &__list-wrap {
    position: relative;
  }

  &__list {
    border-top: 1px solid var(--grey-line-color);
  }

  &__item {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      z-index: 10;
      border: 3px solid white;
      background-color: var(--grey-line-color);
      transition: var(--default-transition);
    }

    &._active {
      &::before {
        background-color: var(--blue-color);
      }

      .history-page {
        &__year {
          color: var(--blue-color);
        }

        &__description {
          color: black;
        }

        &__picture {
          filter: grayscale(0);
        }
      }
    }

    &:last-child {
      padding-bottom: unset;

      .history-page {
        &__year-wrap,
        &__content-wrap {
          padding-bottom: unset;
        }
      }
    }
  }

  &__year {
    color: var(--grey-icon-color);
    transition: var(--default-transition);
  }

  &__description {
    color: var(--black-color-40);
    transition: var(--default-transition);
  }

  &__picture {
    display: flex;
    width: 100%;
    height: 100%;
    filter: grayscale(60%);
    transition: var(--default-transition);
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__progress-bar {
    position: absolute;
    top: 1px;
    z-index: 5;
    width: 3px;
    height: 100%;

    &::before {
      content: '';
      position: absolute;
      z-index: 15;
      top: 0;
      left: 0;
      width: 100%;
      background-color: white;
    }
  }

  &__progress-track {
    position: absolute;
    z-index: 5;
    top: 0;
    left: 1px;
    background-color: var(--grey-line-color);
    width: 1px;
    height: 100%;
  }

  &__progress-fill {
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    background-color: var(--blue-color);
    transition: var(--default-transition);
  }
}

@include respond-up('s-large') {
  .history-page {
    &__list {
      margin-top: 60px;
    }

    &__year-wrap {
      grid-column: 1/7;
      padding: 30px 0 100px;
    }

    &__content-wrap {
      grid-column: 7/13;
      padding: 30px 0 100px;
    }

    &__item {
      display: grid;
      grid-column-gap: var(--grid-gap);
      grid-template-columns: repeat(12, var(--grid-column));

      &:not(:first-child) {
        .history-page {
          &__year-wrap,
          &__content-wrap {
            border-top: 1px solid var(--grey-line-color);
          }
        }
      }

      &::before {
        top: 32px;
        left: calc(50% - 8px);
      }
    }

    &__image-wrap {
      margin-top: 50px;
      width: var(--grid-column5);
      height: calc(var(--grid-column5) * (385 / 565));
    }

    &__progress-bar {
      left: calc(50% - 2px);

      &::before {
        height: 33px;
      }
    }
  }
}

@include respond-up('medium') {
  .history-page {
    &__item {
      &::before {
        width: 9px;
        height: 9px;
      }
    }
  }
}

@include respond('medium') {
  .history-page {
    &__list {
      margin-top: 40px;
      padding: 0 var(--grid-column) 0 40px;
    }

    &__year-wrap,
    &__content-wrap {
      grid-column: 1/8;
    }

    &__content-wrap {
      margin-top: 20px;
    }

    &__item {
      grid-row-gap: 20px;
      padding: 20px 0 60px;

      &::before {
        top: 39px;
      }
    }

    &__image-wrap {
      margin-top: 40px;
      width: calc(var(--grid-column6) - 40px);
      height: calc((var(--grid-column6) - 40px) * (351 / 514));
    }

    &__progress-bar {
      &::before {
        height: 39px;
      }
    }
  }
}

@include respond-down('medium') {
  .history-page {
    &__item {
      display: flex;
      flex-direction: column;

      &::before {
        left: -5px;
      }

      &:not(:first-child) {
        border-top: 1px solid var(--grey-line-color);
      }
    }

    &__progress-bar {
      left: 0;
    }
  }
}

@include respond-down('small') {
  .history-page {
    &__list {
      margin-top: 20px;
      padding-left: 30px;
    }

    &__item {
      padding: 10px 0 30px;

      &::before {
        top: 20px;
        width: 6px;
        height: 6px;
      }
    }

    &__year-wrap,
    &__content-wrap {
      grid-column: 1/5;
    }

    &__content-wrap {
      margin-top: 15px;
    }

    &__image-wrap {
      margin-top: 20px;
      width: calc(var(--grid-column4) - 30px);
      height: calc((var(--grid-column4) - 30px) * (206 / 302));
    }

    &__progress-bar {
      width: 2px;

      &::before {
        height: 20px;
      }
    }

    &__progress-track {
      left: 0;
    }
  }
}