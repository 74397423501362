.upload-form-bottom {
  position: relative;
  display: flex;

  &__multi-uploadable {
    margin: 0 !important;
  }

  &__dashed {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--blue-color-40);
    border: 1px dashed var(--blue-color-40);
    border-radius: 100%;
    width: 45px;
    height: 45px;
    flex: 0 0 auto;
  }

  &__resume-link-field {
    position: relative;
    margin-left: 20px;
    width: 100%;
    flex: 0 1 100%;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate3d(0, -50%, 0);
      width: 18px;
      height: 12px;
      background-image: url('../images/svg/link-blue.svg');
      background-repeat: no-repeat;
      background-position: center;
    }

    input {
      @extend .texts;
      @extend .texts_paragraph-5;
      color: var(--blue-color);
      padding: 11px 0 12px 30px !important;
      height: unset !important;
      border-bottom: 1px solid var(--blue-color-40) !important;

      &::placeholder {
        @extend .texts;
        @extend .texts_paragraph-5;
        color: var(--blue-color);
      }

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-internal-autofill-selected {
        color: var(--blue-color) !important;
        caret-color: var(--blue-color) !important;
        -webkit-text-fill-color: var(--blue-color) !important;
      }
    }
  }

  &__resume-field-wrap {
    display: flex;
    align-items: center;
  }

  &._white {
    .upload-form-bottom {
      &__dashed {
        color: var(--white-color-40);
        border-color: var(--white-color-40);
      }

      &__resume-link-field {
        &::before {
          background-image: url('../images/svg/link-white.svg');
        }

        input {
          background: var(--t);
          color: white;
          border-bottom-color: 1px solid var(--white-color-40) !important;

          &:-webkit-autofill,
          &:-webkit-autofill:hover,
          &:-webkit-autofill:focus,
          &:-internal-autofill-selected {
            color: white !important;
            caret-color: white !important;
            -webkit-text-fill-color: white !important;
          }

          &::placeholder {
            color: white;
          }
        }
      }
    }
  }
}

@include respond-up('medium') {
  .upload-form-bottom {
    align-items: center;

    &__multi-uploadable {
      flex: 0 1 100%;
    }

    &__resume-field-wrap {
      flex: 0 0 auto;
      position: absolute;
      z-index: 50;
      top: 0;
      right: 0;
    }

    &__dashed {
      padding-bottom: 1px;
    }
  }
}

@include respond-down('small') {
  .upload-form-bottom {
    flex-direction: column;

    &__dashed {
      width: 39px;
      height: 39px;
      margin-top: 8px;
    }

    &__resume-link-field {
      margin-left: 18px;
    }
  }
}