.news-page-card {
  border-bottom: 1px solid var(--grey-line-color);

  &:first-child {
    border-top: 1px solid var(--grey-line-color);
  }

  &__link {
    display: grid;
    grid-column-gap: var(--grid-gap);
  }

  &__image-wrap {
    grid-area: image;
  }

  &__title {
    grid-area: title;
    transition: color .3s var(--default-transition-function);
  }

  &__date {
    grid-area: date;
    color: var(--black-color-40);
  }

  &__arrow-wrap {
    display: flex;
    align-items: flex-end;
    grid-area: arrow;
  }

  &__arrow {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 100%;
      height: 100%;
      path {
        fill: var(--grey-icon-color);
        transition: fill .3s var(--default-transition-function);
      }
    }
  }

  &__picture {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@include respond-up('s-large') {
  .news-page-card {
    &:hover {
      .news-page-card {
        &__title {
          color: var(--blue-color);
        }

        &__arrow {
          svg path {
            fill: var(--blue-color);
          }
        }
      }
    }

    &__link {
      padding: 30px 0;
      grid-template-columns: var(--grid-column3) var(--grid-column3) var(--grid-column3);
    }

    &__picture {
      height: calc(var(--grid-column3) * (215 / 315));
    }
  }
}

@include respond-up('medium') {
  .news-page-card {
    &__link {
      grid-template-areas: "image title title"
                           "image date arrow";
    }

    &__date {
      display: flex;
      align-items: flex-end;
    }

    &__date {
      justify-content: flex-start;
    }

    &__arrow-wrap {
      justify-content: flex-end;
    }

    &__arrow {
      width: 34px;
      height: 26px;
      &_mob {
        display: none;
      }
    }
  }
}

@include respond('medium') {
  .news-page-card {
    &__link {
      padding: 20px 0;
      grid-template-columns: var(--grid-column3) var(--grid-column3) var(--grid-column2);
    }

    &__picture {
      height: calc(var(--grid-column3) * (174 / 256));
    }
  }
}

@include respond-down('medium') {
  .news-page-card {

  }
}

@include respond-down('small') {
  .news-page-card {
    &__link {
      padding: 10px 0 20px;
      grid-template-areas: "image image date date"
                           "title title title arrow";
      grid-template-columns: repeat(4, var(--grid-column));
      grid-row-gap: 20px;
    }

    &__date {
      display: flex;
      justify-content: flex-end;
    }

    &__arrow-wrap {
      justify-content: flex-end;
    }

    &__arrow {
      width: 26px;
      height: 16px;
      &_desk {
        display: none;
      }
    }

    &__picture {
      height: calc(var(--grid-column2) * (108 / 158));
    }
  }
}