.team-page {
  &__picture {
    display: flex;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__employee-job {
    color: var(--black-color-40);
  }

  &__department {
    display: grid;
    grid-column-gap: var(--grid-gap);
  }

  &__employees {
    display: grid;
    grid-column-gap: var(--grid-gap);
  }

  &__employee-name {
  }
}

@include respond-up('s-large') {
  .team-page {
    margin-top: 60px;

    &__picture {
      width: var(--grid-column3);
      height: calc(319 / 315 * var(--grid-column3));
    }

    &__employee {
      position: relative;

      &:not(:nth-child(3n)) {
        &:before {
          position: absolute;
          content: '';
          height: 100%;
          width: 1px;
          background: var(--grey-line-color);
          right: calc(-1 * var(--grid-gap) / 2);
          top: 0;
        }
      }
    }

    &__employees {
      grid-row-gap: 50px;
    }

    &__department {
      padding-top: 30px;
      border-top: 1px solid var(--grey-line-color);
      grid-template-columns: var(--grid-column3) var(--grid-column9);

      &:not(:first-child) {
        margin-top: 70px;
      }
    }

    &__employees {
      grid-template-columns: repeat(3, var(--grid-column3));
    }
  }
}

@include respond-up('medium') {
  .team-page {
    &__employee-name {
      margin-top: 10px;
    }
  }
}

@include respond('medium') {
  .team-page {
    margin-top: 40px;

    &__picture {
      width: var(--grid-column4);
      height: calc(361 / 357 * var(--grid-column4));
    }

    &__department {
      &:not(:first-child) {
        margin-top: 50px;
      }
    }

    &__employees {
      grid-template-columns: repeat(2, var(--grid-column4));
      grid-row-gap: 30px;
    }
  }
}

@include respond-down('medium') {
  .team-page {
    &__employees {
      padding-top: calc(var(--grid-gap) / 2);
      margin-top: calc(var(--grid-gap) / 2);
      border-top: 1px solid var(--grey-line-color);
    }

    &__employee {
      position: relative;

      &:not(:nth-child(2n)) {
        &:before {
          position: absolute;
          content: '';
          height: 100%;
          width: 1px;
          background: var(--grey-line-color);
          right: calc(-1 * var(--grid-gap) / 2);
          top: 0;
        }
      }
    }
  }
}

@include respond-down('small') {
  .team-page {
    margin-top: 20px;

    &__picture {
      width: var(--grid-column2);
      height: calc(159 / 157 * var(--grid-column2));
    }

    &__employee-name {
      margin-top: 6px;
    }

    &__department {
      &:not(:first-child) {
        margin-top: 30px;
      }
    }

    &__employees {
      grid-template-columns: repeat(2, var(--grid-column2));
      grid-row-gap: 20px;
    }
  }
}