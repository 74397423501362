.slider-pagination {
  &.swiper-pagination {
    display: flex;
  }

  .swiper-pagination-bullet {
    --swiper-pagination-bullet-width: 132px;
    --swiper-pagination-bullet-height: 2px;
    --swiper-pagination-bullet-horizontal-gap: 6px;
    --swiper-pagination-bullet-inactive-color: var(--white-color-40);
    --swiper-pagination-bullet-inactive-opacity: 1;
    border-radius: unset;
    display: block;
  }

  .swiper-pagination-bullet-active {
    position: relative;
    --swiper-pagination-color: var(--white-color-40);

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: 100%;
      background-color: white;
      animation: pagination-fill 5s linear forwards;
    }
  }
}

@include respond-down('small') {
  .slider-pagination {
    .swiper-pagination-bullet {
      --swiper-pagination-bullet-width: 79px;
      --swiper-pagination-bullet-horizontal-gap: 3px;
    }
  }
}

@keyframes pagination-fill {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}