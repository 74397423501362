:root {
  --default-input-height: 77px;
  --errors-color: var(--alert-color, #b50000)
}

$inputs: 'input[type="text"], input[type="search"], input[type="password"], input[type="number"]';

form {
  #{$inputs}, textarea, select {
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-internal-autofill-selected {
      color: white;
      caret-color: white;
      -webkit-text-fill-color: white;
      -webkit-box-shadow: 0 0 0px 1000px var(--t) inset;
      transition: background-color 5000s ease-in-out 0s;
    }
  }

  label {
    color: black;
    margin-bottom: 2px;
    display: block;
    font-weight: 400;
    line-height: 110%;
    font-stretch: 110%;
    font-variation-settings: var(--font-settings);
  }

  ul.errors {
    list-style: none;
    color: var(--errors-color);

    li {
      margin-top: 4px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  #{$inputs},
  ul.errors {
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
  }

  .form-field {
    margin-bottom: 10px;
  }

  #{$inputs}, textarea, select {
    border: unset;
    border-radius: 0;
    box-shadow: none;
    margin: 0;
    width: 100%;
    display: block;
    appearance: none;
    -moz-appearance: textfield;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  #{$inputs}, textarea, select {
    height: var(--default-input-height);
    font-family: var(--font);
  }

  select {
    font-family: var(--h-font);
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 14px;
    height: 45px;
    padding: 14px 20px;
    background-color: var(--grey-color);
  }

  textarea {
    resize: none;
  }

  //.button, button {
  //  padding: 0;
  //  margin: 0;
  //  text-align: center;
  //  transition: var(--default-transition);
  //  border: none;
  //  background: black;
  //  font-size: 16px;
  //  cursor: pointer;
  //  text-decoration: none;
  //  border-radius: 100px;
  //  color: #fff;
  //  font-weight: 500;
  //  line-height: 110%;
  //  text-transform: uppercase;
  //  font-stretch: 151%;
  //  font-variation-settings: var(--font-settings);
  //  display: flex;
  //  align-items: center;
  //  justify-content: center;
  //}
}

@include respond-up('s-large') {
  form {

  }
}

@include respond-up('medium') {
  form {
    #{$inputs},
    textarea {
      padding: 36px 20px 19px;
      font-size: 16px;
      letter-spacing: -0.32px;
    }

    ul.errors {
      font-size: 12px;
      letter-spacing: -0.22px;
    }
  }
}

@include respond-down('small') {
  :root {
    --default-input-height: 56px;
  }

  form {
    #{$inputs},
    textarea {
      padding: 25px 16px 11px;
      font-size: 14px;
      letter-spacing: -0.28px;
    }

    .form-field {
      margin-bottom: 6px;
    }

    select {
      font-size: 12px;
      height: 38px;
      padding: 12px 16px;
    }

    ul.errors {
      font-size: 11px;
      letter-spacing: -0.22px;
    }
  }
}