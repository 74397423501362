.purchase-method-tabs {

}

@include respond-down('medium') {
  .purchase-method-tabs {
    &.tabs {
      margin: 0;
    }

    .tabs__list {
      padding: 0;
    }
  }
}

@include respond-down('small') {
  .purchase-method-tabs {

  }
}