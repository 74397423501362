.advantages-slider {
  &__slider-wrap {
    cursor: grab;
  }

  &__slider {
    &.swiper {
      padding-left: var(--grid-spacer-and-indent);
      padding-right: var(--grid-spacer-and-indent);
    }

    &._active {
      .advantages-slider__slide {
        pointer-events: auto;
      }
    }
  }

  &__slide {
    pointer-events: none;

    &::before {
      position: absolute;
      content: '';
      left: 0;
      width: 100%;
      border-bottom: 1px solid var(--grey-line-color);
    }

    &:not(:last-child) {
      &::before {
        width: calc(100% + var(--grid-gap));
      }
    }
  }
}

@include respond-up('s-large') {
  .advantages-slider {
    &__slider {
      &.swiper {
        padding-top: 30px;
      }
    }

    &__slide {
      &::before {
        top: -30px;
      }
    }

    &__title {
      grid-column: 1/7;
    }

    &__description {
      grid-column: 7/13;
    }

    &__arrows-wrap {
      margin-top: 20px;
    }

    &__slider-wrap {
      margin-top: 40px;
    }
  }
}

@include respond('medium') {
  .advantages-slider {
    &__slider {
      &.swiper {
        padding-top: 20px;
      }
    }

    &__slide {
      &::before {
        top: -20px;
      }
    }

    &__heading {
      &.grid-block {
        grid-row-gap: 25px;
      }
    }

    &__title,
    &__description {
      grid-column: 1/9;
    }

    &__arrows-wrap {
      margin-top: 50px;
    }

    &__slider-wrap {
      margin-top: 25px;
    }
  }
}

@include respond-down('small') {
  .advantages-slider {
    &__slider {
      &.swiper {
        padding-top: 10px;
      }
    }

    &__slide {
      &::before {
        top: -10px;
      }
    }

    &__heading {
      &.grid-block {
        grid-row-gap: 15px;
      }
    }

    &__title,
    &__description {
      grid-column: 1/5;
    }

    &__arrows-wrap {
      margin-top: 30px;
    }

    &__slider-wrap {
      margin-top: 14px;
    }
  }
}