.purchase-method-page {

}

@include respond-up('s-large') {
  .purchase-method-page {
    padding-bottom: 120px;

    &__payout-cards,
    &__calculator,
    &__block-heading,
    &__banks,
    &__questions,
    &__info-card,
    &__easy-to-start {
      margin-top: 120px;
    }

    &__advantages {
      margin-top: 100px;
    }
  }
}

@include respond-up('medium') {
  .purchase-method-page {
    &__hero {
      margin-top: 30px;
    }
  }
}

@include respond('medium') {
  .purchase-method-page {
    padding-bottom: 80px;

    &__payout-cards,
    &__block-heading,
    &__banks,
    &__questions,
    &__calculator,
    &__info-card,
    &__easy-to-start {
      margin-top: 80px;
    }

    &__advantages {
      margin-top: 60px;
    }
  }
}

@include respond-down('medium') {
  .purchase-method-page {

  }
}

@include respond-down('small') {
  .purchase-method-page {
    padding-bottom: 40px;

    &__hero {
      margin-top: 15px;
    }

    &__payout-cards,
    &__calculator,
    &__block-heading,
    &__banks,
    &__questions,
    &__info-card,
    &__easy-to-start {
      margin-top: 40px;
    }

    &__advantages {
      margin-top: 30px;
    }
  }
}