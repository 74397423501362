.project-layouts {
  .dp-w-flex {
    font-family: var(--font);
    margin: 0;
    flex-wrap: nowrap;
    display: grid;
    grid-gap: var(--grid-gap);
    grid-template-columns: repeat(4, 1fr);
    justify-content: unset;
  }

  .dp-w-griditem {
    width: 100%;
  }

  .dp-w-griditem-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    margin: 0;
    grid-column: span 1;
    background-color: white;

    &:hover {
      transform: translate3d(0, 0, 0);
      box-shadow: unset;
      border-color: var(--grey-line-hover-color);
    }
  }

  .dp-w-griditem-info {
    padding: 20px 30px 30px;
    width: 100%;
  }

  .dp-w-griditem-title,
  .dp-w-griditem-info-flex {
    @extend .texts;
  }

  .dp-w-griditem-title {
    @extend .texts_paragraph-5;
    max-width: 220px;
    transition: var(--default-transition);
  }

  .dp-w-griditem-info-flex {
    @extend .texts_paragraph-5;
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
  }

  .dp-w-griditem-area {
    color: var(--black-color-40);
    white-space: nowrap;
  }

  .dp-w-griditem-price {
    white-space: nowrap;
  }

  .dp-w-griditem-image {
    padding: 0;
  }
}

@include respond-up('s-large') {
  .project-layouts {
    &__title {
      margin-bottom: 40px;
    }

    .dp-w-griditem-image {
      width: calc(var(--grid-column3) - 20px);
      height: calc(var(--grid-column3) - 20px);
    }

    .dp-w-griditem-inner {
      transition: var(--default-transition);

      &:hover {
        cursor: pointer;
        transform: translate3d(0, 0, 0);
        box-shadow: unset;
        border-color: var(--grey-line-hover-color);

        .dp-w-griditem-title {
          color: var(--blue-color);
        }
      }
    }
  }
}

@include respond('s-large') {
  .project-layouts {
    .dp-w-griditem-info-flex {
      flex-direction: column;
    }
  }
}

@include respond-up('medium') {
  .project-layouts {

  }
}

@include respond('medium') {
  .project-layouts {
    &__title {
      margin-bottom: 20px;
    }

    .dp-w-flex {
      grid-template-columns: repeat(2, 1fr);
    }

    .dp-w-griditem-image {
      width: calc(var(--grid-column4) - 20px);
      height: calc(var(--grid-column4) - 20px);
    }
  }
}

@include respond-down('medium') {
  .project-layouts {

  }
}

@include respond-down('small') {
  .project-layouts {
    &__title {
      margin-bottom: 10px;
    }

    .dp-w-flex {
      grid-template-columns: repeat(2, 1fr);
    }

    .dp-w-griditem-image {
      width: calc(var(--grid-column2) - 20px);
      height: calc(var(--grid-column2) - 20px);
    }

    .dp-w-griditem-info {
      padding: 10px 15px 20px;
    }
  }
}