.slider-card {
  &__picture {
    display: flex;
    width: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__item-info {
    display: flex;
    margin-top: 10px;
  }

  &__item-info-inner {
    display: block;
    flex: 0 1 100%;
  }

  &__item-total {
    display: block;
    color: var(--black-color-40);
    margin-top: 4px;
  }

  &__item-icon {
    display: flex;
    height: max-content;
  }

  &__item-title {
    display: block;
  }
}

@include respond-up('s-large') {
  .slider-card {
    &:hover {
      .slider-card {
        &__item-title {
          color: var(--blue-color);
        }

        &__item-icon {
          svg path {
            fill: var(--blue-color);
          }
        }

        &__picture {
          &::before {
            opacity: 1;
          }
        }
      }
    }

    &__item-title,
    &__item-icon svg path {
      transition: var(--default-transition);
    }

    &__picture {
      position: relative;
      height: calc(var(--grid-column3) * (215 / 315));

      &::before {
        content: '';
        position: absolute;
        z-index: 15;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: var(--blue-dark-color-10);
        opacity: 0;
        transition: var(--default-transition);
      }
    }
  }
}

@include respond('medium') {
  .slider-card {
    &__picture {
      height: calc(var(--grid-column4) * (243 / 357));
    }
  }
}

@include respond-down('small') {
  .slider-card {
    &__item-info {
      margin-top: 6px;
    }

    &__item-total {
      margin-top: 2px;
    }

    &__picture {
      height: calc(var(--grid-column2) * (107 / 157));
    }
  }
}