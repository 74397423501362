.contacts-block {
  &__grey-text {
    color: var(--black-color-40);
  }

  &__about-address-wrap,
  &__contacts-list,
  &__info-wrap {
    display: flex;
  }

  &__phone,
  &__email {
    display: block;
  }

  &__schedule-wrap {
    display: flex;
    flex-direction: column;
  }

  &__picture {
    width: 100%;
    display: flex;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__schedule-item {

  }
}

@include respond-up('s-large') {
  .contacts-block {
    &_index {
      .contacts-block {
        &__wrap {
          margin-top: 30px;
        }
      }
    }

    &__wrap {
      padding-top: 30px;
      border-top: 1px solid var(--grey-line-color);
    }

    &__about-address-wrap {
      grid-column: 1/7;
    }

    &__picture {
      height: calc(var(--grid-column2) * (178 / 190));
    }

    &__address-wrap {
      margin-left: var(--grid-gap);
      flex: 0 0 var(--grid-column4);
    }

    &__contacts-list {
      grid-column: 7/10;
      flex-direction: column;
    }

    &__contacts-item {
      &:not(:first-child) {
        margin-top: 30px;
      }
    }

    &__info-wrap {
      grid-column: 10/13;
      flex-direction: column;
    }

    &__socials-wrap {
      margin-top: 30px;
    }

    &__contacts-list,
    &__info-wrap {
      position: relative;

      &:before {
        position: absolute;
        content: '';
        height: 100%;
        border-left: 1px solid var(--grey-line-color);
        top: 0;
        left: calc(-1 * (var(--grid-gap) / 2));
      }
    }
  }
}

@include respond-up('medium') {
  .contacts-block {
    &__image-wrap {
      flex: 0 0 var(--grid-column2);
    }

    &__address,
    &__phone {
      margin-top: 4px;
    }

    &__socials {
      margin-top: 10px;
    }
  }
}

@include respond('medium') {
  .contacts-block {
    &_index {
      .contacts-block {
        &__wrap {
          margin-top: 20px;
        }
      }
    }

    &__about-address-wrap,
    &__contacts-list,
    &__info-wrap {
      padding-top: 20px;
    }

    &__contacts-list,
    &__info-wrap {
      margin-top: 20px;
    }

    &__address-wrap {
      flex: 0 0 var(--grid-column6);
    }

    &__picture {
      height: calc(var(--grid-column2) * (148 / 158));
    }

    &__contacts-item {
      flex: 0 0 var(--grid-column4);

      &:nth-child(2) {
        margin-left: var(--grid-gap);
      }
    }

    &__schedule-wrap,
    &__socials-wrap {
      flex: 0 0 var(--grid-column4);
    }

    &__socials-wrap {
      margin-left: var(--grid-gap);
    }
  }
}

@include respond-down('medium') {
  .contacts-block {
    &__wrap {
      &.grid-block {
        display: flex;
        flex-direction: column;
        grid-gap: unset;
      }
    }

    &__about-address-wrap,
    &__contacts-list,
    &__info-wrap {
      border-top: 1px solid var(--grey-line-color);
    }

    &__address-wrap {
      margin-right: var(--grid-gap);
    }

    &__about-address-wrap {
      flex-direction: row-reverse;
    }
  }
}

@include respond-down('small') {
  .contacts-block {
    &_index {
      .contacts-block {
        &__wrap {
          margin-top: 10px;
        }
      }
    }

    &__about-address-wrap,
    &__contacts-list,
    &__info-wrap {
      padding-top: 10px;
    }

    &__contacts-list {
      flex-direction: column;
      margin-top: 10px;
    }

    &__info-wrap {
      margin-top: 14px;
    }

    &__address-wrap {
      flex: 0 0 var(--grid-column3);
    }

    &__image-wrap {
      flex: 0 0 var(--grid-column1);
    }

    &__picture {
      height: var(--grid-column1);
    }

    &__address,
    &__phone {
      margin-top: 2px;
    }

    &__contacts-item {
      &:not(:first-child) {
        margin-top: 15px;
      }
    }

    &__info-wrap {
      flex-direction: column;
    }

    &__socials-wrap {
      margin-top: 15px;
    }

    &__socials {
      margin-top: 6px;
    }

    &__map-link {
      &.button {
        padding-left: 0;
        padding-top: 10px;
      }
    }
  }
}