.cards {
  @extend .grid-block;
}

@include respond-up('s-large') {
  .cards {
    &._big {
      .card {
        &:nth-child(odd) {
          grid-column: 1/7;

          &::before {
            content: '';
            position: absolute;
            top: 0;
            right: calc(var(--grid-gap) / 2 * -1);
            width: 1px;
            height: 100%;
            background-color: var(--grey-line-color);
            pointer-events: none;
          }
        }

        &:nth-child(even) {
          grid-column: 7/13;
        }

        &__picture-wrap {
          height: calc((470 / 690) * var(--grid-column6));
        }
      }
    }

    &._medium {
      .card {
        &:nth-child(3n+1) {
          grid-column: 1/5;
        }

        &:nth-child(2n) {
          grid-column: 5/9;
        }

        &:nth-child(3n) {
          grid-column: 9/13;
        }

        &:not(:nth-child(3n)) {
          &::before {
            content: '';
            position: absolute;
            top: 0;
            right: calc(var(--grid-gap) / 2 * -1);
            width: 1px;
            height: 100%;
            background-color: var(--grey-line-color);
            pointer-events: none;
          }
        }

        &__picture-wrap {
          height: calc((300 / 440) * var(--grid-column4));
        }
      }
    }

    &._small {
      .card {
        &:nth-child(4n+1) {
          grid-column: 1/4;
        }

        &:nth-child(4n+2) {
          grid-column: 4/7;
        }

        &:nth-child(4n+3) {
          grid-column: 7/10;
        }

        &:nth-child(4n) {
          grid-column: 10/13;
        }

        &:not(:nth-child(4n)) {
          &::before {
            content: '';
            position: absolute;
            top: 0;
            right: calc(var(--grid-gap) / 2 * -1);
            width: 1px;
            height: 100%;
            background-color: var(--grey-line-color);
            pointer-events: none;
          }
        }

        &__picture-wrap {
          height: calc((215 / 315) * var(--grid-column3));
        }
      }
    }
  }
}

@include respond('medium') {
  .cards {
    .card {
      &:nth-child(2n+1) {
        grid-column: 1/5;

        &::before {
          content: '';
          position: absolute;
          top: 0;
          right: calc(var(--grid-gap) / 2 * -1);
          width: 1px;
          height: 100%;
          background-color: var(--grey-line-color);
          pointer-events: none;
        }
      }

      &:nth-child(2n) {
        grid-column: 5/9;
      }
    }
  }
}

@include respond('small') {
  .cards {
    .card {
      grid-column: 1/5;
    }
  }
}