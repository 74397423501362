.offices-list {
  &__heading {
    display: flex;
    align-items: center;
    background-color: white;
  }

  &__heading-title {
    flex: 0 1 100%
  }

  &__heading-link {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 auto;
    border-radius: 100%;
    background-color: var(--grey-color);
    transition: var(--default-transition);
    margin-left: 10px;

    svg path {
      transition: var(--default-transition);
      fill: var(--grey-icon-color);
    }

    &._active {
      background-color: var(--blue-color);

      svg path {
        fill: white;
      }
    }
  }

  &__heading-icon {
    display: flex;
    height: max-content;
  }

  &__items {
    display: grid;
    grid-template-columns: 1fr;
  }

  &__item {
    &:not(:last-child) {
      border-bottom: 1px solid var(--grey-line-color);
    }
  }

  &__link {
    display: flex;
  }

  &__link-icon {
    display: flex;
    height: max-content;
    flex: 0 0 auto;

    &_plus {
      margin-left: 20px;
    }
  }

  &__item-main {
    display: block;
    flex: 0 1 100%;
  }

  &__link-title {
    display: block;
  }

  &__link-address {
    display: block;
    color: var(--black-color-40);
  }

  &__info {
    position: absolute;
    z-index: 55;
    top: 0;
    left: 0;
    width: 100%;
    opacity: 0;
    pointer-events: none;
    background-color: white;
    transition: var(--default-transition);
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.08);
  }

  &__info-close {
    display: flex;
    align-items: center;
    height: max-content;
  }

  &__info-close-icon {
    display: flex;
    height: max-content;

    svg {
      transform: rotate(180deg);
    }

    &_mobile {
      display: none;
    }
  }

  &__info-close-title {
    margin-left: 30px;
  }

  &__picture {
    display: flex;
    width: var(--grid-column4);
    height: calc(var(--grid-column4) * (250 / 440));
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__info-item {
    display: flex;
    padding: 5px 0;
  }

  &__info-name {
    color: var(--black-color-40);
  }
}


@include respond-up('large') {
  .offices-list {
    &__info-name {
      flex: 0 0 127px;
    }

    &__info-value {
      flex: 0 1 100%;
      margin-left: 43px;
    }
  }
}

@include respond-up('s-large') {
  .offices-list {
    position: relative;

    &__heading {
      padding: 30px 40px;
    }

    &__heading-link {
      display: none;
    }

    &__items {
      padding: 0 40px 38px;
      margin: -12px 0 -9px;
    }

    &__item {
      padding: 12px 0 9px;

      &._show {
        .offices-list__info {
          opacity: 1;
          pointer-events: auto;
        }
      }
    }

    &__info-close {
      padding: 30px 40px;
    }

    &__info-list {
      margin: 5px 0 -5px;
      padding: 0 40px;
    }

    &__picture {
      margin-top: 40px;
    }

    &__link-title {
      transition: var(--default-transition);
    }

    &__link-icon_plus {
      svg path {
        transition: var(--default-transition);
      }
    }

    &__link {
      &:hover {
        .offices-list {
          &__link-title {
            color: var(--blue-color);
          }

          &__link-icon_plus {
            svg path {
              fill: var(--blue-color);
            }
          }
        }
      }
    }
  }
}

@include respond('s-large') {
  .offices-list {
    &__info-name {
      flex: 0 0 100px;
    }

    &__info-value {
      flex: 0 1 100%;
      margin-left: 10px;
    }
  }
}

@include respond-up('medium') {
  .offices-list {
    &__item-main {
      margin-left: 20px;
    }
  }
}

@include respond('medium') {
  .offices-list {
    &__heading {
      padding: 11px 11px 11px 30px;
    }

    &__heading-link {
      width: 46px;
      height: 46px;
    }

    &__items {
      margin: 28px 0 -10px;
      grid-template-columns: repeat(2, var(--grid-column4));
      grid-column-gap: var(--grid-gap);
    }

    &__item {
      padding: 12px 0 10px;
      border-bottom: 1px solid var(--grey-line-color);

      $first-child-in-any-row: ":nth-child(#{2}n + 1)";
      $any-child-in-the-last-row: ":nth-last-child(-n + #{2})";
      $first-child-in-last-row: "&#{$first-child-in-any-row}#{$any-child-in-the-last-row}";

      #{$first-child-in-last-row},
      #{$first-child-in-last-row} ~ & {
        border-bottom: unset;
      }
    }

    &__info-close {
      padding: 30px 0;
      grid-column: 1/5;
    }

    &__info-list {
      margin: -10px 0;
      grid-column: 1/5;
    }

    &__info-item {
      padding: 10px 0;
    }

    &__info {
      display: grid;
      grid-template-columns: repeat(8, var(--grid-column));
      grid-template-rows: max-content 1fr;
      grid-column-gap: var(--grid-gap);
      padding: 0 var(--grid-spacer);
    }

    &__picture {
      grid-column: 5/9;
      grid-row: 1/3;
      margin-top: 30px;
    }
  }
}

@include respond-down('medium') {
  .offices-list {
    &__heading {
      pointer-events: auto;
    }

    &__info {
      background-color: var(--background-color);
    }

    &__info-item {
      flex-direction: column;
    }

    &__items {
      opacity: 0;
      pointer-events: none;
      transition: var(--default-transition);

      &._active {
        opacity: 1;
        pointer-events: auto;

        .offices-list {
          &__item {
            &._show {
              .offices-list__info {
                opacity: 1;
                pointer-events: auto;
              }
            }
          }
        }
      }
    }
  }
}

@include respond-down('small') {
  .offices-list {
    &__heading {
      padding: 7px 7px 7px 20px;
    }

    &__heading-link {
      width: 36px;
      height: 36px;
    }

    &__items {
      margin: 22px 0 -10px;
    }

    &__item {
      padding: 8px 0 10px;
    }

    &__item-main {
      margin-left: 10px;
    }

    &__info-close-icon {
      &_desktop {
        display: none;
      }

      &_mobile {
        display: flex;
      }
    }

    &__info-close-title {
      margin-left: 20px;
    }

    &__info-close {
      padding: 20px;
    }

    &__info-list {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: var(--grid-gap);
      grid-row-gap: 10px;
      margin: 2px 0 -5px;
      padding: 0 var(--grid-spacer);
    }

    &__picture {
      margin: 30px auto 0;
      width: var(--grid-column4);
      height: calc(var(--grid-column4) * (212 / 374));
    }
  }
}