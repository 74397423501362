.advantage-modal {
  &__picture {
    display: flex;
    width: 100%;
    height: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__title {
    color: #fff;
    position: absolute;
    z-index: 10;
  }

  &__picture-wrap {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      z-index: 5;
      bottom: 0;
      left: 0;
      width: 100%;
      background: linear-gradient(180deg, rgba(7, 20, 46, 0.00) 0%, rgba(7, 20, 46, 0.30) 100%);
    }
  }
}

@include respond-up('s-large') {
  .advantage-modal {
    width: var(--grid-column6);

    &__title {
      left: 40px;
      right: 40px;
    }

    &__picture-wrap {
      height: calc(var(--grid-column6) * (438 / 690));
    }
  }
}

@include respond-up('medium') {
  .advantage-modal {
    &__title {
      bottom: 30px;
    }

    &__description-wrap {
      padding: 40px 40px 50px;
    }

    &__picture-wrap {
      &::before {
        height: 177px;
      }
    }

    &__description {
      &_bottom {
        margin-top: 20px;
      }
    }
  }
}

@include respond('medium') {
  .advantage-modal {
    &__picture-wrap {
      height: calc(100vw * (530 / 834));
    }
  }
}

@include respond-down('medium') {
  .advantage-modal {
    width: 100vw;
    height: 100vh;

    &__title {
      left: var(--grid-spacer);
      right: var(--grid-spacer);
    }
  }
}

@include respond-down('small') {
  .advantage-modal {
    &__title {
      bottom: 20px;
    }

    &__picture-wrap {
      height: calc(100vw * (238 / 374));

      &::before {
        height: calc((105 / 374) * 100vw);
      }
    }

    &__description-wrap {
      padding: 30px 20px;
    }

    &__description {
      &_bottom {
        margin-top: 15px;
      }
    }
  }
}