.card {
  position: relative;

  &__picture-wrap,
  &__content,
  &__title,
  &__secondary-info,
  &__additional-info {
    display: block;
  }

  &__content-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__content-bottom {
    display: flex;
    align-items: center;
  }

  &__icon {
    display: flex;
    height: max-content;

    svg path {
      transition: var(--default-transition);
    }
  }

  &__picture-wrap {
    position: relative;
    background-color: var(--grey-color);
  }

  &__picture {
    display: flex;
    width: 100%;
    height: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__bottom-delimiter {
    width: 3px;
    height: 3px;
    background-color: var(--black-color-40);
    border-radius: 100%;
    margin: 0 10px;
  }

  &__additional-info {
    color: var(--black-color-40);
  }

  &__tags-wrap {
    display: flex;
    flex-wrap: wrap;
    position: absolute;
    z-index: 10;
    top: 10px;
    right: 10px;
    left: 10px;
    margin: -2px;

    &._hidden {
      .card__tags-list_additional {
        opacity: 0;
      }
    }

    &._visible {
      .card__tags-list_additional {
        opacity: 1;
      }
    }
  }

  &__tags-list {
    flex: 0 0 auto;
    margin: 0 !important;
    transition: var(--default-transition);

    &_main {

    }

    &_additional {
      flex: 0 0 100%;
    }
  }

  &__tags-more {
    display: flex;
    flex: 0 1 auto;
    padding: 3px 8px 1px;
    margin: 2px;
    background-color: var(--blue-color);
  }

  &__tags-more-title {
    color: white;
  }

  &__title {
    transition: var(--default-transition);
  }

  &__additional-image {
    position: absolute;
    z-index: 5;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: var(--default-transition);
  }

  &__additional-image-bg {
    display: block;
    width: 100%;
    height: 100%;
    background-color: var(--blue-dark-color-10);
  }
}

@include respond-up('s-large') {
  .card {
    &:hover {
      .card {
        &__title {
          color: var(--blue-color);
        }

        &__icon {
          svg path {
            stroke: var(--blue-color);
          }
        }

        &__additional-image {
          opacity: 1;
        }
      }
    }

    &__picture-wrap {
      height: calc((470 / 690) * var(--grid-column6));
    }
  }
}

@include respond-up('medium') {
  .card {
    &__content {
      margin-top: 10px;
    }

    &__content-bottom {
      margin-top: 4px;
    }
  }
}

@include respond('medium') {
  .card {
    &__picture-wrap {
      height: calc((243 / 357) * var(--grid-column4));
    }
  }
}

@include respond-down('small') {
  .card {
    &__content {
      margin-top: 6px;
    }

    &__content-bottom {
      margin-top: 2px;
    }

    &__bottom-delimiter {
      margin: 0 8px;
    }

    &__picture-wrap {
      height: calc((228 / 334) * var(--grid-column4));
    }
  }
}