.index-cards {
  &__item {
    display: flex;

    &:first-child {
      border: 1px solid var(--grey-line-color);
      background: #fff;
      --index-card-title-color: #000;
      --index-card-description-color: var(--black-color-40);
      --index-card-icon-bg: var(--grey-color);
      --index-card-icon-fill: var(--grey-icon-color);
    }

    &:nth-child(2) {
      background: linear-gradient(180deg, #F2F4F9 0%, #E5E9F8 100%);
      --index-card-title-color: var(--blue-color);
      --index-card-description-color: var(--black-color-40);
      --index-card-icon-bg: #fff;
      --index-card-icon-fill: var(--grey-icon-color);
    }

    &:last-child {
      background: var(--blue-color-gradient);
      --index-card-title-color: #fff;
      --index-card-description-color: #fff;
      --index-card-icon-bg: var(--blue-color);
      --index-card-icon-fill: var(--white-color-40);
    }
  }

  &__title {
    color: var(--index-card-title-color);
    transition: var(--default-transition);
  }

  &__description {
    color: var(--index-card-description-color);
  }

  &__icon-round,
  &__icon-round-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__icon-round-wrap {
    background: var(--index-card-icon-bg);
    border-radius: 50%;
  }

  &__icon-round {
    width: 22px;
    height: 22px;

    svg {
      width: 100%;
      height: 100%;

      path {
        fill: var(--index-card-icon-fill);
      }
    }
  }

  &__link {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding: 40px;
  }

  &__text-wrap,
  &__icon-wrap {
    display: flex;
    flex-direction: column;
  }

  &__icon-wrap {
    align-items: flex-end;
  }
}

@include respond-up('s-large') {
  .index-cards {
    &__item {
      grid-column: span 4;
      transition: border-color .3s var(--default-transition-function);

      &:not(:first-child) {
        position: relative;

        &:before {
          position: absolute;
          content: '';
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          opacity: 0;
          transition: opacity .3s var(--default-transition-function);
        }
      }

      &:first-child {
        &:hover {
          border-color: var(--grey-line-hover-color);

          .index-cards__title {
            color: var(--blue-color);
          }
        }
      }

      &:nth-child(2) {
        &:before {
          background: linear-gradient(180deg, #F2F4F9 0%, #D4DAF3 100%);
        }
      }

      &:last-child {
        &:before {
          background: linear-gradient(180deg, #0C247B 0%, #0B1539 100%);
        }
      }

      &:nth-child(2),
      &:last-child {
        &:hover {
          &:before {
            opacity: 1;
          }
        }
      }

      &:first-child,
      &:nth-child(2) {
        &:hover {
          .index-cards {
            &__icon-link {
              svg path {
                stroke: var(--blue-color);
              }
            }
          }
        }
      }

      &:last-child {
        &:hover {
          .index-cards {
            &__icon-link {
              svg path {
                fill: #fff;
              }
            }
          }
        }
      }
    }

    &__text-wrap {
      width: calc(var(--grid-column2) + var(--grid-gap));
    }

    &__text-wrap,
    &__icon-wrap {
      justify-content: space-between;
    }

    &__description {
      margin-top: 124px;
    }

    &__icon-link {
      width: 18px;
      height: 18px;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 100%;
        height: 100%;

        path {
          transition-property: stroke, fill;
          transition-duration: .3s;
          transition-timing-function: var(--default-transition-function);
        }
      }
    }

    &__link {
      z-index: 10;
    }
  }
}

@include respond('s-large') {
  .index-cards {
    &__link {
      padding: 30px;
    }
  }
}

@include respond-up('medium') {
  .index-cards {
    &__icon-round-wrap {
      width: 70px;
      height: 70px;
    }
  }
}

@include respond('medium') {
  .index-cards {
    &__item {
      grid-column: span 8;
    }

    &__description {
      margin-top: 10px;
    }
  }
}

@include respond-down('medium') {
  .index-cards {
    &__icon-link {
      display: none;
    }
  }
}

@include respond-down('small') {
  .index-cards {
    &__item {
      grid-column: span 4;
    }

    &__icon-round-wrap {
      width: 46px;
      height: 46px;
    }

    &__link {
      padding: 20px;
    }

    &__text-wrap {
      width: calc(var(--grid-column3) - 30px);
    }

    &__description {
      margin-top: 8px;
    }
  }
}