.action-card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;

  &__image-wrap {
    position: absolute;
    display: flex;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &__title,
  &__label-wrap,
  &__arrow {
    z-index: 10;
  }

  &__title,
  &__label-wrap {
    color: #fff;
  }

  &__arrow {
    transition: opacity .3s var(--default-transition-function);
    svg path {
      fill: #fff;
    }
  }

  &__bottom-wrap {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }

  &__label-value {
    margin-left: 20px;
  }

  &__arrow {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__picture {
    display: flex;
    width: 100%;
    height: 100%;
    position: relative;

    &:before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      height: 100%;
      background: linear-gradient(270deg, rgba(7, 20, 46, 0.00) 0%, rgba(7, 20, 46, 0.50) 100%);
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@include respond-up('s-large') {
  .action-card {
    height: calc(var(--grid-column6) * (365 / 690));
    padding: 40px;

    &::before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: #07142E1A;
      opacity: 0;
      z-index: 5;
      transition: opacity .3s var(--default-transition-function);
    }

    &:hover {
      &::before {
        opacity: 1;
      }

      .action-card {
        &__arrow {
          opacity: 1;
        }
      }
    }

    &__title {
      width: calc(var(--grid-column4) - 40px);
    }

    &__bottom-wrap {
      margin-top: 30px;
    }

    &__arrow {
      opacity: 0;
    }

    &__picture {
      &::before {
        width: calc(100% - 60px);
      }
    }
  }
}

@include respond-up('medium') {
  .action-card {
    &__arrow {
      &_mob {
        display: none;
      }

      width: 36px;
      height: 26px;
    }
  }
}

@include respond('medium') {
  .action-card {
    height: calc(var(--grid-column8) * (399 / 754));
    padding: 30px;

    &__title {
      width: calc(var(--grid-column5) - 30px);
    }

    &__bottom-wrap {
      margin-top: 16px;
    }

    &__picture {
      &:before {
        width: calc(100% - 48px);
      }
    }
  }
}

@include respond-down('medium') {
  .action-card {

  }
}

@include respond-down('small') {
  .action-card {
    min-height: calc(var(--grid-column4) * (177 / 334));
    height: 100%;
    padding: 15px 20px 20px;

    &__bottom-wrap {
      margin-top: 20px;
    }

    &__title {
      width: calc(var(--grid-column4) - 60px);
    }

    &__arrow {
      &_desk {
        display: none;
      }

      width: 26px;
      height: 16px;
    }

    &__picture {
      &:before {
        width: calc(100% - 29px);
      }
    }
  }
}