.question-item {
  overflow: hidden;
  border-bottom: 1px solid var(--grey-line-color);

  &._opened {
    .question-item {
      &__link-icon {
        &:after {
          transform: translate3d(0, -50%, 0) rotate(180deg);
        }
      }
    }
  }

  &:first-child {
    border-top: 1px solid var(--grey-line-color);
  }

  &__link {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__link-title {
    transition: color .3s var(--default-transition-function);
  }

  &__link-icon {
    position: relative;

    &:before,
    &:after {
      position: absolute;
      content: '';
      width: 100%;
      border-bottom: 2px solid var(--blue-color);
      top: 50%;
      left: 0;
      transform: translate3d(0, -50%, 0);
      transform-origin: center;
      transition: transform .3s var(--default-transition-function);
    }

    &:after {
      transform: translate3d(0, -50%, 0) rotate(90deg);
    }
  }

  &__block {
    transition: height .3s var(--default-transition-function);
    height: 0;
  }

  &__link-icon-wrap {
    border-radius: 50%;
    background: var(--grey-color);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@include respond-up('s-large') {
  .question-item {
    &._opened {
      .question-item {
        &__link-title {
          color: var(--blue-color);
        }
      }
    }

    &__link {
      padding: 26px 0 28px;

      &:hover {
        .question-item {
          &__link-title {
            color: var(--blue-color);
          }
        }
      }
    }

    &__link-title {
      flex: 0 0 var(--grid-column5);
    }

    &__block-inner {
      width: var(--grid-column5);
      padding-bottom: 60px;
    }
  }
}

@include respond-up('medium') {
  .question-item {
    &__link-icon {
      width: 18px;
      height: 18px;
    }

    &__link-icon-wrap {
      width: 46px;
      height: 46px;
    }
  }
}

@include respond('medium') {
  .question-item {
    &__link {
      padding: 20px 0;
    }

    &__link-title {
      flex: 0 0 var(--grid-column6);
    }

    &__block-inner {
      width: var(--grid-column6);
      padding-bottom: 50px;
    }
  }
}

@include respond-down('medium') {
  .question-item {

  }
}

@include respond-down('small') {
  .question-item {
    &__link-icon {
      width: 12px;
      height: 12px;
    }

    &__link-icon-wrap {
      width: 32px;
      height: 32px;
    }

    &__link {
      padding: 14px 0 18px;
    }

    &__link-title {
      flex: 0 0 calc(var(--grid-column4) - 52px);
    }

    &__block-inner {
      width: var(--grid-column4);
      padding-bottom: 30px;
    }
  }
}