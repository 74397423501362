.calculator {
  &__main,
  &__ranges {
    display: grid;
  }

  &__form {
    height: 100%;
  }

  &__info-wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: var(--blue-color-gradient);
  }

  &__success {
    + div button[type="submit"] {
      display: none;
    }
  }

  &__pay-wrap {
    color: white;
  }

  &__pay-title-wrap {
    display: flex;
    align-items: flex-end;
  }

  &__pay-currency {
    margin-left: 9px;
  }

  &__policy-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__form-inner {
    display: flex;
  }

  &__range-input {
    &_rate {
      label {
        display: block;
        @extend .texts;
        @extend .texts_paragraph-5;
        margin-bottom: 10px;
      }

      input[type="number"] {
        @extend .texts;
        @extend .texts_paragraph-3;
        width: 100%;
        height: 77px;
        border: none;
        border-radius: 0;
        background-color: var(--grey-color);
        padding: 23px 96px 25px 20px;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }
    }
  }

  &__input-wrap {
    position: relative;

    &::before {
      content: '%';
      position: absolute;
      z-index: 5;
      right: 20px;
      top: 23px;
      @extend .texts;
      @extend .texts_paragraph-3;
    }
  }
}

@include respond-up('s-large') {
  .calculator {
    &__main {
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: var(--grid-gap);
      margin-top: 40px;
    }

    &__ranges {
      grid-column: 1/2;
    }

    &__info-wrap {
      grid-column: 2/3;
      margin-top: 32px;
    }

    &__form-data {
      margin-top: 73px;
    }
  }
}

@include respond-up('medium') {
  .calculator {
    &__ranges {
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 10px;
      grid-row-gap: 30px;
    }

    &__range-input {
      &_price,
      &_fee {
        grid-column: 1/3;
      }

      &_term {
        grid-column: 1/2;
      }

      &_rate {
        grid-column: 2/3;
      }
    }

    &__pay-wrap {
      padding: 40px 40px 0;
    }

    &__pay-title-wrap {
      margin-top: 5px;
    }

    &__form-data {
      padding: 0 20px 30px;
    }

    &__policy-wrap {
      margin-top: 20px;
    }

    &__phone-field,
    &__submit {
      flex: 0 0 50%;
    }
  }
}

@include respond('medium') {
  .calculator {
    &__main {
      margin-top: 30px;
    }

    &__form-data,
    &__info-wrap {
      margin-top: 40px;
    }
  }
}

@include respond-down('medium') {
  .calculator {
    &__main {
      grid-template-columns: 1fr;
    }

    &__ranges,
    &__info-wrap {
      grid-column: 1/2;
    }
  }
}

@include respond-down('small') {
  .calculator {
    &__main {
      margin-top: 15px;
    }

    &__ranges {
      grid-template-columns: 1fr;
      grid-row-gap: 20px;
    }

    &__range-input {
      grid-column: span 1;

      &_rate {
        label {
          margin-bottom: 6px;
        }

        input[type="number"] {
          height: 56px;
          padding: 15px 69px 19px 16px;
        }
      }
    }

    &__input-wrap {
      &::before {
        right: 16px;
        top: 15px;
      }
    }

    &__pay-wrap {
      padding: 20px 20px 0;
    }

    &__pay-title-wrap {
      margin-top: 2px;
    }

    &__form-data {
      padding: 0 10px 20px;
      margin-top: 20px;
    }

    &__policy-wrap {
      margin-top: 15px;
    }

    &__form-inner {
      flex-direction: column;
    }

    &__info-wrap {
      margin-top: 30px;
    }

    &__phone-field {
      &.form-field {
        margin-bottom: unset;
      }
    }

    &__submit {
      width: 100%;
    }
  }
}