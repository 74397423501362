.banks {
  &__item {
    border-bottom: 1px solid var(--grey-line-color);

    &:first-child {
      border-top: 1px solid var(--grey-line-color);
    }
  }

  &__item-title {
    color: var(--black-color-40);
  }

  &__picture {
    display: flex;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__button-wrap {
    display: flex;
    align-items: center;
  }

  &__text-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

@include respond-up('large') {
  .banks {
    &__rate {
      grid-column: 5/7;
    }

    &__month-pay {
      grid-column: 7/9;
    }

    &__fee {
      grid-column: 9/11;
    }

    &__button-wrap {
      grid-column: 11/13;
    }
  }
}

@include respond-up('s-large') {
  .banks {
    &__list {
      margin-top: 30px;
    }

    &__item {
      padding: 30px 0;
    }

    &__image-wrap {
      grid-column: 1/3;
    }

    &__picture {
      width: 167px;
      height: 57px;
    }

    &__button-wrap {
      justify-content: flex-end;
    }
  }
}

@include respond('s-large') {
  .banks {
    &__item-title {
      white-space: nowrap;
    }

    &__rate {
      grid-column: 4/6;
    }

    &__month-pay {
      grid-column: 6/8;
    }

    &__fee {
      grid-column: 8/10;
    }

    &__button-wrap {
      grid-column: 10/13;
    }
  }
}

@include respond('medium') {
  .banks {
    &__list {
      margin-top: 20px;
    }

    &__item {
      padding: 23px 0 25px;
    }

    &__picture {
      width: 134px;
      height: 46px;
    }
  }
}

@include respond-down('medium') {
  .banks {
    &__image-wrap,
    &__rate,
    &__month-pay,
    &__fee {
      grid-column: span 2;
    }

    &__button-wrap {
      grid-column: span 3;
    }
  }
}

@include respond-down('small') {
  .banks {
    &__list {
      margin-top: 10px;
    }

    &__item {
      padding: 13px 0 20px;
    }

    &__picture {
      width: 116px;
      height: 40px;
    }
  }
}