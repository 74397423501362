.contacts-requisites {
  grid-row-gap: 0;

  &__main {
    &._opened {
      .contacts-requisites {
        &__block {
          max-height: unset;
        }

        &__button-title {
          &_opened {
            display: none;
          }

          &_hidden {
            display: block;
          }
        }
      }
    }
  }

  &__block {
    overflow: hidden;
    transition: var(--default-transition);
    height: max-content;


  }

  &__item {
    display: flex;

    &:not(:last-child) {
      border-bottom: 1px solid var(--grey-line-color);
    }
  }

  &__button {
    width: 100%;
  }

  &__button-title {
    &_hidden {
      display: none;
    }
  }
}

@include respond-up('s-large') {
  .contacts-requisites {
    &__title {
      grid-column: 1/5;
    }

    &__main {
      grid-column: 7/13;
    }

    &__button {
      margin-top: 40px;
    }

    &__name {
      flex: 0 0 var(--grid-column2);
    }
  }
}

@include respond-up('medium') {
  .contacts-requisites {
    &__block {
      max-height: 241px;
    }

    &__item {
      align-items: center;
      padding: 7px 0 10px;
    }

    &__value {
      flex: 0 1 100%;
      margin-left: var(--grid-gap);
    }
  }
}

@include respond('medium') {
  .contacts-requisites {
    &__title,
    &__main {
      grid-column: 1/9;
    }

    &__main,
    &__button {
      margin-top: 30px;
    }

    &__name {
      flex: 0 0 var(--grid-column3);
    }
  }
}

@include respond-down('small') {
  .contacts-requisites {
    &__title,
    &__main {
      grid-column: 1/5;
    }

    &__main {
      margin-top: 15px;
    }

    &__block {
      max-height: 390px;
    }

    &__button {
      margin-top: 20px;
    }

    &__item {
      flex-direction: column;
      padding: 6px 0 8px;
    }

    &__name {
      color: var(--black-color-40);
    }
  }
}