.index-page {

}

@include respond-up('s-large') {
  .index-page {
    padding-bottom: 120px;

    &__projects {
      margin-top: 100px;
    }

    &__purchase-methods,
    &__cards,
    &__about,
    &__in-our-buildings-slider,
    &__about-slider,
    &__news,
    &__actions,
    &__contacts,
    &__info-card {
      margin-top: 120px;
    }
  }
}

@include respond-up('medium') {
  .index-page {

  }
}

@include respond('medium') {
  .index-page {
    padding-bottom: 80px;

    &__projects,
    &__purchase-methods,
    &__cards,
    &__about,
    &__in-our-buildings-slider,
    &__about-slider,
    &__news,
    &__actions,
    &__contacts,
    &__info-card {
      margin-top: 80px;
    }
  }
}

@include respond-down('medium') {
  .index-page {

  }
}

@include respond-down('small') {
  .index-page {
    padding-bottom: 40px;

    &__projects,
    &__purchase-methods,
    &__cards,
    &__about,
    &__in-our-buildings-slider,
    &__about-slider,
    &__news,
    &__actions,
    &__contacts,
    &__info-card {
      margin-top: 40px;
    }
  }
}