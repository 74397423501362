.range-input {
  &__values {
    display: flex;
    position: relative;
    background-color: white;

    &_double {

    }

    &_single {

    }
  }

  &__value {
    position: relative;

    input[type="text"] {
      width: 100%;
      height: 77px;
      border: none;
      border-radius: 0;
      background-color: var(--grey-color);
      padding: 23px 96px 25px 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 130%;
    }

    &_from,
    &_to {
      flex: 0 0 50%;
    }

    &_single {
      flex: 0 0 100%;
    }
  }

  &__show {
    display: none;
  }

  &__prefix {
    display: flex;
    position: absolute;
    left: 20px;
    bottom: 0;
    height: var(--default-input-height);
    align-items: center;
  }

  &__percent {
    position: absolute;
    right: 20px;
    top: 23px;
  }

  &__label {
    margin-bottom: 10px;
  }

  .range-input__line {
    height: 2px;
    padding: 0;
    border: none;
    box-shadow: none;

    .noUi-connects {
      border-radius: 0;
      background-color: white;
    }

    .noUi-connect {
      background-color: var(--blue-color);
      cursor: pointer;
    }

    .noUi-handle {
      position: absolute;
      height: 30px;
      width: 30px;
      top: -15px;
      right: -15px;
      border: 5px solid white;
      border-radius: 100%;
      background: var(--blue-color);
      cursor: pointer;
      box-shadow: none;
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.10));

      &::after, &::before {
        display: none;
      }
    }
  }
}

@include respond-up('s-large') {
  .range-input {
    &__value {
      input[type="text"] {
        font-size: 22px !important;
        letter-spacing: -0.44px !important;
      }
    }
  }
}

@include respond('medium') {
  .range-input {
    &__value {
      input[type="text"] {
        font-size: 20px !important;
        letter-spacing: -0.4px !important;
      }
    }
  }
}

@include respond('small') {
  .range-input {
    &__label {
      margin-bottom: 6px;
    }

    &__value {
      input[type="text"] {
        height: 56px;
        padding: 15px 69px 19px 16px;
        line-height: 140% !important;
        font-size: 16px !important;
        letter-spacing: -0.32px !important;
      }
    }

    &__percent {
      right: 16px;
      top: 15px;
    }
  }
}