.construction-progress {
  &__top-wrap {
    display: flex;
  }

  &__main {
    margin: 0 calc(var(--grid-spacer-and-indent) * -1);
  }

  &__slider {
    &.swiper {
      padding: 0 var(--grid-spacer-and-indent);
    }
  }

  &__item {
    flex: 0 0 var(--grid-column3);

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      border-top: 1px solid var(--grey-line-color);
    }

    &:not(:last-child) {
      &::before {
        width: calc(100% + var(--grid-gap));
      }

      &::after {
        content: '';
        position: absolute;
        right: calc(var(--grid-gap) / -2);
        border-right: 1px solid var(--grey-line-color);
      }
    }
  }

  &__title-wrap {
    display: flex;
    justify-content: space-between;
  }

  &__images-item {
    &:nth-child(n+2) {
      display: none;
    }
  }

  &__item-info {
    display: flex;
    margin-top: 10px;
  }

  &__item-info-inner {
    display: block;
    flex: 0 1 100%;
  }

  &__item-total {
    display: block;
    color: var(--black-color-40);
    margin-top: 4px;
  }

  &__item-icon {
    display: flex;
    height: max-content;
  }

  &__picture {
    display: flex;
    width: 100%;
    height: calc(var(--grid-column3) * (215 / 315));
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__arrows {
    &._hidden {
      pointer-events: none;
      opacity: 0;
    }
  }

  &__item-title {
    display: block;
  }
}

@include respond-up('s-large') {
  .construction-progress {
    &__main {
      margin-top: 30px;
    }

    &__item {
      padding-top: 30px;

      &::after {
        top: 30px;
        height: calc(100% - 30px);
      }

      &:hover {
        .construction-progress {
          &__item-title {
            color: var(--blue-color);
          }

          &__item-icon {
            svg path {
              fill: var(--blue-color);
            }
          }

          &__picture {
            &::before {
              opacity: 1;
            }
          }
        }
      }
    }

    &__title-wrap {
      align-items: center;
    }

    &__sort-wrap {
      width: 270px;
      margin-left: 40px;
    }

    &__item-title,
    &__item-icon svg path {
      transition: var(--default-transition);
    }

    &__picture {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        z-index: 15;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: var(--blue-dark-color-10);
        opacity: 0;
        transition: var(--default-transition);
      }
    }
  }
}

@include respond-up('medium') {
  .construction-progress {
    &__top-wrap {
      justify-content: space-between;
    }
  }
}

@include respond('medium') {
  .construction-progress {
    &__item {
      padding-top: 20px;

      &::after {
        top: 20px;
        height: calc(100% - 20px);
      }
    }

    &__top-wrap {
      align-items: flex-end;
    }

    &__main {
      margin-top: 20px;
    }

    &__slider {

    }

    &__sort-wrap {
      margin-top: 20px;
      width: var(--grid-column5);
    }
  }
}

@include respond-down('medium') {
  .construction-progress {
    &__title-wrap {
      flex-direction: column;
    }
  }
}

@include respond-down('small') {
  .construction-progress {
    &__item {
      padding-top: 10px;

      &::after {
        top: 10px;
        height: calc(100% - 10px);
      }
    }

    &__top-wrap {
      align-items: flex-start;
    }

    &__slide-info {
      margin-top: 6px;
    }

    &__main {
      margin-top: 14px;
    }

    &__top-wrap,
    &__title-wrap {
      flex-direction: column;
    }

    &__slider {

    }

    &__sort-wrap {
      margin-top: 10px;
      width: var(--grid-column4);
    }

    &__arrows {
      margin-top: 20px;
    }

    &__item-info {
      margin-top: 6px;
    }

    &__item-total {
      margin-top: 2px;
    }
  }
}