.about-also {
  background: var(--soft-gradient);
  grid-row-gap: 0;

  &__docs-wrap {
    display: flex;
    align-items: flex-end;
  }

  &__link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--grey-icon-color);
    transition: var(--default-transition);
  }

  &__link-title {
    color: var(--blue-color);
  }

  &__link-icon {
    display: flex;
    height: max-content;
  }
}

@include respond-up('s-large') {
  .about-also {
    padding: 60px 0 70px;

    &__title-wrap,
    &__docs-wrap {
      grid-column: 1/7;
      padding: 0 60px;
    }

    &__menu-wrap {
      grid-column: 7/13;
      padding-right: 60px;
    }

    &__list {
      margin-top: -30px;
    }

    &__link {
      padding: 30px 0 20px;

      &:hover {
        border-bottom-color: var(--grey-icon-hover-color);

        .about-also {
          &__link-icon {
            svg path {
              stroke: var(--blue-color);
            }
          }
        }
      }
    }

    &__link-icon {
      svg path {
        transition: var(--default-transition);
      }
    }
  }
}

@include respond-up('medium') {
  .about-also {
    &__docs-wrap {
      margin: -19px 0;
    }

    &__menu-wrap {
      grid-row: 1/3;
    }
  }
}

@include respond('medium') {
  .about-also {
    padding: 40px 0 50px;

    &__title-wrap,
    &__docs-wrap {
      grid-column: 1/5;
      padding: 0 40px;
    }

    &__menu-wrap {
      grid-column: 5/9;
      padding-right: 40px;
    }

    &__list {
      margin-top: -24px;
    }

    &__link {
      padding: 24px 0 18px;
    }
  }
}

@include respond-down('small') {
  .about-also {
    padding: 20px 0 30px;

    &__title-wrap,
    &__docs-wrap,
    &__menu-wrap {
      grid-column: 1/5;
    }

    &__title-wrap {
      grid-row: 1;
      padding: 0 20px;
    }

    &__docs-wrap {
      grid-row: 3;
      margin-top: 19px;
    }

    &__menu-wrap {
      grid-row: 2;
      margin-top: 30px;
    }

    &__menu-wrap {
      padding: 0 20px;
    }

    &__list {
      margin-top: -20px;
    }

    &__link {
      padding: 20px 0 12px;
    }
  }
}