/*
spacer - Задаёт фиксированные отступы по краям контейнера.

Использование в шаблоне:

<div class="container container_spacer">
  ...
</div>

По умолчанию в качестве единиц измерения для отступа используются - 'px'.
При необходимости их можно изменить переменной $spacer-unit в файлике ../_common/_container.scss
*/

$grid: (
  small: (
    from: 0,
    to: 767
  ),
  medium: (
    from: 768,
    to: 1279
  ),
  s-large: (
    from: 1280,
    to: 1440
  ),
  large: (
    from: 1441,
  ),
);

:root {
  --body-font-size: 16px;
  --body-line-height: 1.3;

  --font: 'Inter', sans-serif;
  --h-font: 'Halvar Breit', sans-serif;

  --t: transparent;

  --primary-color: black;
  --background-color: var(--grey-color); // серая подлога
  --alert-color: #FF6D6D;

  --blue-color: #0C247B; //blue
  --blue-hover-color: #0D2B9B; //blue-hover
  --blue-light-color: #A6BDE9;
  --grey-color: #F5F5F5; //grey
  --grey-hover-color: #EFEFF2; //grey-hover
  --grey-icon-color: #B8BDCB; //icon-grey
  --grey-icon-hover-color: #8C95AF; //icon-grey-hover
  --grey-line-color: #CECECE; //grey-line
  --grey-line-hover-color: #BCBCBC; //grey-line-hover

  --blue-dark-color-10: rgba(7, 20, 46, .1);

  --black-color-40: rgba(0, 0, 0, .4); //black 40
  --white-color-10: rgba(255, 255, 255, .1); //white 10
  --white-color-13: rgba(255, 255, 255, .13); //white 13
  --white-color-40: rgba(255, 255, 255, .4); //white 40
  --white-color-80: rgba(255, 255, 255, .8); //white 80
  --blue-color-40: rgba(12, 36, 123, .4); //blue 40
  --blue-color-80: rgba(12, 36, 123, .8); //blue 80
  --gradient-image-color-20: rgba(7, 20, 46, .2); //black gradient image color 20
  --gradient-image-color-30: rgba(7, 20, 46, .3); //black gradient image color 30

  --blue-color-gradient: linear-gradient(180deg, #0C247B 0%, #060C21 100%);
  --blue-white-color-gradient: linear-gradient(180deg, #F2F4F9 0%, #C9D5FF 100%);
  --soft-gradient: linear-gradient(180deg, #F2F4F9 0%, #E5E9F8 100%);

  --default-transition: all 0.3s var(--default-transition-function);
  --default-transition-function: cubic-bezier(.25, .1, .25, 1);
}