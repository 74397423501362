.card-big {
  border-top: 1px solid var(--grey-line-color);

  &__picture-wrap,
  &__subtitle,
  &__title,
  &__description,
  &__secondary-info,
  &__additional-info,
  &__tags-more,
  &__tags-more-title {
    display: block;
  }

  &__picture-wrap {
    position: relative;
    background-color: var(--grey-color);
  }

  &__icon-arrow {
    display: flex;
    height: max-content;
    position: absolute;
    right: 0;

    svg path {
      fill: var(--grey-icon-color);
      transition: var(--default-transition);
    }

    &_mobile {
      display: none;
    }
  }

  &__picture {
    display: flex;
    width: 100%;
    height: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
  }

  &__subtitle,
  &__additional-info {
    color: var(--black-color-40);
  }

  &__bottom {
    display: flex;
    align-items: center;
  }

  &__bottom-delimiter {
    width: 3px;
    height: 3px;
    background-color: var(--black-color-40);
    border-radius: 100%;
    margin: 0 10px;
  }

  &__tags-wrap {
    display: none;
  }

  &__title {
    transition: var(--default-transition);
  }

  &__additional-image {
    position: absolute;
    z-index: 5;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: var(--default-transition);
  }

  &__additional-image-bg {
    display: block;
    width: 100%;
    height: 100%;
    background-color: var(--blue-dark-color-10)
  }
}

@include respond-up('s-large') {
  .card-big {
    padding: 30px 0;

    &:hover {
      .card-big {
        &__title {
          color: var(--blue-color);
        }

        &__icon-arrow {
          svg path {
            fill: var(--blue-color);
          }
        }
      }
    }

    &__picture-wrap {
      grid-column: 1/7;
      height: calc((470 / 690) * var(--grid-column6));

      &:hover {
        .card-big {
          &__additional-image {
            opacity: 1;
          }
        }
      }
    }

    &__content {
      grid-column: 7/13;
      justify-content: space-between;
    }

    &__tags {
      margin-top: 20px;
    }

    &__description {
      margin-top: 40px;
    }

    &__icon-arrow {
      bottom: 0;
    }

    &__bottom {
      &._hidden {
        opacity: 0;
        pointer-events: none;
      }
    }
  }
}

@include respond-up('medium') {
  .card-big {
    &__tags-more-title {
      padding-top: 2px;
    }

  }
}

@include respond('medium') {
  .card-big {
    padding: 20px 0 50px;
    grid-row-gap: 20px;

    &__picture-wrap,
    &__content {
      grid-column: 1/9;
    }

    &__picture-wrap {
      height: calc((514 / 754) * var(--grid-column8));
    }

    &__tags {
      margin-top: 15px;
    }

    &__description {
      margin-top: 30px;
    }

    &__bottom {
      margin-top: 20px;
    }
  }
}

@include respond-down('medium') {
  .card-big {
    &__subtitle {
      display: none;
    }

    &__secondary-info {
      color: var(--black-color-40);
    }

    &__icon-arrow {
      top: 0;
    }

    &__bottom {
      &._hidden {
        display: none;
      }
    }
  }
}

@include respond-down('small') {
  .card-big {
    padding: 10px 0 30px;
    grid-row-gap: 10px;

    &__picture-wrap,
    &__content {
      grid-column: 1/5;
    }

    &__picture-wrap {
      height: calc((228 / 334) * var(--grid-column4));
    }

    &__description {
      margin-top: 10px;
    }

    &__bottom-delimiter {
      margin: 0 8px;
    }

    &__icon-arrow {
      &_desk {
        display: none;
      }

      &_mobile {
        display: block;
      }
    }

    &__tags-wrap {
      display: flex;
      flex-wrap: wrap;
      position: absolute;
      top: 10px;
      right: 10px;
      left: 10px;
      margin: -2px;

      &._hidden {
        .card-big__tags-mobile_more {
          opacity: 0;
        }
      }

      &._visible {
        .card-big__tags-mobile_more {
          opacity: 1;
        }
      }
    }

    &__tags-mobile {
      flex: 0 0 auto;
      margin: 0 !important;

      &_more {
        transition: var(--default-transition);
        flex: 0 0 100%;
      }
    }

    &__tags-more {
      flex: 0 1 auto;
      padding: 2px 8px;
      margin: 2px;
      background-color: var(--blue-color);
    }

    &__tags-more-title {
      color: white;
      padding-top: 1px;
    }

    &__tags {
      display: none;
    }

    &__bottom {
      margin-top: 15px;
    }
  }
}