@include respond-up('s-large') {
  .block-heading {
    &__title {
      grid-column: 1/7;
    }

    &__description {
      grid-column: 7/13;
    }
  }
}

@include respond('medium') {
  .block-heading {
    &.grid-block {
      grid-row-gap: 25px;
    }

    &__title {
      grid-column: 1/9;
      grid-row: 1;
    }

    &__description {
      grid-column: 1/9;
      grid-row: 2;
    }
  }
}

@include respond-down('small') {
  .block-heading {
    &.grid-block {
      grid-row-gap: 15px;
    }

    &__title {
      grid-column: 1/5;
      grid-row: 1;
    }

    &__description {
      grid-column: 1/5;
      grid-row: 2;
    }
  }
}