.renovation-tabs-block {
  padding-bottom: 30px;
  border-bottom: 1px solid var(--grey-line-color);

  &__picture {
    display: flex;
    width: 100%;
    height: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__content-inner {
    display: grid;
    grid-gap: var(--grid-gap);
    grid-template-columns: 1fr;
  }

  &__content-picture,
  &__content-text {
    grid-column: span 1;
  }

  &__contents {
    &._single {
      border-top: 1px solid var(--grey-line-color);
    }
  }
}

@include respond-up('s-large') {
  .renovation-tabs-block {
    &__tabs {
      margin-top: 30px;
    }

    &__contents {
      margin-top: 30px;

      &._single {
        padding-top: 30px;
      }
    }

    &__content-inner {
      grid-template-columns: repeat(2, 1fr);
    }

    &__content-picture {
      height: calc(var(--grid-column6) * (453 / 689));
    }

    &__content-text {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &__content-description {
      margin-top: 20px;
    }

    &__content-link {
      margin-top: 40px;
    }
  }
}

@include respond('medium') {
  .renovation-tabs-block {
    padding-bottom: 50px;

    &__tabs {
      margin-top: 20px;
    }

    &__contents {
      margin-top: 20px;

      &._single {
        padding-top: 20px;
      }
    }

    &__content-picture {
      height: calc(var(--grid-column8) * (496 / 754));
    }

    &__content-description {
      margin-top: 15px;
    }

    &__content-link {
      margin-top: 30px;
    }

    &__content-inner {
      grid-row-gap: 20px;
    }
  }
}

@include respond-down('small') {
  .renovation-tabs-block {
    &__tabs {
      margin-top: 10px;
    }

    &__contents {
      margin-top: 10px;

      &._single {
        padding-top: 10px;
      }
    }

    &__content-picture {
      height: calc(var(--grid-column4) * (220 / 334));
    }

    &__content-description {
      margin-top: 8px;
    }

    &__content-link {
      margin-top: 20px;
    }

    &__content-inner {
      grid-row-gap: 10px;
    }
  }
}
