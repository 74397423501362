.tags {
  display: flex;
  flex-wrap: wrap;
  margin: -2px;

  &__item {
    display: flex;
    align-items: center;
    padding: 4px 10px 6px;
    background-color: var(--grey-color);
    margin: 2px;
  }

  &__icon {
    display: flex;
    height: max-content;
  }

  &__title {
    display: block;
    margin-left: 8px;
    white-space: nowrap;
  }

  &_white {
    .tags {
      &__item {
        background: var(--white-20, rgba(255, 255, 255, 0.20));
        backdrop-filter: blur(5px);
      }

      &__icon {
        svg path {
          fill: white;
        }
      }

      &__title {
        color: white;
      }
    }
  }

  &_mini {
    .tags {
      &__item {
        padding: 2px 8px;
        background-color: white;

        &_more {
          background-color: var(--blue-color);

          .tags__title {
            color: white;
          }
        }
      }

      &__title {
        margin: 0;
      }
    }
  }
}

@include respond-up('s-large') {
  .tags {
    &__title {
      margin-left: 8px;
    }
  }
}

@include respond-up('medium') {
  .tags {
    &_card {
      .tags__title {
        padding-top: 2px;
      }
    }
  }
}

@include respond('small') {
  .tags {
    &__item {
      padding: 3px 8px;
    }

    &__title {
      margin-left: 6px;
    }

    &__more {
      padding: 2px 6px;
    }

    &_mini {
      .tags {
        &__item {
          padding: 2px 6px 3px;
        }
      }
    }

    &_card {
      .tags__title {
        padding-top: 1px;
      }
    }
  }
}