.tabs {
  &::-webkit-scrollbar {
    display: none;
  }

  &__list {
    display: flex;
    width: max-content;
  }

  &__link {
    display: flex;
    width: 100%;
    height: 100%;
    transition: var(--default-transition);
  }

  &__item {
    position: relative;
  }

  &__text {
    white-space: nowrap;
    display: block;
    transition: var(--default-transition);
  }

  &_mini {
    .tabs {
      &__item {
        &._active {
          .tabs {
            &__link {
              background-color: var(--blue-color);
              border-top: 1px solid var(--blue-color);
              border-bottom: 1px solid var(--blue-color);
              border-left: 1px solid var(--blue-color);
            }

            &__text {
              color: white;
            }
          }

          &:last-child {
            .tabs__link {
              border-right: 1px solid var(--blue-color);
            }
          }
        }

        &:last-child {
          .tabs__link {
            border-right: 1px solid var(--grey-line-color);
          }
        }
      }

      &__link {
        background-color: var(--t);
        border-top: 1px solid var(--grey-line-color);
        border-bottom: 1px solid var(--grey-line-color);
        border-left: 1px solid var(--grey-line-color);
      }

      &__text {
        color: black;
      }
    }
  }

  &_big {
    .tabs {
      &__item {
        background-color: var(--grey-color);

        &._active {
          .tabs__text {
            background-color: white;
            color: black;
            box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05);
          }
        }

        &:not(:last-child) {
          &::before {
            content: '';
            position: absolute;
            top: 10px;
            right: 0;
            width: 1px;
            height: calc(100% - 20px);
            border-right: 1px solid var(--grey-line-color);
          }
        }
      }

      &__link {
        padding: 10px;
      }

      &__text {
        padding: 27px;
        color: var(--blue-color);
      }
    }

    &_width {
      .tabs {
        &__list {
          width: 100%;
        }

        &__item {
          flex: 0 1 100%;
        }

        &__text {
          text-align: center;
        }
      }
    }
  }
}

@include respond-up('s-large') {
  .tabs {
    &_mini {
      .tabs {
        &__item {
          &:hover {
            .tabs__link {
              background-color: var(--grey-color);
              color: var(--blue-color);
            }
          }

          &._active {
            &:hover {
              .tabs__link {
                background-color: var(--blue-hover-color);
                color: white;
              }
            }
          }
        }
      }
    }

    &_big {
      .tabs {
        &__item {
          &:hover {
            .tabs__text {
              background-color: white;
              color: black;
              box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05);
            }
          }
        }
      }
    }
  }
}

@include respond-down('medium') {
  .tabs {
    margin: 0 calc(var(--grid-spacer) * -1);

    &_overflowed {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        z-index: 15;
        top: 0;
        left: 0;
        width: 15px;
        height: 100%;
        background: linear-gradient(90deg, #F5F5F5 0%, rgba(245, 245, 245, 0.00) 100%);
      }

      &::after {
        content: '';
        position: absolute;
        z-index: 15;
        top: 0;
        right: 0;
        width: 15px;
        height: 100%;
        background: linear-gradient(270deg, #F5F5F5 0%, rgba(245, 245, 245, 0.00) 100%);
      }
    }

    &__list {
      overflow-x: scroll;
      padding: 0 var(--grid-spacer);
      width: 100%;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}

@include respond-down('small') {
  .tabs {
    &_big {
      .tabs {
        &__text {
          padding: 13px 16px;
        }
      }
    }
  }
}

@include respond('small') {
  .tabs {
    &_mini {
      .tabs {
        &__link {
          &.button_small {
            padding: 11px 18px;
          }
        }
      }
    }
  }
}