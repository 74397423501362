.projects-page {
  &__item {
    &:last-child {
      .card-big {
        border-bottom: 1px solid var(--grey-line-color);
      }
    }
  }

  &__done-list {
    border-top: 1px solid var(--grey-line-color);
  }
}

@include respond-up('s-large') {
  .projects-page {
    padding-bottom: 120px;

    &._has-active {
      .projects-page__done-projects {
        margin-top: 120px;
      }
    }

    &__done-list {
      margin-top: 30px;
      padding-top: 30px;
    }
  }
}

@include respond('medium') {
  .projects-page {
    padding-bottom: 80px;

    &._has-active {
      .projects-page__done-projects {
        margin-top: 80px;
      }
    }

    &__done-list {
      margin-top: 20px;
      padding-top: 20px;
    }
  }
}

@include respond-down('small') {
  .projects-page {
    padding-bottom: 60px;

    &._has-active {
      .projects-page__done-projects {
        margin-top: 40px;
      }
    }

    &__done-list {
      margin-top: 10px;
      padding-top: 10px;
    }
  }
}