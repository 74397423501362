.guarantee {
  &__item {
    border-top: 1px solid var(--grey-line-color);
  }

  &__service-list {
    display: grid;
  }

  &__service-item {
    border: 1px solid var(--grey-line-color);
  }

  article {
    p,
    ol li,
    ul li {
      @extend .texts;
      @extend .texts_paragraph-4;
    }
  }
}

@include respond-up('s-large') {
  .guarantee {
    padding-bottom: 50px;

    &__service-item-text {
      padding-top: 20px;
    }

    &__link {
      margin-top: 40px;
    }

    &__pt60 {
      margin-top: 60px;
    }

    &__content-heading {
      width: var(--grid-column8);
    }

    &__pt25 {
      margin-top: 25px;
    }

    &__item {
      padding: 60px calc(var(--grid-column2) + var(--grid-gap)) 70px calc(var(--grid-column3) + var(--grid-gap));
      counter-increment: main-counter;
      position: relative;

      &:before {
        content: "0" counter(main-counter);
        font-family: var(--h-font);
        font-size: var(--h-size);
        font-style: normal;
        font-weight: 700;
        text-transform: uppercase;
        --h-size: 30px;
        letter-spacing: -0.6px;
        position: absolute;
        left: 0;
      }
    }

    &__item-description {
      margin-top: 25px;
    }

    &__list {
      margin-top: 60px;
      list-style: none;
      counter-reset: main-counter;
    }

    article {
      p,
      ol,
      ul {
        margin-top: 25px;
      }

      h5 {
        margin-top: 60px;
      }
    }
  }
}

@include respond-up('medium') {
  .guarantee {
    &__service-item {
      padding: 40px;
    }

    &__service-list {
      grid-gap: 30px;
    }

    article {
      ol,
      ul {
        //padding-left: 25px;
      }
    }
  }
}

@include respond('medium') {
  .guarantee {
    padding-bottom: 30px;

    &__service-item-text {
      padding-top: 15px;
    }

    &__link {
      margin-top: 30px;
    }

    &__pt25 {
      margin-top: 15px;
    }

    &__pt60 {
      margin-top: 50px;
    }

    &__item {
      padding-top: 40px;
      padding-bottom: 50px;
    }

    &__item-description {
      margin-top: 20px;
    }

    &__list {
      margin-top: 50px;
    }

    article {
      p {
        margin-top: 20px;
      }

      h5 {
        margin-top: 50px;
      }

      ol,
      ul {
        margin-top: 15px;
      }
    }
  }
}

@include respond-down('small') {
  .guarantee {
    padding-bottom: 10px;

    &__service-item-text {
      padding-top: 8px;
    }

    &__service-item {
      padding: 20px;
    }

    &__service-list {
      grid-gap: 20px;
    }

    &__link {
      margin-top: 20px;
    }

    &__pt25 {
      margin-top: 8px;
    }

    &__pt60 {
      margin-top: 30px;
    }

    &__item {
      padding-top: 20px;
      padding-bottom: 30px;
    }

    &__item-description {
      margin-top: 10px;
    }

    &__list {
      margin-top: 30px;
    }

    article {
      p {
        margin-top: 10px;
      }

      h5 {
        margin-top: 30px;
      }

      ol,
      ul {
        margin-top: 8px;
        //padding-left: 20px;
      }
    }
  }
}