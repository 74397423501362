.share {
  display: flex;

  &__wrap {
    display: flex;
    position: sticky;
  }

  .ya-share2__list.ya-share2__list_direction_horizontal > .ya-share2__item {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--grey-line-color);
  }

  &__share {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ya-share2__item_service_whatsapp .ya-share2__badge,
  .ya-share2__item_service_telegram .ya-share2__badge,
  .ya-share2__item_service_vkontakte .ya-share2__badge {
    background-color: white;
  }

  .ya-share2__item_service_vkontakte .ya-share2__icon {
    background-image: url('../images/svg/vk.svg');
  }

  .ya-share2__item_service_telegram .ya-share2__icon {
    background-image: url('../images/svg/tg.svg');
  }

  .ya-share2__item_service_whatsapp .ya-share2__icon {
    background-image: url('../images/svg/wa.svg');
  }

  .ya-share2__icon {
    background-size: 18px 18px !important;
    background-position: center;
    background-repeat: no-repeat;
  }
}

@include respond-up('s-large') {
  .share {
    position: sticky;
    top: var(--header-height);
    height: fit-content;

    &__wrap {
      flex-direction: column;
      align-items: center;
    }

    .ya-share2__list.ya-share2__list_direction_horizontal > .ya-share2__item {
      margin: 0 0 -1px 0;
    }

    .ya-share2__list {
      display: flex;
      flex-direction: column;
    }

    .ya-share2__item {
      transition: var(--default-transition);

      &:hover {
        border-color: var(--grey-line-hover-color) !important;

        .ya-share2__item_service_vkontakte .ya-share2__icon {
          background-image: url('../images/svg/vk-hover.svg');
        }

        .ya-share2__item_service_telegram .ya-share2__icon {
          background-image: url('../images/svg/tg-hover.svg');
        }

        .ya-share2__item_service_whatsapp .ya-share2__icon {
          background-image: url('../images/svg/wa-hover.svg');
        }
      }
    }
  }
}

@include respond-up('medium') {
  .share {
    .ya-share2__list.ya-share2__list_direction_horizontal > .ya-share2__item,
    &__share {
      width: 50px;
      height: 50px;
    }
  }
}

@include respond('medium') {
  .share {
    margin-top: 50px;
  }
}

@include respond-down('medium') {
  .share {
    margin-left: -15px;

    .ya-share2__list.ya-share2__list_direction_horizontal > .ya-share2__item {
      margin: 0 -1px 0 0;
    }

    .ya-share2__list.ya-share2__list_direction_horizontal {
      display: flex;
      flex-direction: row;
    }
  }
}

@include respond-down('small') {
  .share {
    margin-top: 30px;

    &__share,
    .ya-share2__container_mobile.ya-share2__container_size_m .ya-share2__icon {
      width: 42px;
      height: 42px;
    }
  }
}
