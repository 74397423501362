.news-page {

}

@include respond-up('s-large') {
  .news-page {
    padding-bottom: 120px;

    &__list {
      //margin-top: 60px;
    }
  }
}

@include respond-up('medium') {
  .news-page {

  }
}

@include respond('medium') {
  .news-page {
    padding-bottom: 80px;

    &__list {
      //margin-top: 40px;
    }
  }
}

@include respond-down('medium') {
  .news-page {

  }
}

@include respond-down('small') {
  .news-page {
    padding-bottom: 40px;

    &__list {
      //margin-top: 20px;
    }
  }
}