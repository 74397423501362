.vacancy-form {
  &__title-container {
    border: 1px solid var(--grey-line-color);
  }

  &__data {
    background: var(--blue-color-gradient);
  }

  &__data-title {
    color: white;
    text-align: center;
  }

  &__submit {
    width: 100%;
  }

  &__policy-wrap {
    display: flex;
    justify-content: center;
  }

  &__success {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: var(--blue-color-gradient);
  }

  &__success-icon {
    display: flex;
    height: max-content;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__success-title,
  &__success-subtitle {
    width: var(--grid-column4);
    text-align: center;
  }

  &__success-title {
    color: white;
    margin-top: 30px;
  }

  &__success-subtitle {
    color: var(--white-color-40);
    margin-top: 20px;
  }
}

@include respond-up('s-large') {
  .vacancy-form {
    &__title-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      grid-column: 1/7;
    }

    &__title {
      width: var(--grid-column5);
    }

    &__description {
      width: var(--grid-column4);
    }

    &__data {
      grid-column: 7/13;
      padding: 40px 20px;
    }

    &__description {
      margin-top: 40px;
    }

    &__submit {
      margin-top: 40px;
    }

    &__bottom-fields {
      margin-top: 20px;
    }
  }
}

@include respond-up('medium') {
  .vacancy-form {
    &__title-container {
      padding: 40px 40px 50px;
    }

    &__top {
      margin-top: 30px;
    }

    &__policy-wrap {
      margin-top: 20px;
    }
  }
}

@include respond('medium') {
  .vacancy-form {
    &__inner {
      grid-row-gap: 40px;
    }

    &__title-container,
    &__data {
      grid-column: 1/9;
    }

    &__data {
      padding: 30px 20px 40px;
    }

    &__description {
      margin-top: 20px;
    }

    &__title,
    &__description {
      width: calc(var(--grid-column7) - 40px);
    }

    &__fields {
      display: flex;
    }

    &__field {
      flex: 0 1 100%;

      &:last-child {
        margin-left: 10px;
      }
    }

    &__success-subtitle {
      margin-top: 15px;
    }

    &__submit {
      margin-top: 30px;
    }

    &__bottom-fields {
      margin-top: 10px;
    }
  }
}

@include respond-down('small') {
  .vacancy-form {
    &__inner {
      grid-row-gap: 20px;
    }

    &__title-container {
      padding: 20px 20px 30px;
    }

    &__top {
      margin-top: 20px;
    }

    &__title-container,
    &__data {
      grid-column: 1/5;
    }

    &__data {
      padding: 20px;
    }

    &__description {
      margin-top: 10px;
    }

    &__policy-wrap {
      margin-top: 15px;
    }

    &__success-icon {
      width: 46px;
      height: 46px;
    }

    &__success-title {
      margin-top: 20px;
    }

    &__success-subtitle {
      margin-top: 8px;
    }

    &__bottom-fields {
      margin-top: 10px;
    }

    &__submit {
      margin-top: 20px;
    }
  }
}