.policy {
  position: relative;
  padding-left: 36px;

  input[type="checkbox"] {
    position: absolute;
    left: 0;
    top: -2px;

    appearance: none;
    background-color: transparent;
    margin: 0;

    width: 22px;
    height: 22px;
    border-radius: 0;
    transition: var(--default-transition);
    cursor: pointer;

    &::before {
      content: "";
      display: block;
      width: 22px;
      height: 22px;
      transition: var(--default-transition);
      background-image: url('../images/svg/checkbox-unchecked.svg');
      background-repeat: no-repeat;
      background-position: center;
    }

    &:checked {
      &::before {
        background-image: url('../images/svg/checkbox-checked.svg');
      }
    }
  }

  label {
    color: var(--white-color-40);
    @extend .texts;
    @extend .texts_paragraph-7;
  }

  &__link {
    color: white;
  }

  &_light {
    input[type="checkbox"] {
      &::before {
        background-image: url('../images/svg/checkbox-unchecked-light.svg');
      }

      &:checked {
        &::before {
          background-image: url('../images/svg/checkbox-checked-light.svg');
        }
      }
    }

    label {
      color: var(--black-color-40);
    }

    .policy {
      &__link {
        color: black;
      }
    }
  }
}

@include respond-up('s-large') {
  .policy {
    input[type="checkbox"] {
      &:not(:checked) {
        &:hover {
          &::before {
            background-image: url('../images/svg/checkbox-hovered.svg');
          }
        }
      }
    }

    &__link {
      &:hover {
        color: var(--white-color-80);
      }
    }

    &_light {
      input[type="checkbox"] {
        &:not(:checked) {
          &:hover {
            &::before {
              background-image: url('../images/svg/checkbox-hovered-light.svg');
            }
          }
        }
      }

      .policy {
        &__link {
          &:hover {
            color: var(--blue-color);
          }
        }
      }
    }
  }
}