.online-card {
  display: flex;
  flex-direction: column;
  width: var(--grid-column3);

  &__top-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--blue-color-gradient);
    height: calc(var(--grid-column3) * (215 / 315));
  }

  &__info-wrap {
    display: flex;
    flex-direction: column;
  }

  &__live {
    position: relative;

    &:before {
      position: absolute;
      content: '';
      width: 8px;
      height: 8px;
      background: var(--alert-color);
      top: 50%;
      left: 0;
      border-radius: 50%;
      transform: translate3d(0, -50%, 0);
    }
  }

  &__bottom-wrap {
    display: flex;
    justify-content: space-between;
  }

  &__arrow-wrap,
  &__icon-wrap {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__arrow-wrap {
    height: 18px;
    width: 18px;
  }
}

@include respond-up('s-large') {
  .online-card {
    &:hover {
      .online-card {
        &__title {
          color: var(--blue-color);
        }

        &__arrow-wrap {
          svg path {
            stroke: var(--blue-color);
          }
        }
      }
    }

    &__title {
      transition: color .3s var(--default-transition-function);
    }

    &__arrow-wrap {
      svg path {
        transition: stroke .3s var(--default-transition-function);
      }
    }
  }
}

@include respond-up('medium') {
  .online-card {
    &__live {
      margin-top: 4px;
      padding-left: 18px;
    }

    &__bottom-wrap {
      margin-top: 10px;
    }
  }
}

@include respond('medium') {
  .online-card {

  }
}

@include respond-down('medium') {
  .online-card {
    &__icon-wrap {
      width: 85px;
      height: 42px;
    }
  }
}

@include respond-down('small') {
  .online-card {
    &__live {
      margin-top: 2px;
      padding-left: 16px;
    }

    &__bottom-wrap {
      margin-top: 6px;
    }
  }
}