.vacancies-page {
  &__description-wrapper {
    height: 0;
    overflow: hidden;
    transition: height 0.3s var(--default-transition-function);
  }

  &__plus {
    .button__icon {
      &:after {
        z-index: 10;
        transition: background-color 0.3s var(--default-transition-function);
      }

      &:before {
        z-index: 15;
      }
    }
  }

  &__item {
    &._opened {
      .vacancies-page__plus {
        .button__icon {
          &:after {
            background: var(--grey-color);
          }
        }
      }
    }
  }

  &__list {
    border-bottom: 1px solid var(--grey-line-color);
  }

  &__plus {
    background: var(--grey-color);
  }

  &__link-info-wrap {
    display: flex;
  }

  &__link {
    border-top: 1px solid var(--grey-line-color);
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__salary {
    flex: 1 0 auto;
  }

  &__links {
    display: flex;
  }
}

@include respond-up('s-large') {
  .vacancies-page {
    margin-top: 60px;

    &__list {
      margin-bottom: 120px;
    }

    &__link-info-wrap {
      align-items: center;
      flex: 1 0 auto;
    }

    &__links {
      margin-top: 40px;
    }

    &__link {
      padding: 30px 0;
    }

    &__description-wrap {
      padding: 30px calc(var(--grid-column1) + var(--grid-gap)) 60px calc(var(--grid-column6) + var(--grid-gap));
    }

    &__title {
      flex: 0 0 var(--grid-column6);
      margin-right: var(--grid-gap);
    }
  }
}

@include respond-up("medium") {
  .vacancies-page {
    &__hh-link {
      margin-left: 40px;
    }
  }
}

@include respond('medium') {
  .vacancies-page {
    margin-top: 40px;

    &__list {
      margin-bottom: 80px;
    }

    &__salary {
      margin-top: 6px;
    }

    &__links {
      margin-top: 30px;
    }

    &__link {
      padding: 30px 0 36px;
    }

    &__description-wrap {
      padding-bottom: 50px;
      padding-right: calc(var(--grid-column1) + var(--grid-gap));
      padding-top: 4px;
    }
  }
}

@include respond-down('medium') {
  .vacancies-page {
    &__link-info-wrap {
      flex-direction: column;
    }
  }
}

@include respond-down('small') {
  .vacancies-page {
    margin-top: 20px;

    &__list {
      margin-bottom: 40px;
    }

    &__description-wrap {
      padding-bottom: 20px;
      padding-top: 10px;
    }

    &__salary {
      margin-top: 2px;
    }

    &__links {
      flex-direction: column;
      margin-top: 20px;
    }

    &__hh-link {
      margin-left: -20px;
      margin-top: 10px;
    }

    &__link {
      padding: 15px 0;
    }
  }
}
