.advantages-slide {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  cursor: default;

  &._has-modal {
    .advantages-slide {
      &__link {
        cursor: pointer;
      }

      &__button {
        display: flex;
      }
    }
  }

  &:not(:first-child) {
    &:after {
      position: absolute;
      content: '';
      border-left: 1px solid var(--grey-line-color);
      left: calc(-1 * (var(--grid-gap) / 2));
      height: 100%;
      top: 0;
    }
  }

  &__link {
    cursor: grab;
  }

  &__title {
    display: flex;
  }

  &__image-wrap {
    position: relative;
    display: block;
  }

  &__button {
    position: absolute;
    display: none;

    &.button_small {
      padding: 0;
    }
  }

  &__picture {
    width: 100%;
    display: flex;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@include respond-up('s-large') {
  .advantages-slide {
    &.swiper-slide {
      width: var(--grid-column4);
    }

    &__link {
      &:hover {
        .advantages-slide {
          &__button {
            opacity: 1;
          }
        }
      }
    }

    &__button {
      right: 30px;
      bottom: 30px;
      transition: var(--default-transition);
      opacity: 0;
    }

    &__picture {
      &_horizontal {
        height: calc(var(--grid-column4) * (343 / 440));
      }

      &_vertical {
        height: calc(var(--grid-column4) * (512 / 440));
      }
    }
  }
}

@include respond-up('medium') {
  .advantages-slide {
    &__title {
      margin-top: 10px;
    }
  }
}

@include respond('medium') {
  .advantages-slide {
    &__button {
      right: 20px;
      bottom: 20px;
    }

    &__picture {
      &_horizontal {
        height: calc(var(--grid-column3) * (201 / 258));
      }

      &_vertical {
        height: calc(var(--grid-column3) * (300 / 258));
      }
    }
  }
}

@include respond-down('medium') {
  .advantages-slide {
    &.swiper-slide {
      width: var(--grid-column3);
    }
  }
}

@include respond-down('small') {
  .advantages-slide {
    &__title {
      margin-top: 6px;
    }

    &__button {
      right: 10px;
      bottom: 10px;
    }

    &__picture {
      &_horizontal {
        height: calc(var(--grid-column3) * (192 / 246));
      }

      &_vertical {
        height: calc(var(--grid-column3) * (286 / 246));
      }
    }
  }
}