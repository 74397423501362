.content-header {
  &__description {
    @extend .texts;
    @extend .texts_paragraph-2;
  }

  &__news-date {
    color: var(--black-color-40);
  }
}

@include respond-up('s-large') {
  .content-header {
    &__news {
      display: grid;
      grid-template-columns: var(--grid-column3) var(--grid-column8) var(--grid-column1);
      grid-gap: var(--grid-gap);
      margin-top: 90px;
      margin-bottom: 30px;
    }

    h1 {
      padding: 60px 0 40px;
    }

    &__description {
      max-width: var(--grid-column8);
      margin-bottom: 70px;
    }
  }
}

@include respond('medium') {
  .content-header {
    &__news {
      margin-top: 40px;
      margin-bottom: 20px;
    }

    &__news-title {
      margin-top: 15px;
    }

    h1 {
      padding: 40px 0 30px;
    }

    &__description {
      margin-bottom: 50px;
    }
  }
}

@include respond-down('medium') {
  .content-header {
    &__news {
      display: flex;
      flex-direction: column;
    }
  }
}

@include respond-down('small') {
  .content-header {
    &__news {
      margin-top: 20px;
      margin-bottom: 10px;
    }

    &__news-title {
      margin-top: 10px;
    }

    h1 {
      padding: 20px 0 15px;
    }

    &__description {
      margin-bottom: 30px;
    }
  }
}