.purchase-method-hero {
  position: relative;

  &__picture-wrap {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      z-index: 5;
      bottom: 0;
      left: 0;
      width: 100%;
      background: linear-gradient(180deg, rgba(7, 20, 46, 0.00) 0%, rgba(7, 20, 46, 0.50) 100%);
    }

    &::after {
      content: '';
      position: absolute;
      z-index: 5;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: var(--gradient-image-color-20);
    }
  }

  &__picture {
    display: flex;
    width: 100%;
    height: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__text-wrap {
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__title,
  &__description {
    color: white;
  }
}

@include respond-up('s-large') {
  .purchase-method-hero {
    &__picture-wrap {
      height: calc(var(--grid-column12) * (580 / 1440));

      &::before {
        height: calc(var(--grid-column12) * (333 / 1440));
      }
    }

    &__text-wrap {
      padding: 50px 60px 60px;
    }

    &__description {
      width: var(--grid-column8);
    }

    &__button {
      margin-top: 40px;
    }
  }
}

@include respond('medium') {
  .purchase-method-hero {
    &__picture-wrap {
      height: 468px;

      &::before {
        height: 286px;
      }
    }

    &__text-wrap {
      padding: 30px 40px 40px;
    }

    &__button {
      margin-top: 30px;
    }
  }
}

@include respond-down('small') {
  .purchase-method-hero {
    &__picture-wrap {
      height: 357px;

      &::before {
        height: 250px;
      }
    }

    &__text-wrap {
      padding: 15px 20px 20px;
    }

    &__button {
      margin-top: 20px;
    }
  }
}