.easy-to-start {
  &__title-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__slider {
    &.swiper {
      padding: 0 var(--grid-spacer-and-indent);
    }
  }
}

@include respond-up('s-large') {
  .easy-to-start {
    padding: 120px 0;

    &__slider-wrap {
      margin-top: 30px;
    }
  }
}

@include respond('medium') {
  .easy-to-start {
    padding: 80px 0;

    &__slider-wrap {
      margin-top: 20px;
    }
  }
}

@include respond-down('small') {
  .easy-to-start {
    padding: 40px 0;

    &__slider-wrap {
      margin-top: 10px;
    }
  }
}