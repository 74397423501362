.contacts-page {

}

@include respond-up('s-large') {
  .contacts-page {
    padding-bottom: 120px;

    &__offices {
      margin-top: 70px;
    }

    &__requisites {
      margin-top: 120px;
    }
  }
}

@include respond-up('medium') {
  .contacts-page {

  }
}

@include respond('medium') {
  .contacts-page {
    padding-bottom: 80px;

    &__offices {
      margin-top: 50px;
    }

    &__requisites {
      margin-top: 80px;
    }
  }
}

@include respond-down('medium') {
  .contacts-page {

  }
}

@include respond-down('small') {
  .contacts-page {
    padding-bottom: 40px;

    &__offices {
      margin-top: 30px;
    }

    &__requisites {
      margin-top: 40px;
    }
  }
}