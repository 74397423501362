h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  font-family: var(--h-font);
  font-size: var(--h-size);
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
}

h1, .h1, {
  line-height: 110%;
}

h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  line-height: 120%;
}

h6, .h6 {
  --h-size: 14px;
}

@include respond-up("large") {
  h1, .h1 {
    --h-size: 60px;
    letter-spacing: -1.2px;
  }

  h2, .h2 {
    --h-size: 40px;
    letter-spacing: -0.8px;
  }
}

@include respond-up("s-large") {
  h3, .h3 {
    --h-size: 30px;
    letter-spacing: -0.6px;
  }

  h4, .h4 {
    --h-size: 25px;
    letter-spacing: -0.75px;
  }

  h5, .h5 {
    --h-size: 22px;
    letter-spacing: -0.66px;
  }
}
@include respond("s-large") {
  h1, .h1 {
    --h-size: 50px;
    letter-spacing: -1px;
  }

  h2, .h2 {
    --h-size: 35px;
    letter-spacing: -0.7px;
  }
}

@include respond("medium") {
  h1, .h1 {
    --h-size: 45px;
    letter-spacing: -0.9px;
  }

  h2, .h2 {
    --h-size: 30px;
    letter-spacing: -0.6px;
  }

  h3, .h3 {
    --h-size: 25px;
    letter-spacing: -0.5px;
  }

  h4, .h4 {
    --h-size: 22px;
    letter-spacing: -0.66px;
  }

  h5, .h5 {
    --h-size: 18px;
    letter-spacing: -0.54px;
  }
}

@include respond-down("medium") {
  h4, .h4 {
    line-height: 125%;
  }

  h5, .h5 {
    line-height: 130%;
  }
}

@include respond-down("small") {
  h1, .h1 {
    --h-size: 27px;
    letter-spacing: -0.54px;
  }

  h2, .h2 {
    --h-size: 20px;
    letter-spacing: -0.4px;
  }

  h3, .h3 {
    --h-size: 18px;
    letter-spacing: -0.36px;
  }

  h4, .h4 {
    --h-size: 16px;
    letter-spacing: -0.48px;
  }

  h5, .h5 {
    --h-size: 15px;
    letter-spacing: -0.45px;
  }

  h6, .h6 {
    --h-size: 12px;
  }
}
