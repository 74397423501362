.purchase-cards {
  &__item {
    border: 1px solid var(--grey-line-color);
  }

  &__link {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__top-wrap,
  &__bottom-wrap {
    display: flex;
    justify-content: space-between;
  }

  &__top-wrap {
    align-items: flex-start;
  }

  &__bottom-wrap {
    align-items: center;
  }

  &__title {
    transition: color .3s var(--default-transition-function);
  }

  &__description {
    color: var(--black-color-40);
  }

  &__icon,
  &__arrow {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__icon {
    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__arrow {
    svg {
      width: 100%;
      height: 100%;
      path {
        transition: fill .3s var(--default-transition-function);
        fill: var(--grey-icon-color);
      }
    }
  }
}

@include respond-up('s-large') {
  .purchase-cards {
    &__item {
      grid-column: span 6;
    }

    &__bottom-wrap {
      margin-top: 64px;
    }

    &__link {
      &:hover {
        .purchase-cards {
          &__title {
            color: var(--blue-color);
          }

          &__arrow {
            svg path {
              fill: var(--blue-color);
            }
          }
        }
      }
    }
  }
}

@include respond-up('medium') {
  .purchase-cards {
    &__link {
      padding: 40px;
    }

    &__icon {
      width: 60px;
      height: 60px;
    }

    &__arrow {
      width: 34px;
      height: 26px;

      &_mob {
        display: none;
      }
    }
  }
}

@include respond('medium') {
  .purchase-cards {
    &__item {
      grid-column: span 8;
    }

    &__bottom-wrap {
      margin-top: 44px;
    }
  }
}

@include respond-down('small') {
  .purchase-cards {
    &__item {
      grid-column: span 4;
    }

    &__link {
      padding: 20px;
    }

    &__bottom-wrap {
      margin-top: 38px;
    }

    &__icon {
      width: 46px;
      height: 46px;
    }

    &__arrow {
      width: 26px;
      height: 16px;

      &_desk {
        display: none;
      }
    }
  }
}