.multi-uploadable-field {
  &__input-wrap {
    position: relative;
  }

  input[type='file'] {
    position: absolute;
    z-index: 5;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }

  label {
    position: relative;
    z-index: 10;
    cursor: pointer;
    margin: 0;

    font-family: var(--h-font);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
    color: var(--blue-color);
    text-align: center;

    border: 1px solid var(--blue-color-40);
    padding: 14px 20px;
    width: max-content;
    transition: var(--default-transition);
  }

  .input-file-list {
    img {
      display: none;
    }
  }

  .input-file-item {
    display: flex;
    align-items: center;

    &:first-child {
      margin-top: 26px;
    }
  }

  .input-file-name {
    @extend .texts;
    @extend .texts_paragraph-5;
  }

  .input-file-list-remove {
    position: relative;
    display: block;
    width: 30px;
    height: 30px;

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 16px;
      height: 2px;
      background-color: var(--black-color-40);
      transition: var(--default-transition);
    }

    &::before {
      transform: translate3d(-50%, -50%, 0) rotate(-45deg);
    }

    &::after {
      transform: translate3d(-50%, -50%, 0) rotate(45deg);
    }
  }

  &._white {
    label {
      color: white;
      border: 1px solid var(--white-color-40);
    }

    .input-file-list-remove {
      &::before,
      &::after {
        background-color: var(--white-color-40);
      }
    }

    .input-file-name {
      color: white;
    }
  }
}

@include respond-up('s-large') {
  .multi-uploadable-field {
    label {
      &:hover {
        border-color: var(--blue-color);
      }
    }

    .input-file-list-remove {
      &:hover {
        &::before,
        &::after {
          background-color: var(--primary-color);
        }
      }
    }

    &._white {
      label {
        &:hover {
          border-color: white;
        }
      }

      .input-file-list-remove {
        &:hover {
          &::before,
          &::after {
            background-color: white;
          }
        }
      }
    }
  }
}

@include respond-up('medium') {
  .multi-uploadable-field {

  }
}

@include respond('medium') {
  .multi-uploadable-field {

  }
}

@include respond-down('medium') {
  .multi-uploadable-field {

  }
}

@include respond-down('small') {
  .multi-uploadable-field {
    label {
      font-size: 12px;
      padding: 12px 20px;
      width: 100%;
    }

    .input-file-item {
      &:first-child {
        margin-top: 16px;
      }
    }
  }
}