.project-docs-accordion {
  border-bottom: 1px solid var(--grey-line-color);

  &:first-child {
    border-top: 1px solid var(--grey-line-color);
  }

  &__element {
    overflow: hidden;

    &._opened {
      .project-docs-accordion {
        &__button {
          .button__icon {
            &:after {
              transform: translate(-50%,-50%) rotate(180deg);
            }
          }
        }
      }
    }
  }

  &__link {
    display: flex;
    align-items: center;
  }

  &__title-wrap {
    display: flex;
    flex: auto;
  }

  &__block {
    transition: height .3s var(--default-transition-function);
    height: 0;
  }

  &__block-inner {
    display: flex;
  }

  &__doc-list {
    width: 100%;
  }

  &__doc-item {
    &:not(:last-child) {
      .project-docs-accordion__doc-link {
        border-bottom: 1px solid var(--grey-line-color);
      }
    }
  }

  &__doc-link {
    display: flex;
  }

  &__doc-link-title {
    flex: 0 1 100%;
  }

  &__doc-link-right {
    display: flex;
    align-items: center;
    flex: 0 0 auto;
  }

  &__button-wrap {
    display: flex;
    justify-content: flex-end;
  }

  &__button {
    .button__icon {
      &:after {
        transform-origin: center;
        transition: transform .3s var(--default-transition-function);
      }
    }
  }

  &__docs-count,
  &__doc-size {
    color: var(--black-color-40);
  }

  &__download-icon {
    display: flex;
    height: max-content;
  }
}

@include respond-up('s-large') {
  .project-docs-accordion {
    &__link {
      padding: 30px 0;
    }

    &__title {
      flex: 0 0 var(--grid-column6);
    }

    &__docs-count {
      flex: 0 0 var(--grid-column5);
    }

    &__block-inner {
      justify-content: flex-end;
      padding: 19px 0 58px;
    }

    &__doc-list {
      width: var(--grid-column6);
    }

    &__doc-link {
      &:hover {
        .project-docs-accordion {
          &__doc-link-title {
            color: var(--blue-color);
          }

          &__download-icon {
            svg path {
              stroke: var(--blue-color);
            }
          }
        }
      }
    }

    &__doc-link-title,
    &__download-icon svg path {
      transition: var(--default-transition);
    }
  }
}

@include respond-up('medium') {
  .project-docs-accordion {
    &__title-wrap {
      align-items: center;
    }

    &__doc-link {
      padding: 15px 0;
      align-items: center;
      justify-content: space-between;
    }

    &__docs-count,
    &__button-wrap {
      margin-left: var(--grid-gap);
    }

    &__button-wrap {
      flex: 0 0 var(--grid-column1);
    }

    &__download-icon {
      margin-left: 48px;
    }

    &__doc-link-right {
      margin-left: 15px;
    }
  }
}

@include respond('medium') {
  .project-docs-accordion {
    &__link {
      padding: 20px 0;
    }

    &__title {
      flex: 0 0 var(--grid-column4);
    }

    &__docs-count {
      flex: 0 0 var(--grid-column3);
    }

    &__block-inner {
      padding: 17px 0 50px;
    }
  }
}

@include respond-down('medium') {
  .project-docs-accordion {

  }
}

@include respond-down('small') {
  .project-docs-accordion {
    &__link {
      padding: 15px 0;
    }

    &__title-wrap {
      flex-direction: column;
    }

    &__docs-count {
      margin-top: 2px;
    }

    &__button-wrap {
      margin-left: 20px;
    }

    &__doc-link {
      padding: 6px 0 9px;
      flex-direction: column;
    }

    &__doc-link-right {
      justify-content: space-between;
    }

    &__block-inner {
      padding: 5px 0 20px;
    }
  }
}