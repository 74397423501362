.project-stores {
  &__main {
    display: grid;
    grid-column-gap: var(--grid-gap);
  }

  &__item {
    grid-column: span 1;
  }
}

@include respond-up('s-large') {
  .project-stores {
    &__main {
      grid-template-columns: repeat(2, 1fr);
      margin-top: 20px;
    }
  }
}

@include respond('medium') {
  .project-stores {
    &__main {
      grid-row-gap: 40px;
      margin-top: 20px;
    }
  }
}

@include respond-down('medium') {
  .project-stores {
    &__main {
      grid-template-columns: 1fr;
    }
  }
}

@include respond-down('small') {
  .project-stores {
    &__main {
      grid-row-gap: 20px;
      margin-top: 10px;
    }
  }
}