.news-page-list {
  &__button-wrap {
    .button {
      &_big {
        width: 100%;
      }
    }
  }
}

@include respond-up('s-large') {
  .news-page-list {
    padding-top: 60px;
    display: grid;
    grid-template-columns: var(--grid-column9) var(--grid-column3);
    grid-column-gap: var(--grid-gap);

    &__list-wrap {
      grid-column: 1/2;
      grid-row: 1;
    }

    &__year-filter-wrap {
      grid-column: 2/3;
      grid-row: 1;
      position: relative;
    }

    &__button-wrap {
      .pagination-endless button {
        margin-top: 30px;
      }
    }
  }
}

@include respond('medium') {
  .news-page-list {
    padding-top: 40px;

    &__button-wrap {
      .pagination-endless button {
        margin-top: 20px;
      }
    }
  }
}

@include respond-down('medium') {
  .news-page-list {
    display: flex;
    flex-direction: column;
  }
}

@include respond-down('small') {
  .news-page-list {
    padding-top: 20px;

    &__button-wrap {
      .pagination-endless button {
        margin-top: 10px;
      }
    }
  }
}