.about-layout {
  &__projects-button {
    &.button {
      width: 100%;
    }
  }
}

@include respond-up('s-large') {
  .about-layout {
    padding-bottom: 120px;

    &__also {
      margin-top: 120px;
    }
  }
}

@include respond-up('medium') {
  .about-layout {
    &__projects-button {
      &.button {
        margin-top: 20px;
      }
    }
  }
}

@include respond('medium') {
  .about-layout {
    padding-bottom: 80px;

    &__also {
      margin-top: 80px;
    }
  }
}

@include respond-down('medium') {
  .about-layout {
    .tabs {
      margin: 0;
    }

    .tabs__list {
      padding: 0;
    }
  }
}

@include respond-down('small') {
  .about-layout {
    padding-bottom: 40px;

    &__also {
      margin-top: 40px;
    }

    &__projects-button {
      &.button {
        margin-top: 10px;
      }
    }
  }
}