.card-with-description {

}

@include respond-up('s-large') {
  .card-with-description {
    &__title {
      flex: 0 0 calc(var(--grid-gap) + var(--grid-column5));
    }

    &__description-wrap {
      flex: 0 0 var(--grid-column5);
      margin-left: var(--grid-gap);
    }

    &__button-wrap {
      margin-top: 40px;
    }
  }
}

@include respond-up('medium') {
  .card-with-description {

  }
}

@include respond('medium') {
  .card-with-description {
    &__description-wrap {
      margin-top: 20px;
    }

    &__button-wrap {
      margin-top: 30px;
    }
  }
}

@include respond-down('medium') {
  .card-with-description {
    flex-direction: column;
  }
}

@include respond-down('small') {
  .card-with-description {
    &__description-wrap {
      margin-top: 10px;
    }

    &__button-wrap {
      margin-top: 20px;
    }
  }
}