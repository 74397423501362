.project-genplan {
  position: relative;
  width: 100vw;

  .dp-genplan {
    display: block !important;
    font-family: var(--font);
  }

  .dp-genplan-housenum-n,
  .dp-genplan-housenum-full-title,
  .dp-genplan-housenum-full-vars {
    @extend .texts;
  }

  .dp-genplan-housenum-n {
    @extend .texts_paragraph-4;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 46px;
    height: 46px;
  }

  .dp-genplan-housenum-full-title {
    @extend .texts_paragraph-3;
  }

  .dp-genplan-housenum-full-vars {
    @extend .texts_paragraph-6;
  }

  .dp-genplan-housenum-full-inner {
    border-radius: 0;
  }
}

@include respond-up('s-large') {
  .project-genplan {
    &__title {
      top: 60px;
      left: var(--grid-spacer-and-indent);
      right: var(--grid-spacer-and-indent);
    }
  }
}

@include respond-up('medium') {
  .project-genplan {
    &__title {
      position: absolute;
      z-index: 10;
      color: white;
    }
  }
}

@include respond('medium') {
  .project-genplan {
    &__title {
      top: 30px;
      left: var(--grid-spacer);
      right: var(--grid-spacer);
    }
  }
}

@include respond-down('small') {
  .project-genplan {
    &__title {
      padding: 0 var(--grid-spacer);
      margin-bottom: 10px;
    }
  }
}