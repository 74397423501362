.purchase-questions {
  &__container,
  &__title-wrap {
    display: flex;
  }

  &__container {
    flex-direction: column;
  }
}

@include respond-up('s-large') {
  .purchase-questions {
    &__title-wrap {
      grid-column: 1/7;
      flex-direction: column;
    }

    &__accordion-wrap {
      grid-column: 7/13;
    }

    &__button-wrap {
      margin-top: 30px;
    }
  }
}

@include respond('medium') {
  .purchase-questions {
    &__title-wrap {
      grid-column: 1/9;
      grid-row: 1;
      align-items: flex-end;
      justify-content: space-between;
    }

    &__accordion-wrap {
      grid-column: 1/9;
      grid-row: 2;
    }
  }
}

@include respond-down('medium') {
  .purchase-questions {
    &.grid-block {
      grid-row-gap: 20px;
    }
  }
}

@include respond-down('small') {
  .purchase-questions {
    &__title-wrap {
      grid-column: 1/5;
      grid-row: 1;
      flex-direction: column;
    }

    &__accordion-wrap {
      grid-column: 1/5;
      grid-row: 2;
    }

    &__button-wrap {
      margin-top: 10px;
    }
  }
}