.about-points {
  &__list {
    &.grid-block {
      grid-row-gap: 0;
    }
  }

  &__item {
    display: flex;
    border-bottom: 1px solid var(--grey-line-color);
  }
}

@include respond-up('s-large') {
  .about-points {
    &__item {
      grid-column: span 6;
      padding: 40px 0;

      &:first-child,
      &:nth-child(2) {
        border-top: 1px solid var(--grey-line-color);
      }
    }

    &__value {
      flex: 0 0 calc(var(--grid-column2) + var(--grid-gap) - 30px);
    }

    &__title {
      flex: 0 0 var(--grid-column4);
      margin-left: 30px;
    }
  }
}

@include respond-up('medium') {
  .about-points {
    &__item {
      align-items: center;
    }
  }
}

@include respond('medium') {
  .about-points {
    &__item {
      padding: 30px 0;
      grid-column: span 8;
    }

    &__value {
      flex: 0 0 var(--grid-column3);
    }

    &__title {
      flex: 0 0 var(--grid-column5);
    }
  }
}

@include respond-down('medium') {
  .about-points {
    &__item {
      &:first-child {
        border-top: 1px solid var(--grey-line-color);
      }
    }

    &__title {
      margin-left: var(--grid-gap);
    }
  }
}

@include respond-down('small') {
  .about-points {
    &__item {
      padding: 14px 0 16px;
      grid-column: span 4;
    }

    &__value,
    &__title {
      flex: 0 0 var(--grid-column2);
    }
  }
}