.project-store-card {
  display: flex;
  border: 1px solid var(--grey-line-color);

  &__icon {
    display: flex;
    height: max-content;

    svg path {
      transition: var(--default-transition);
    }
  }

  &__picture {
    display: flex;
    width: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__info-bottom-title {
    color: var(--black-color-40);
  }

  &__info-top {
    display: flex;
    flex-direction: column;
  }

  &__info-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__info-block {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 0 1 100%;
  }
}

@include respond-up('s-large') {
  .project-store-card {
    transition: var(--default-transition);

    &:hover {
      border-color: var(--grey-line-hover-color);

      .project-store-card {
        &__title {
          color: var(--blue-color);
        }

        &__icon {
          svg {
            path {
              stroke: var(--blue-color);
            }
          }
        }
      }
    }

    &__title {
      transition: var(--default-transition);
    }

    &__info-block {
      padding: 30px 40px 30px var(--grid-gap);
    }

    &__subtitle {
      margin-top: 6px;
    }
  }
}

@include respond-up('medium') {
  .project-store-card {
    &__picture {
      flex: 0 0 var(--grid-column3);
      height: calc(var(--grid-column3) * (234 / 315));
    }

    &__info-block {
      padding: 30px 40px 30px var(--grid-gap);
    }
  }
}

@include respond('medium') {
  .project-store-card {
    &__info-top {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
  }
}

@include respond-down('small') {
  .project-store-card {
    &__picture {
      flex: 0 0 var(--grid-column2);
      height: calc(var(--grid-column2) * (138 / 157));
    }

    &__subtitle {
      margin-top: 4px;
    }

    &__info-block {
      padding: 15px 20px 15px var(--grid-gap);
    }
  }
}