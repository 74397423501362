.offices-map {
  position: relative;

  &__list-wrap {
    position: absolute;
    z-index: 50;
  }
}

@include respond-up('s-large') {
  .offices-map {
    &__list-wrap {
      top: 60px;
      bottom: 60px;
      left: var(--grid-spacer-and-indent);
      width: var(--grid-column4);
      height: max-content;
      max-height: 629px;
      background-color: white;
      box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.08);
    }

    &__map-element {
      height: 749px;
    }
  }
}

@include respond-up('medium') {
  .offices-map {

  }
}

@include respond('medium') {
  .offices-map {
    &__list-wrap {
      padding: 20px var(--grid-spacer) 50px;
    }

    &__map-element {
      height: 550px;
    }
  }
}

@include respond-down('medium') {
  .offices-map {
    &__list-wrap {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: var(--t);
      transition: var(--default-transition);
      pointer-events: none;

      &._active {
        background-color: var(--background-color);
        pointer-events: auto;
      }
    }
  }
}

@include respond-down('small') {
  .offices-map {
    &__list-wrap {
      padding: 10px var(--grid-spacer) 40px;
    }

    &__map-element {
      height: 438px;
    }
  }
}