.pay-cards {
  &__list {

  }

  &__item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-height: 332px;

    &:nth-child(odd) {
      background: var(--blue-color-gradient);

      .pay-cards {
        &__item-title-wrap,
        &__description {
          color: #fff;
        }
      }
    }

    &:nth-child(even) {
      background: var(--blue-white-color-gradient);

      .pay-cards {
        &__item-title-wrap {
          color: var(--blue-color);
        }
      }
    }
  }

  &__item-title-wrap {
    display: flex;
    align-items: flex-end;
  }

  &__item-currency {
    margin-left: 9px;
  }
}

@include respond-up('s-large') {
  .pay-cards {
    &__list {
      margin-top: 30px;
    }

    &__item {
      padding: 40px 40px 50px;
      height: calc(var(--grid-column6) * (332 / 691));

      &:nth-child(odd) {
        grid-column: 1/7;
      }

      &:nth-child(even) {
        grid-column: 7/13;
      }
    }
  }
}

@include respond('medium') {
  .pay-cards {
    &__list {
      margin-top: 20px;
    }

    &__item {
      grid-column: 1/9;
      padding: 40px;
      min-height: 253px;
    }
  }
}

@include respond-down('small') {
  .pay-cards {
    &__list {
      margin-top: 10px;
    }

    &__item {
      grid-column: 1/5;
      padding: 20px;
      min-height: 190px;
    }

    &__content {
      margin-top: 40px;
    }
  }
}