.index-actions {
  &__heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__slider {
    &.swiper {
      padding: 0 var(--grid-spacer-and-indent);
    }
  }

  &__item {
    display: flex;
    &.swiper-slide {
      height: auto;
    }
  }

  &__button {
    width: 100%;
  }
}

@include respond-up('s-large') {
  .index-actions {
    padding: 120px 0;

    &__slider-wrap {
      margin-top: 30px;
    }

    &__button {
      margin-top: 50px;

      &:hover {
        background-color: white;
        color: var(--blue-color);
        border-color: white;
      }
    }
  }
}

@include respond-up('medium') {
  .index-actions {
    &__item {
      flex: 0 0 var(--grid-column6);
    }
  }
}

@include respond('medium') {
  .index-actions {
    padding: 80px 0;

    &__slider-wrap {
      margin-top: 20px;
    }

    &__button {
      margin-top: 40px;
    }

    &__action-card {
      &.action-card {
        height: calc(var(--grid-column6) * (294 / 556));
      }
    }
  }
}

@include respond-down('small') {
  .index-actions {
    padding: 40px 0;

    &__slider-wrap {
      margin-top: 10px;
    }

    &__item {
      flex: 0 0 var(--grid-column4);
    }

    &__button {
      margin-top: 20px;
    }
  }
}