.project-page {
  overflow: hidden;
}

@include respond-up('s-large') {
  .project-page {
    padding-bottom: 120px;

    &__advantages-slider,
    &__genplan,
    &__layouts,
    &__albums,
    &__documents,
    &__buildings,
    &__albums,
    &__infrastructure,
    &__construction-progress,
    &__renovations,
    &__advantages,
    &__excusrion,
    &__mortgage {
      margin-top: 120px;
    }

    &__stores {
      margin-top: 60px;
    }
  }
}

@include respond-up('medium') {
  .project-page {

  }
}

@include respond('medium') {
  .project-page {
    padding-bottom: 80px;

    &__advantages-slider,
    &__genplan,
    &__layouts,
    &__albums,
    &__documents,
    &__buildings,
    &__albums,
    &__infrastructure,
    &__construction-progress,
    &__renovations,
    &__advantages,
    &__excusrion,
    &__mortgage {
      margin-top: 80px;
    }

    &__stores {
      margin-top: 50px;
    }
  }
}

@include respond-down('medium') {
  .project-page {

  }
}

@include respond-down('small') {
  .project-page {
    padding-bottom: 40px;

    &__advantages-slider,
    &__genplan,
    &__layouts,
    &__albums,
    &__documents,
    &__buildings,
    &__albums,
    &__infrastructure,
    &__construction-progress,
    &__renovations,
    &__advantages,
    &__excusrion,
    &__mortgage {
      margin-top: 40px;
    }

    &__stores {
      margin-top: 30px;
    }
  }
}