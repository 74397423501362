.news-page-filter {
  &__list {
    display: flex;
  }

  &__link {
    color: var(--black-color-40);
    transition: color .3s var(--default-transition-function);
    display: flex;
  }

  &__item {
    position: relative;

    &:before {
      position: absolute;
      content: '';
      transition: opacity .3s var(--default-transition-function);
      opacity: 0;
    }
  }

  &__item {
    &._active {
      &:before {
        opacity: 1;
      }

      .news-page-filter {
        &__link {
          color: black;
        }
      }
    }
  }
}

@include respond-up('s-large') {
  .news-page-filter {
    margin-top: -12px;
    position: sticky;
    top: calc(var(--header-height) + 20px);

    &__list {
      padding-left: 40px;
      position: relative;
      flex-direction: column;

      &:before {
        position: absolute;
        content: '';
        border-left: 1px solid var(--grey-line-color);
        height: calc(100% - 24px);
        top: 12px;
        left: 0;
      }
    }

    &__item {
      &:before {
        border-left: 3px solid var(--blue-color);
        height: calc(100% - 24px);
        left: -40px;
        top: 12px;
      }
    }

    &__link {
      padding: 12px 0;

      &:hover {
        color: black;
      }
    }
  }
}

@include respond-up('medium') {
  .news-page-filter {

  }
}

@include respond('medium') {
  .news-page-filter {
    &__list {
      margin-left: -12px;
      margin-top: -20px;
    }

    &__link {
      padding: 20px 12px;
    }

    &__item {
      &:before {
        width: calc(100% - 24px);
        left: 12px;
      }
    }
  }
}

@include respond-down('medium') {
  .news-page-filter {
    &__item {
      &:before {
        border-bottom: 3px solid var(--blue-color);
        bottom: -1px;
      }
    }
  }
}

@include respond-down('small') {
  .news-page-filter {
    &__list {
      margin-left: -10px;
      margin-top: -12px;
    }

    &__link {
      padding: 12px 10px;
    }

    &__item {
      &:before {
        width: calc(100% - 20px);
        left: 10px;
      }
    }
  }
}