.hero {
  position: relative;

  &__slider-wrap {
    position: relative;
    height: 100%;

    &::before,
    &::after {
      content: '';
      position: absolute;
      z-index: 10;
      width: 100%;
      pointer-events: none;
    }

    &::before {
      top: 0;
      left: 0;
      background: linear-gradient(0deg, rgba(7, 20, 46, 0.00) 0%, rgba(7, 20, 46, 0.50) 100%);
    }

    &::after {
      bottom: 0;
      left: 0;
      background: linear-gradient(180deg, rgba(7, 20, 46, 0.00) 0%, rgba(7, 20, 46, 0.50) 100%);
    }
  }

  &__slider {
    height: 100%;
  }

  &__picture {
    display: flex;
    width: 100%;
    height: 100%;
    pointer-events: none;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__text-slider {
    display: flex;
    align-items: flex-end;
    position: absolute;
    z-index: 10;
    pointer-events: none;
  }

  &__text-container {
    position: absolute;
    z-index: 10;
    display: flex;
    flex-direction: column;
    pointer-events: none;
  }

  &__text-slider-list {
    height: 100%;
    width: 100%;
  }

  &__text-slide {
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    height: max-content;
    z-index: 5;
    overflow: hidden;
    transition: transform 0s, opacity 0s;
    transition-timing-function: var(--default-transition-function);
    opacity: 0;

    &._active {
      z-index: 10;
      transform: translate3d(0, 0, 0);
      opacity: 1;
      transition-duration: .7s;
    }

    &._in-next-act {
      transform: translate3d(50px, 0, 0) !important;
      opacity: 0 !important;
    }

    &._out-next-act {
      transform: translate3d(-50px, 0, 0);
      transition-duration: .3s;
    }

    &._in-prev-act {
      transform: translate3d(-50px, 0, 0) !important;
      opacity: 0 !important;
    }

    &._out-prev-act {
      transform: translate3d(50px, 0, 0);
      transition-duration: .3s;
    }
  }

  &__subtitle,
  &__title {
    color: white;
  }

  &__title {
    margin-top: 10px;
  }

  &__button {
    margin-top: 30px;
    pointer-events: auto;
    width: max-content;
  }

  &__pagination {
    &.swiper-pagination-bullets.swiper-pagination-horizontal {
      z-index: 50;
      height: 2px;
    }

    .swiper-pagination-bullet {
      flex: 0 1 100%;
    }
  }

  &__prev,
  &__next {
    position: absolute;
    z-index: 5;
    top: 0;
    bottom: 0;
    width: var(--grid-column3);
    height: 100%;
    opacity: 0;
    transition: var(--default-transition);

    svg {
      position: absolute;
      z-index: 5;
      top: 50%;
      transform: translate3d(0, -50%, 0);

      path {
        fill: white;
      }
    }

    &:hover {
      opacity: 1;
    }
  }

  &__prev {
    left: 0;
    background: linear-gradient(270deg, rgba(7, 20, 46, 0.00) 0%, rgba(7, 20, 46, 0.30) 100%);

    svg {
      left: 40px;
      transform: rotate(180deg);
    }
  }

  &__next {
    right: 0;
    background: linear-gradient(90deg, rgba(7, 20, 46, 0.00) 0%, rgba(7, 20, 46, 0.30) 100%);

    svg {
      right: 40px;
    }
  }
}

@include respond-up('s-large') {
  .hero {
    height: 100vh;
    max-height: 1080px;

    &__slider-wrap {
      &::before {
        height: 28vh;
      }

      &::after {
        height: 40vh;
      }
    }

    &__text-slider {
      top: calc(var(--header-height) + 80px);
      left: var(--grid-spacer-and-indent);
      bottom: 80px;
      width: var(--grid-column6);
      height: calc(100% - var(--header-height) - 160px);
    }

    &__text-container {
      bottom: 80px;
      left: var(--grid-spacer-and-indent);
      width: var(--grid-column6);
    }

    &__pagination {
      &.swiper-pagination-bullets.swiper-pagination-horizontal {
        bottom: 80px;
        right: var(--grid-spacer-and-indent);
        left: unset;
        width: var(--grid-column5);
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }

      .swiper-pagination-bullet {
        max-width: 132px;
      }
    }

    &__tags {
      margin-top: 30px;
    }
  }
}

@include respond-up('medium') {
  .hero {

  }
}

@include respond('medium') {
  .hero {
    height: calc((790 / 834) * 100vw);

    &__slider-wrap {
      &::before {
        height: calc((240 / 834) * 100vw);
      }

      &::after {
        height: calc((370 / 834) * 100vw);
      }
    }

    &__text-slider {
      top: calc(var(--header-height) + 92px);
      bottom: 92px;
      width: var(--grid-column7);
      height: calc(((790 / 834) * 100vw) - var(--header-height) - 184px);
    }

    &__text-container {
      bottom: 92px;
      width: var(--grid-column7);
    }

    &__pagination {
      &.swiper-pagination-bullets.swiper-pagination-horizontal {
        bottom: 30px;
      }
    }

    &__tags {
      margin-top: 20px;
    }
  }
}

@include respond-down('medium') {
  .hero {
    &__text-slider,
    &__text-container {
      left: var(--grid-spacer);
      right: var(--grid-spacer);
    }

    &__pagination {
      &.swiper-pagination-bullets.swiper-pagination-horizontal {
        right: var(--grid-spacer);
        left: var(--grid-spacer);
        width: calc(100% - var(--grid-spacer) * 2);
      }
    }

    &__prev,
    &__next {
      display: none;
    }
  }
}

@include respond-down('small') {
  .hero {
    height: calc((444 / 374) * 100vw);

    &__slider-wrap {
      &::before {
        height: calc((111 / 374) * 100vw);
      }

      &::after {
        height: calc((301 / 374) * 100vw);
      }
    }

    &__text-slider {
      top: calc(var(--header-height) + 62px);
      bottom: 62px;
      width: var(--grid-column4);
      height: calc(((444 / 374) * 100vw) - var(--header-height) - 124px);
    }

    &__text-container {
      bottom: 62px;
      width: var(--grid-column4);
    }

    &__pagination {
      &.swiper-pagination-bullets.swiper-pagination-horizontal {
        bottom: 20px;
      }
    }

    &__tags {
      margin-top: 15px;
    }
  }
}