//это у нас блок
article {
  &:first-child {
    :first-child {
      margin-top: 0;
    }
  }

  &, p, li {
    @extend .texts;
    @extend .texts_paragraph-4;
  }

  blockquote {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      background-size: var(--quote-size) var(--quote-size);
      width: var(--quote-size);
      height: var(--quote-size);
      background-image: url('../images/svg/quote.svg');
      left: 0;
      top: 5px;
    }

    p {
      @extend .texts;
      @extend .texts_paragraph-3;
      font-style: italic;
    }
  }

  ul {
    li {
      &::before {
        content: '';
        width: 6px;
        height: 6px;
        border-radius: 3px;
        background-color: var(--blue-color);
        top: 10px;
      }
    }
  }

  ol {
    counter-reset: ol-counter;

    li {
      &::before {
        content: counter(ol-counter) ".";
      }
    }
  }

  li {
    position: relative;
    counter-increment: ol-counter;
    padding-left: 20px;

    &::before {
      font-family: inherit;
      font-size: inherit;
      font-style: normal;
      text-transform: uppercase;
      position: absolute;
      left: 0;
    }
  }

  strong {
    @extend .texts;
    @extend .texts_paragraph-2;
  }

  em {
    font-style: italic;
  }

  a {
    color: var(--blue-color);
    text-decoration: none;
    display: inline-flex;
    position: relative;

    &::before {
      position: absolute;
      content: '';
      width: 100%;
      bottom: 4px;
      border-bottom: 1px solid var(--t);
      transition: border-bottom-color 0.3s var(--default-transition-function);
    }

    &:hover {
      &::before {
        border-color: var(--blue-color-40);
      }
    }
  }
}

@include respond-up('medium') {
  article {
    blockquote {
      --quote-size: 26px;
      padding-left: 56px;
    }
  }
}

@include respond-down('small') {
  article {
    blockquote {
      --quote-size: 18px;
      padding-left: 38px;
    }
  }
}