.socials {
  display: flex;

  &__icons {
    border: 1px solid var(--grey-line-color);
    display: inline-flex;
    align-items: center;
    justify-content: center;

    &_yt {
      transform: translate3d(-1px, 0, 0);
    }

    svg {
      path {
        transition: var(--default-transition);
      }
    }
  }
}

@include respond-up('s-large') {
  .socials {
    &__icons {
      &:hover {
        border: 1px solid var(--grey-line-hover-color);

        svg {
          path {
            fill: var(--blue-hover-color);
          }
        }
      }
    }
  }
}

@include respond-up('medium') {
  .socials {
    &__icons {
      width: 50px;
      height: 50px;
    }
  }
}

@include respond('medium') {
  .socials {

  }
}

@include respond-down('small') {
  .socials {
    &__icons {
      width: 44px;
      height: 44px;
    }
  }
}
