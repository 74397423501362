.included-slider {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__slider {
    &.swiper {
      overflow: visible;
    }
  }

  &__included-item {
    padding-left: 19px;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      width: 6px;
      height: 6px;
      background: var(--blue-color);
      border-radius: 3px;
      left: 0;
      top: 9px;
    }
  }

  &__item {
    &.swiper-slide {
      position: relative;
      border-top: 1px solid var(--grey-line-color);
      height: auto;

      &:not(:last-child) {
        width: calc(var(--grid-column3) + var(--grid-gap));

        &::before {
          content: '';
          position: absolute;
          z-index: 5;
          top: calc(var(--grid-gap) / 2);
          right: calc(var(--grid-gap) / 2);
          height: calc(100% - (var(--grid-gap) / 2));
          width: 1px;
          border-right: 1px solid var(--grey-line-color);
        }
      }

      &:last-child {
        width: var(--grid-column3);
      }
    }
  }

  &__item-inner {
    height: 100%;
  }

  &__picture {
    display: flex;
    height: calc(var(--grid-column3) * (215 / 315));
    width: var(--grid-column3);
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@include respond-up('s-large') {
  .included-slider {
    &__slider-wrap {
      margin-top: 30px;
    }

    &__item.swiper-slide {
      padding: 30px 60px 0 0;
    }
  }
}

@include respond-up('medium') {
  .included-slider {
    &__item-title {
      margin-top: 10px;
    }

    &__included-list {
      margin-top: 20px;
    }
  }
}

@include respond('medium') {
  .included-slider {
    &__slider-wrap {
      margin-top: 20px;
    }

    &__item.swiper-slide {
      padding: 20px 40px 0 0;
    }
  }
}

@include respond-down('small') {
  .included-slider {
    &__slider-wrap {
      margin-top: 10px;
    }

    &__item.swiper-slide {
      padding: 10px 20px 0 0;
    }

    &__item-title {
      margin-top: 6px;
    }

    &__included-list {
      margin-top: 10px;
    }
  }
}